export default function PromotionIcon({ ...rest }) {
  return (
    <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M6.65881 11.0833L4.39999 11.7186L5.22352 14.6597C5.38822 15.248 5.99999 15.648 6.68234 15.4833C7.31763 15.3186 7.67057 14.6597 7.50587 14.0244L6.65881 11.0833ZM13.6 8.14209C14.5176 7.76562 15.0353 6.73033 14.7765 5.74209C14.5176 4.75386 13.5294 4.14209 12.5412 4.3068L11.647 1.1068C11.5059 0.63621 11.0353 0.377386 10.5882 0.495033C10.1176 0.63621 9.85881 1.1068 9.97646 1.55386L12.8235 11.7891C12.9412 12.2362 13.4118 12.5186 13.8823 12.4009C14.3529 12.2833 14.6118 11.7891 14.4706 11.3421L13.6 8.14209ZM5.57646 5.76562C5.50587 5.83621 5.48234 5.9068 5.50587 6.00091L6.70587 10.3539C6.7294 10.448 6.79999 10.495 6.87057 10.5186L12.3294 11.8362L9.55293 1.81268L5.57646 5.76562ZM6.28234 10.4715C5.03528 5.9068 5.03528 6.14209 5.05881 5.88327L2.94116 6.4715C2.75293 6.51856 2.58822 6.65974 2.4941 6.82444C2.42351 6.94209 2.39999 7.05974 2.39999 7.20091C1.50587 7.55385 0.964691 8.56562 1.24704 9.53032C1.48234 10.4009 2.25881 10.9656 3.1294 10.9656C3.24704 10.9656 3.34116 10.9656 3.45881 10.9421C3.57646 11.1774 3.83528 11.3186 4.0941 11.3186C4.16469 11.3186 4.23528 11.3186 4.28234 11.295L6.39999 10.7068C6.3294 10.6127 6.28234 10.5421 6.28234 10.4715Z"
        fill="currentColor"
      />
    </svg>
  );
}
