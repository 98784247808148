import { ComponentPropsWithoutRef, ReactNode } from 'react';

interface SecondaryButtonProps extends ComponentPropsWithoutRef<'button'> {
  children: ReactNode;
  rounded?: 'small' | 'normal' | 'large';
  color?: 'normal' | 'light-green' | 'orange';
  size?: 'small' | 'normal' | 'large';
}

export default function SecondaryButton({
  rounded,
  color,
  size,
  children,
  ...rest
}: SecondaryButtonProps) {
  const customStyle = convertToClassName({ size, color, rounded });
  return (
    <button className={`whitespace-nowrap border font-semibold ${customStyle}`} {...rest}>
      {children}
    </button>
  );
}

interface convertToClassNameProps {
  color?: string;
  rounded?: string;
  size?: string;
}

function convertToClassName({
  color = 'normal',
  rounded = 'normal',
  size = 'normal',
}: convertToClassNameProps) {
  if (color === 'normal') color = 'text-secondary-button-600 border-secondary-button-600';
  if (color === 'light-green') color = 'text-primary-500 border-primary-500';
  if (color === 'orange') color = 'text-secondary3-400 border-secondary3-400';

  if (rounded === 'small') rounded = 'rounded-sm';
  if (rounded === 'normal') rounded = 'rounded';
  if (rounded === 'large') rounded = 'rounded-lg';

  if (size === 'normal') size = 'py-2 px-3';
  if (size === 'small') size = 'py-1 px-2 text-sm';

  return `${color} ${rounded} ${size}`;
}
