/* eslint-disable indent */
import { formatLink } from '../../constants/getLink';
import { openModal } from '../../features/modal/slice';
import { Transaction } from '../../interfaces';
import { useTypedDispatch } from '../../store';
import { CloseButton, PrimaryButton, SecondaryButton } from '../buttons';
import ImageModal from './ImageModal';
import PaymentDetailModal from './PaymentDetailModal';

interface TransactionModalProps {
  close: () => void;
  data: Transaction;
  // eslint-disable-next-line no-unused-vars
  handleVerify: (el: string) => void;
}

export default function TransactionModal({ data, close, handleVerify }: TransactionModalProps) {
  const dispatch = useTypedDispatch();

  const image = formatLink(data.class.classThumbnail);

  function openPaymentDetailModal() {
    dispatch(openModal(<PaymentDetailModal />));
  }

  function openImageModal(imageUrl: string) {
    dispatch(openModal(<ImageModal close={close} url={imageUrl} />));
  }

  function handleOnclickButton(status: string, imageURL: string) {
    if (status === 'unpaid') return openPaymentDetailModal();
    openImageModal(imageURL);
  }

  let user: Partial<Transaction['user']> = {
    email: '',
    name: '',
    profile_picture: '',
  };

  if (data.user) user = data.user;

  return (
    <div className="w-[438px] rounded-2xl border border-netral-5 bg-white p-6">
      <div className="flex items-center justify-between border-b border-b-netral-4 pb-3">
        <p className="font-semibold text-netral-8">Detail Transaksi</p>
        <CloseButton onClick={close} type="button" />
      </div>
      <div className="mt-3 flex space-x-3 rounded-lg border border-netral-6 p-3">
        <div>
          <div className="h-[110px] w-[110px] rounded-xl bg-black">
            <img
              className="h-[110px] w-[110px] rounded-xl"
              src={image}
              style={{ objectFit: 'contain' }}
            />
          </div>
        </div>
        <div className="flex h-full w-full flex-col justify-between">
          <h5 className="text-lg font-semibold text-netral-10">{data.class.courseTitle}</h5>
          <p className="mt-1 text-sm text-netral-8">{data.class.instructor}</p>
          <h6 className="mt-2 font-semibold text-netral-10">
            Rp {Number(data.total_price).toLocaleString('id-ID')}
          </h6>
        </div>
      </div>
      <div className="mt-4 space-y-4">
        <div className="space-y-2">
          <p className="text-xs font-semibold text-netral-8">Invoice</p>
          <h6 className="font-semibold text-netral-10">{data.invoice}</h6>
        </div>
        <div className="flex items-center">
          <div className="flex-1 space-y-2">
            <p className="text-xs font-semibold text-netral-8">Tanggal Transaksi</p>
            <h6 className="font-semibold text-netral-10">
              {new Date(data.createdAt).toDateString()}
            </h6>
          </div>
          <div className="flex-1 space-y-2">
            <p className="text-xs font-semibold text-netral-8">Kode Prakerja</p>
            <h6 className="font-semibold text-netral-10">{data.voucher_code}</h6>
          </div>
        </div>
        <div className="space-y-2">
          <p className="text-xs font-semibold text-netral-8">Nama Peserta</p>
          <h5 className="text-lg font-semibold text-netral-10">{user.name}</h5>
          <p className="text-sm text-netral-9">{user.email}</p>
          {/* <p className="text-sm text-netral-9">{user.phonenumber}</p> */}
        </div>
        <div className="space-y-2">
          <p className="text-xs font-semibold text-netral-8">Status Paid</p>
          <h6 className={status(data.status_payment).className}>
            {status(data.status_payment).text}
          </h6>
        </div>
      </div>
      <div
        className={`${
          data.status_payment === 'waiting for verification' ? 'justify-between' : 'justify-center'
        } mt-6 flex`}
      >
        <div className="flex items-center space-x-3">
          <PrimaryButton
            color="dark-green"
            onClick={() => handleOnclickButton(data.status_payment, data.payment_receipt || '')}
            type="button"
          >
            {data.status_payment === 'waiting for payment'
              ? 'Detail Pembayaran'
              : 'Lihat Bukti Bayar'}
          </PrimaryButton>
          {data.status_payment === 'waiting for verification' && (
            <SecondaryButton color="light-green" onClick={() => handleVerify(data.invoice)}>
              Verifikasi
            </SecondaryButton>
          )}
        </div>
        {data.status_payment === 'waiting for verification' && (
          <PrimaryButton color="red">Delete</PrimaryButton>
        )}
      </div>
    </div>
  );
}

function status(status: string) {
  switch (status) {
    case 'paid':
      return { text: 'Paid', className: 'font-semibold text-secondary2-500' };
    case 'waiting for payment':
      return { text: 'Waiting Payment', className: 'font-semibold text-primary2-500' };
    case 'waiting for verification':
      return {
        text: 'Waiting Verification',
        className: 'font-semibold text-primary-600',
      };
    case 'cancel':
      return {
        text: 'Cancel',
        className: 'font-semibold text-secondary3-500',
      };
    case 'expired':
      return {
        text: 'Expired',
        className: 'font-semibold text-secondary3-700',
      };
    default:
      return { text: 'Paid', className: 'font-semibold ttext-secondary2-500' };
  }
}
