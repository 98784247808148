export default function CloseIcon({ ...rest }) {
  return (
    <svg fill="none" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_148_7716)">
        <path
          d="M21.3501 6.66151C20.8951 6.20651 20.1601 6.20651 19.7051 6.66151L14.0001 12.3548L8.29509 6.64984C7.84009 6.19484 7.10509 6.19484 6.65009 6.64984C6.19509 7.10484 6.19509 7.83984 6.65009 8.29484L12.3551 13.9998L6.65009 19.7048C6.19509 20.1598 6.19509 20.8948 6.65009 21.3498C7.10509 21.8048 7.84009 21.8048 8.29509 21.3498L14.0001 15.6448L19.7051 21.3498C20.1601 21.8048 20.8951 21.8048 21.3501 21.3498C21.8051 20.8948 21.8051 20.1598 21.3501 19.7048L15.6451 13.9998L21.3501 8.29484C21.7934 7.85151 21.7934 7.10484 21.3501 6.66151Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_148_7716">
          <rect fill="white" height="28" width="28" />
        </clipPath>
      </defs>
    </svg>
  );
}
