export default function KeyboardArrowRightIcon({ ...rest }) {
  return (
    <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M14.7099 15.8805L10.8299 12.0005L14.7099 8.12047C15.0999 7.73047 15.0999 7.10047 14.7099 6.71047C14.3199 6.32047 13.6899 6.32047 13.2999 6.71047L8.70994 11.3005C8.31994 11.6905 8.31994 12.3205 8.70994 12.7105L13.2999 17.3005C13.6899 17.6905 14.3199 17.6905 14.7099 17.3005C15.0899 16.9105 15.0999 16.2705 14.7099 15.8805Z"
        fill="currentColor"
      />
    </svg>
  );
}
