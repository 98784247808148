import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { TransactionsState } from './slice';
import { AlertModal } from '../../components/modals';
import { Transaction } from '../../interfaces';
import { closeModal, openModal } from '../modal/slice';
import { AppDispatch, RootState } from '../../store';
import { getTransactionsAPI } from '../../services/api/transaction';

export function resetTransactionsAction(state: TransactionsState) {
  state.loadingData = false;

  state.data = [];

  state.totalPage = 0;
}

export const getTransactionsAction = createAsyncThunk<
  { message: string; status: boolean; total_page: number; data: Transaction[] },
  { query: string },
  { state: RootState; dispatch: AppDispatch }
>('transactions/getTransactions', async ({ query }, { getState, dispatch }) => {
  try {
    const { token } = getState().auth;

    if (!token) throw null;

    const result = await getTransactionsAPI(token, query);

    return result;
  } catch (error) {
    const message = (error as Error).message;

    const close = () => dispatch(closeModal());

    dispatch(openModal(AlertModal({ close, message })));

    throw error;
  }
});

export function transactionsExtraReducer(builder: ActionReducerMapBuilder<TransactionsState>) {
  builder
    .addCase(getTransactionsAction.pending, (state) => {
      state.loadingData = true;
    })
    .addCase(getTransactionsAction.fulfilled, (state, action) => {
      state.loadingData = false;

      state.data = action.payload.data;

      state.totalPage = action.payload.total_page;
    })
    .addCase(getTransactionsAction.rejected, (state) => {
      state.loadingData = false;
    });
}
