import { APIBaseURL } from '../../environment';
import { Voucher } from '../../interfaces';
import * as XLSX from 'xlsx';

interface CreateVoucherAPI {
  status: boolean;
  message: string;
  data: Voucher[];
}

interface GetVouchersByClassIdAPI {
  status: boolean;
  message: string;
  data: {
    class: [{ id: string; courseTitle: string; price: number; instructor: string }];
    voucher: Voucher[];
  };
}

export async function createVoucherAPI(
  token: string,
  courseId: string,
  vendor: string,
  total: string,
  voucher_type: string,
  discount: string,
): Promise<CreateVoucherAPI> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

  const urlencoded = new URLSearchParams();
  urlencoded.append('vendor', vendor);
  urlencoded.append('class_id', courseId);
  urlencoded.append('total', total);
  urlencoded.append('voucher_type', voucher_type);
  urlencoded.append('discount', discount);

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow',
  };

  const response = await fetch(`${APIBaseURL}/api/voucher/create`, requestOptions);

  //   "template": [
  //     {
  //         "class_id": "63c6561adf8b2a3b3da1bb77",
  //         "code_voucher": "Tokopedia68fa6zisp6y",
  //         "status": "active",
  //         "status_reedem": "NO",
  //         "title_class": "2",
  //         "class_instrucor": "",
  //         "redeem_date": "",
  //         "user_name": ""
  //     },
  // ],

  const result = await response.json();

  const worksheet = XLSX.utils.json_to_sheet(result.template);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  XLSX.writeFile(workbook, `${result.template[0].title_class}.xlsx`);

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (!result.success) throw new Error(result.message);

  return result;
}

export async function getVouchersByClassIdAPI(
  token: string,
  courseId: string,
): Promise<GetVouchersByClassIdAPI> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(`${APIBaseURL}/api/voucher/byClassId/${courseId}`, requestOptions);

  const result: GetVouchersByClassIdAPI = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (!result.status) throw new Error(result.message);

  return result;
}
