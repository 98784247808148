import { Link, useLocation, useNavigate } from 'react-router-dom';
import { logout } from '../../features/auth/slice';
import { useTypedDispatch } from '../../store';
import { ProfileButton } from '../buttons';
import { NotificationIcon2, SettingsIcon } from '../icons';

interface DashboardHeaderProps {
  imageURL: string;
  name: string;
  isWhite?: boolean;
}

export default function DashboardHeader({ imageURL, name, isWhite }: DashboardHeaderProps) {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const paths = getPaths(pathname);

  return (
    <div className="flex items-center justify-between">
      <div className="space-y-2">
        <div className="flex items-center space-x-1">
          {paths.map((path, i) => (
            <li key={i} className="list-none">
              <Link
                className={`${
                  paths.length - 1 === i
                    ? `${isWhite ? 'text-white' : 'text-netral-10'} `
                    : `${isWhite ? 'text-white' : 'text-netral-5 hover:text-netral-10'}`
                } text-xs `}
                to={path.link}
              >
                {!path.name ? 'Skills.id Admin' : `/ ${path.name}`}
              </Link>
            </li>
          ))}
        </div>
        <p className={`${isWhite ? 'text-white' : 'text-netral-10'} font-semibold`}>
          {paths[paths.length - 1].name.split('-').join(' ') || 'Dashboard'}
        </p>
      </div>
      <div className="flex items-center space-x-6">
        <NotificationIcon2 className={`${!isWhite ? 'text-[#23C4F4]' : 'text-white'} h-6 w-6`} />
        <Link to="/setting">
          <SettingsIcon className={`${!isWhite ? 'text-[#23C4F4]' : 'text-white'} h-6 w-6`} />
        </Link>
        <ProfileButton
          imageURL={imageURL}
          isWhite={!!isWhite}
          logout={() => dispatch(logout())}
          name={name}
          profile={() => navigate('/profile')}
          setting={() => navigate('/setting')}
        />
      </div>
    </div>
  );
}
function getPaths(pathname: string) {
  if (pathname === '/') return [{ name: '', link: '/' }];
  const pathnameArr = pathname.split('/');
  const result = [];
  let temp = '';

  for (let i = 0; i < pathnameArr.length; i++) {
    temp += pathnameArr[i];
    result.push({ name: pathnameArr[i], link: temp });
    temp += '/';
  }

  return result;
}
