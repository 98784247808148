import { ReactNode } from 'react';

export interface DashboardTableProps {
  tableHeads: {
    node: ReactNode;
    justify?: string;
    noshrink?: boolean;
    width?: string;
    breakWord?: boolean;
    widthAuto?: boolean;
  }[][];
  tableBodies?: {
    node: ReactNode;
    justify?: string;
    noshrink?: boolean;
    width?: string;
    breakWord?: boolean;
    widthAuto?: boolean;
  }[][];
}

export default function DashboardTable({ tableHeads, tableBodies }: DashboardTableProps) {
  return (
    <table className="w-full">
      <thead>
        {tableHeads.map((el, i) => (
          <tr key={i} className="flex items-center space-x-6 py-3">
            {el.map((elHead, indexHead) => (
              <th
                key={indexHead}
                className={`${
                  elHead.noshrink
                    ? ` grow ${elHead.width ? `w-[${elHead.width}]` : 'w-[250px]'}` + ''
                    : `${elHead.width ? `w-[${elHead.width}]` : ''}`
                } flex w-full overflow-hidden break-all text-left text-xs font-semibold text-netral-7`}
                style={{ justifyContent: elHead.justify || 'start' }}
              >
                <div>{elHead.node}</div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {tableBodies?.map((el, i) => (
          <tr key={i} className="flex items-center space-x-6 border-t border-t-netral-5 py-3">
            {el.map((elBody, indexBody) => (
              <td
                key={indexBody}
                className={`${elBody.breakWord ? 'break-all' : ''} ${
                  elBody.noshrink
                    ? `${elBody.width ? `w-[${elBody.width}]` : 'w-[250px]'}` + 'grow'
                    : `${elBody.width ? `w-[${elBody.width}]` : ''}`
                }
                ${!elBody.widthAuto && 'w-full'} flex overflow-hidden text-sm text-netral-10`}
                style={{ justifyContent: elBody.justify || 'start' }}
              >
                {elBody.node}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
