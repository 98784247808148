import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import authSlice from './features/auth/slice';
import modalSlice from './features/modal/slice';
import userSlice from './features/user/slice';
import learnersSlice from './features/learners/slice';
import instructorsSlice from './features/instructors/slice';
import redeemsSlice from './features/redeems/slice';
import dashboardSlice from './features/dashboard/slice';
import coursesSlice from './features/courses/slice';
import vouchersSlice from './features/vouchers/slice';
import transactionsSlice from './features/transactions/slice';
import adminSlice from './features/admin/slice';
import courseSlice from './features/course/slice';
import tasksSlice from './features/tasks/slice';
import taskSlice from './features/task/slice';
import transactionSlice from './features/transaction/slice';
import certificatesSlice from './features/certificates/slice';

export const store = configureStore({
  reducer: {
    modal: modalSlice,
    auth: authSlice,
    user: userSlice,
    learners: learnersSlice,
    instructors: instructorsSlice,
    redeems: redeemsSlice,
    dashboard: dashboardSlice,
    courses: coursesSlice,
    vouchers: vouchersSlice,
    transactions: transactionsSlice,
    transaction: transactionSlice,
    admin: adminSlice,
    course: courseSlice,
    tasks: tasksSlice,
    task: taskSlice,
    certificates: certificatesSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const useTypedDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
