import { createSlice } from '@reduxjs/toolkit';
import { User } from '../../interfaces';
import { getUserAction, removeUserAction, userExtraReducer } from './action';

export interface UserState {
  data: User | null;
  loadingUser: boolean;
}

const initialState: UserState = {
  data: null,
  loadingUser: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    removeUser: removeUserAction,
  },
  extraReducers: userExtraReducer,
});

export default userSlice.reducer;

export const { removeUser } = userSlice.actions;

export { getUserAction as getUser };
