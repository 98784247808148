import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { AdminState, getAdmins } from './slice';
import { AlertModal } from '../../components/modals';
import { Admin } from '../../interfaces';
import { closeModal, openModal } from '../modal/slice';
import { AppDispatch, RootState } from '../../store';
import {
  addAdminAPI,
  deleteAdminAPI,
  getAdminsAPI,
  GetdminsAPI,
  updateAdminAPI,
} from '../../services/api/admin';

export function resetAdminAction(state: AdminState) {
  state.loading = false;

  state.data = [];
}

export const addAdminAction = createAsyncThunk<
  void,
  { email: string; name: string; password: string; query: string },
  { state: RootState; dispatch: AppDispatch }
>('admin/addAdmin', async ({ email, name, password, query }, { getState, dispatch }) => {
  try {
    const { token } = getState().auth;

    if (!token) throw null;

    await addAdminAPI(token, email, name, password);

    dispatch(getAdmins({ query }));

    dispatch(closeModal());
  } catch (error) {
    const message = (error as Error).message;

    const close = () => dispatch(closeModal());

    dispatch(openModal(AlertModal({ close, message })));

    throw error;
  }
});

export const getAdminsAction = createAsyncThunk<
  { result: Admin[]; total_page: number },
  { query: string },
  { state: RootState; dispatch: AppDispatch }
>('admin/getAdmins', async ({ query }, { getState, dispatch }) => {
  try {
    const { token } = getState().auth;

    if (!token) throw null;

    const result: GetdminsAPI = await getAdminsAPI(token, query);

    return { result: result.data.reverse(), total_page: result.total_page };
  } catch (error) {
    const message = (error as Error).message;

    const close = () => dispatch(closeModal());

    dispatch(openModal(AlertModal({ close, message })));

    throw error;
  }
});

export const updateAdminAction = createAsyncThunk<
  void,
  { email: string; name: string; password: string; query: string; adminId: string },
  { state: RootState; dispatch: AppDispatch }
>(
  'admin/addAupdateAdmin',
  async ({ email, adminId, name, password, query }, { getState, dispatch }) => {
    try {
      const { token } = getState().auth;

      if (!token) throw null;

      await updateAdminAPI(token, adminId, email, name, password);

      dispatch(getAdmins({ query }));

      dispatch(closeModal());
    } catch (error) {
      const message = (error as Error).message;

      const close = () => dispatch(closeModal());

      dispatch(openModal(AlertModal({ close, message })));

      throw error;
    }
  },
);

export const deleteAdminAction = createAsyncThunk<
  void,
  { adminId: string; query: string },
  { state: RootState; dispatch: AppDispatch }
>('admin/deleteAdmin', async ({ adminId, query }, { getState, dispatch }) => {
  try {
    const { token } = getState().auth;

    if (!token) throw null;

    await deleteAdminAPI(token, adminId);

    dispatch(getAdmins({ query }));
  } catch (error) {
    const message = (error as Error).message;

    const close = () => dispatch(closeModal());

    dispatch(openModal(AlertModal({ close, message })));

    throw error;
  }
});

export function adminExtraReducer(builder: ActionReducerMapBuilder<AdminState>) {
  builder
    .addCase(getAdminsAction.pending, (state) => {
      state.loading = true;
    })
    .addCase(getAdminsAction.fulfilled, (state, action) => {
      state.loading = false;

      state.data = action.payload.result;

      state.totalPage = action.payload.total_page;
    })
    .addCase(getAdminsAction.rejected, (state) => {
      state.loading = false;
    })
    .addCase(addAdminAction.pending, (state) => {
      state.loading = true;
    })
    .addCase(addAdminAction.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(addAdminAction.rejected, (state) => {
      state.loading = false;
    })
    .addCase(deleteAdminAction.pending, (state) => {
      state.loading = true;
    })
    .addCase(deleteAdminAction.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(deleteAdminAction.rejected, (state) => {
      state.loading = false;
    })
    .addCase(updateAdminAction.pending, (state) => {
      state.loading = true;
    })
    .addCase(updateAdminAction.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(updateAdminAction.rejected, (state) => {
      state.loading = false;
    });
}
