import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { DashboardState } from './slice';
import { AlertModal } from '../../components/modals';
import { closeModal, openModal } from '../modal/slice';
import { AppDispatch, RootState } from '../../store';
import { Dashboard } from '../../interfaces';
import { getDashboardAPI } from '../../services/api/dashboard';
import initialDashboardData from '../../constants/initial';

export function resetDashboardAction(state: DashboardState) {
  state.loadingData = false;

  state.data = initialDashboardData;
}

export const getDashboardAction = createAsyncThunk<
  Dashboard,
  void,
  { state: RootState; dispatch: AppDispatch }
>('dashboard/getDashboard', async (_, { getState, dispatch }) => {
  try {
    const { token } = getState().auth;

    if (!token) throw null;

    const result = await getDashboardAPI(token);

    return result;
  } catch (error) {
    const message = (error as Error).message;

    const close = () => dispatch(closeModal());

    dispatch(openModal(AlertModal({ close, message })));

    throw error;
  }
});

export function dashboardExtraReducer(builder: ActionReducerMapBuilder<DashboardState>) {
  builder
    .addCase(getDashboardAction.pending, (state) => {
      state.loadingData = true;
    })
    .addCase(getDashboardAction.fulfilled, (state, action) => {
      state.loadingData = false;

      state.data = action.payload;
    })
    .addCase(getDashboardAction.rejected, (state) => {
      state.loadingData = false;
    });
}
