/* eslint-disable no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../components/buttons';
import { ChecklistIcon, CloseIconRed, SearchIcon } from '../../components/icons';
import { SelectTemplateNotifModal, SuccessNotifModal } from '../../components/modals';
import AddReminderModal from '../../components/modals/AddReminderModal';
import { TablePagination } from '../../components/paginations';
import { DashboardTable } from '../../components/tables';
import { openModal } from '../../features/modal/action';
import { closeModal } from '../../features/modal/slice';
import { useQuery } from '../../hooks';
import {
  getAllClass,
  getNotifClassAPI,
  getNotifLearnerAPI,
  getNotifPromoAPI,
  getRedeemNotifTemplate,
  getRegisterNotifTemplate,
  sendNotifAPI,
} from '../../services/api/notif';
import { useTypedDispatch, useTypedSelector } from '../../store';

interface GetAllRedeemClass {
  status: boolean;
  totalPage: number;
  data: {
    _id: string;
    courseTitle: string;
    instructor: string;
  }[];
}

interface GetNotifLearner {
  status: boolean;
  totalPage: number;
  allPhoneNumber: {
    name: string;
    phoneNumber: string;
  }[];
  data: {
    _id: string;
    user_id: string;
    class_id: string;
    user: {
      name: string;
      phoneNumber: string;
    };
    class: {
      courseTitle: string;
    };
    voucher: {
      voucher_code: string;
      redeemCode: string;
      isReported: boolean;
    };
  }[];
}

interface CourseType {
  courseTitle: string;
  learners: {
    name: string;
    phoneNumber: string;
  }[];
  startDate: string;
  startHour: string;
}

interface PromoType {
  data: {
    totalPage: number;
    status: boolean;
    allPhoneNumber: {
      name: string;
      phoneNumber: string;
    }[];
    data: {
      _id: string;
      phoneNumber: string;
      name: string;
    }[];
  };
}

interface RegisterType {
  data: {
    phoneNumber: string;
    name: string;
  }[];
}

const tabList = [
  'Reminder Registrasi',
  'Reminder Redeem',
  'Reminder Pelatihan',
  'Reminder Validasi Nama',
  'Promo',
];

function Notification() {
  const dispatch = useTypedDispatch();
  const { pathname } = useLocation();
  const [searchClass, setSearchClass] = useState('');
  const [searchLearner, setSearchLearner] = useState('');
  const [searchPromo, setSearchPromo] = useState('');
  const [searchRegister, setSearchRegister] = useState('');
  const [searchRedeem, setSearchRedeem] = useState('');
  const [searchRedeemClass, setSearchRedeemClass] = useState('');
  const [tab, setTab] = useState(tabList[0]);
  const [dataClass, setDataClass] = useState<CourseType[]>([]);
  const [dataPromo, setDataPromo] = useState<PromoType>({
    data: {
      totalPage: 1,
      status: true,
      allPhoneNumber: [],
      data: [],
    },
  });
  const [dataRegister, setDataRegister] = useState<any>({
    data: [],
  });
  const [dataRedeem, setDataRedeem] = useState<any>({
    data: [],
  });
  const [dataLearner, setDataLearner] = useState<GetNotifLearner>({
    status: false,
    totalPage: 0,
    allPhoneNumber: [
      {
        name: '',
        phoneNumber: '',
      },
    ],
    data: [
      {
        _id: '',
        user_id: '',
        class_id: '',
        user: {
          name: '',
          phoneNumber: '',
        },
        class: {
          courseTitle: '',
        },
        voucher: {
          voucher_code: '',
          redeemCode: '',
          isReported: false,
        },
      },
    ],
  });

  const [dataRedeemClass, setDataRedeemClass] = useState<GetAllRedeemClass>({
    status: false,
    totalPage: 0,
    data: [
      {
        _id: '',
        instructor: '',
        courseTitle: '',
      },
    ],
  });
  const [dataTemplate, setDataTemplate] = useState([
    {
      templateId: '',
      id: '',
      body: '',
    },
  ]);
  const [dataRedeemTemplate, setDataRedeemTemplate] = useState([
    {
      templateId: '',
      id: '',
      body: '',
    },
  ]);
  // const [registerFile, setRegisterFile] = useState(null);
  const { token } = useTypedSelector((state) => state.auth);
  const navigate = useNavigate();

  async function getDataClass(token: string) {
    const res = await getNotifClassAPI(token);
    if (res.status) setDataClass(res.data);
  }

  async function getLearner(token: string, page_number: number, name: string) {
    const res = await getNotifLearnerAPI(token, 5, page_number, name);
    if (res.status) setDataLearner(res);
  }

  async function getAllReedemClass(token: string, page_number: number, name: string) {
    const res = await getAllClass(token, 5, page_number, name);
    if (res.status) setDataRedeemClass(res);
  }

  async function getRegisterNotif(token: string) {
    const res = await getRegisterNotifTemplate(token);
    if (res.status) setDataTemplate(res.result);
  }

  async function getRedeemNotif(token: string) {
    const res = await getRedeemNotifTemplate(token);
    if (res.status) setDataRedeemTemplate(res.result);
  }

  useEffect(() => {
    getRegisterNotif(token);
    getRedeemNotif(token);
  }, []);

  // async function getRegisterReminder(file: File) {
  //   const res = await sendRegisterReminder(token, file);
  //   if (res.status) {
  //     setDataRegister(res);
  //     dispatch(closeModal());
  //   }
  // }

  async function getPromo(token: string, page_number: number, name: string) {
    const res = await getNotifPromoAPI(token, 5, page_number, name);
    setDataPromo(res);
  }

  async function sendNotif(
    templateId: string,
    data: {
      name: string;
      phoneNumber: string;
    }[],
  ) {
    const res = await sendNotifAPI(token, templateId, data);
    if (res.success) openSuccessModal();
  }

  const handleSendRegisterMessage = (template: string, data: any) => {
    sendNotif(template, data);
  };

  function openSuccessModal() {
    dispatch(openModal(<SuccessNotifModal close={() => dispatch(closeModal())} />));
  }

  function openSelectNotifModal(data: any, dataTemplate: any) {
    dispatch(
      openModal(
        <SelectTemplateNotifModal
          close={() => dispatch(closeModal())}
          data={dataTemplate}
          sendMessage={(templateId) => handleSendRegisterMessage(templateId, data)}
        />,
      ),
    );
  }

  // function openSelectNotifModalById(data: any, dataTemplate: any) {
  //   dispatch(
  //     openModal(
  //       <SelectTemplateNotifModal
  //         close={() => dispatch(closeModal())}
  //         data={dataTemplate}
  //         sendMessage={(templateId) => handleSendRegisterMessage(templateId, data)}
  //       />,
  //     ),
  //   );
  // }

  function openImportModal() {
    dispatch(
      openModal(
        <AddReminderModal
          close={() => dispatch(closeModal())}
          // handleUpload={(file) => getRegisterReminder(file)}
          setData={setDataRegister}
          type="Import Data Peserta"
          url={'notification-register'}
        />,
      ),
    );
  }

  function openImportRedeemModal() {
    dispatch(
      openModal(
        <AddReminderModal
          close={() => dispatch(closeModal())}
          // handleUpload={(file) => getRegisterReminder(file)}
          setData={setDataRedeem}
          type="Import Data Peserta"
          url={'notification-redeem'}
        />,
      ),
    );
  }

  const query = useQuery();
  const queryPage = query.get('page_number') || '1';
  const queryCode = query.get('c') || '';
  function filterData({ page = queryPage, code = queryCode }) {
    navigate(`${pathname}?page_size=5&page_number=${page}&c=${code}`);
  }

  const [tableHeadsClass, tableBodiesClass] = useMemo(() => {
    return mapDataToTableData(
      dataClass.filter((el) => el.courseTitle.toUpperCase().includes(searchClass.toUpperCase())),
      (templateId, dataToSend) => sendNotif(templateId, dataToSend),
    );
  }, [dataClass, searchClass]);

  const [tableHeadsLearner, tableBodiesLearner] = useMemo(() => {
    return mapLearnerDataToTableData(dataLearner.data, (templateId, dataToSend) =>
      sendNotif(templateId, dataToSend),
    );
  }, [dataLearner]);

  const [tableHeadsRegister, tableBodiesRegister] = useMemo(() => {
    return mapLearnerDataToRegistrationTableData(
      {
        data: dataRegister.data.filter((el: any) =>
          el.name.toUpperCase().includes(searchRegister.toUpperCase()),
        ),
      },
      (dataToSend) => openSelectNotifModal(dataToSend, dataTemplate),
    );
  }, [dataRegister, searchRegister]);

  const [tableHeadsRedeem, tableBodiesRedeem] = useMemo(() => {
    return mapLearnerDataToRedeemTableData(
      {
        data: dataRedeem.data.filter((el: any) =>
          el.name.toUpperCase().includes(searchRedeem.toUpperCase()),
        ),
      },
      (dataToSend) => openSelectNotifModal(dataToSend, dataRedeemTemplate),
    );
  }, [dataRedeem, searchRedeem]);

  const [tableHeadsRedeemClass, tableBodiesRedeemClass] = useMemo(() => {
    return mapLearnerDataToRedeemClassTableData(dataRedeemClass.data, (code) =>
      filterData({ code: code }),
    );
  }, [dataRedeemClass, searchRedeem]);

  const [tableHeadsPromo, tableBodiesPromo] = useMemo(() => {
    return mapLearnerDataToPromoTableData(dataPromo.data, (templateId, dataToSend) =>
      sendNotif(templateId, dataToSend),
    );
  }, [dataPromo]);

  useEffect(() => {
    getDataClass(token);
  }, []);

  useEffect(() => {
    getLearner(token, Number(queryPage), searchLearner);
  }, [queryPage, searchLearner]);

  useEffect(() => {
    getAllReedemClass(token, Number(queryPage), searchRedeemClass);
  }, [queryPage, searchRedeemClass]);

  useEffect(() => {
    getPromo(token, Number(queryPage), searchPromo);
  }, [queryPage, searchPromo]);

  return (
    <div className="space-y-6">
      <div className="flex space-x-[16px]">
        {tabList.map((el, idx) => (
          <button
            key={idx}
            className={`${
              tab !== el
                ? 'bg-primary-1 text-secondary-button-900 hover:bg-netral-2 active:translate-y-[2px]'
                : 'bg-secondary-button-900 text-netral-1'
            } flex min-w-[150px] cursor-pointer justify-center whitespace-nowrap rounded-xl border px-[16px] py-[8px] text-sm shadow-button duration-150`}
            onClick={() => setTab(el)}
          >
            {el}
          </button>
        ))}
      </div>
      {tab === 'Reminder Pelatihan' && (
        <div className="space-y-4 rounded-xl bg-white p-4">
          <div className="flex items-center justify-between">
            <h5 className="text-lg font-semibold text-netral-10">Reminder Pelatihan</h5>
            <div className="flex">
              <form className="relative mx-[20px]" onSubmit={(e) => e.preventDefault()}>
                <input
                  className="rounded border border-netral-1-stroke bg-white p-1 pl-[42px]"
                  onChange={(e) => setSearchClass(e.target.value)}
                  placeholder="Cari"
                  type="search"
                  value={searchClass}
                  // {...rest}
                />
                <div className="absolute top-0 left-2 flex h-full items-center">
                  <SearchIcon className="h-6 w-6 text-[#323232]" />
                </div>
              </form>
            </div>
          </div>
          <DashboardTable tableBodies={tableBodiesClass} tableHeads={tableHeadsClass} />
        </div>
      )}
      {tab === 'Reminder Validasi Nama' && (
        <div className="space-y-4 rounded-xl bg-white p-4">
          <div className="flex items-center justify-between">
            <h5 className="text-lg font-semibold text-netral-10">Reminder Validasi Nama</h5>
            <div className="flex">
              <form className="relative mx-[20px]" onSubmit={(e) => e.preventDefault()}>
                <input
                  className="rounded border border-netral-1-stroke bg-white p-1 pl-[42px]"
                  onChange={(e) => setSearchLearner(e.target.value)}
                  placeholder="Cari"
                  type="search"
                  value={searchLearner}
                  // {...rest}
                />
                <div className="absolute top-0 left-2 flex h-full items-center">
                  <SearchIcon className="h-6 w-6 text-[#323232]" />
                </div>
              </form>
              <PrimaryButton
                // color={course.status === 'Publish' ? 'tomatto' : 'light-green'}
                // loading={loadingUpdateCourseById === course._id}
                onClick={() => sendNotif('manualValidation', dataLearner.allPhoneNumber)}
                rounded="large"
                size="small"
                type="button"
                width={150}
              >
                {/* {course.status === 'Publish' ? 'Take Down' : 'Publish'} */}
                Kirim Semua
              </PrimaryButton>
            </div>
          </div>
          <DashboardTable tableBodies={tableBodiesLearner} tableHeads={tableHeadsLearner} />
          <TablePagination
            changePage={(page) => filterData({ page: String(page) })}
            page={Number(queryPage)}
            totalPage={dataLearner.totalPage}
          />
        </div>
      )}
      {tab === 'Promo' && (
        <div className="space-y-4 rounded-xl bg-white p-4">
          <div className="flex items-center justify-between">
            <h5 className="text-lg font-semibold text-netral-10">Promo</h5>
            <div className="flex">
              <form className="relative mx-[20px]" onSubmit={(e) => e.preventDefault()}>
                <input
                  className="rounded border border-netral-1-stroke bg-white p-1 pl-[42px]"
                  onChange={(e) => setSearchPromo(e.target.value)}
                  placeholder="Cari"
                  type="search"
                  value={searchPromo}
                  // {...rest}
                />
                <div className="absolute top-0 left-2 flex h-full items-center">
                  <SearchIcon className="h-6 w-6 text-[#323232]" />
                </div>
              </form>
              <PrimaryButton
                // color={course.status === 'Publish' ? 'tomatto' : 'light-green'}
                // loading={loadingUpdateCourseById === course._id}
                onClick={() => sendNotif('promo', dataPromo.data.allPhoneNumber)}
                rounded="large"
                size="small"
                type="button"
                width={150}
              >
                {/* {course.status === 'Publish' ? 'Take Down' : 'Publish'} */}
                Kirim Semua
              </PrimaryButton>
            </div>
          </div>
          <DashboardTable tableBodies={tableBodiesPromo} tableHeads={tableHeadsPromo} />
          <TablePagination
            changePage={(page) => filterData({ page: String(page) })}
            page={Number(queryPage)}
            totalPage={dataPromo.data.totalPage}
          />
        </div>
      )}
      {tab === 'Reminder Registrasi' && (
        <div className="space-y-4 rounded-xl bg-white p-4">
          <div className="flex items-center justify-between">
            <h5 className="ch text-lg font-semibold text-netral-10">Reminder Registrasi</h5>
            <PrimaryButton onClick={openImportModal}>Import Data Peserta</PrimaryButton>
          </div>
          <div className="mt-4 mb-1 flex justify-end">
            <div className="flex">
              <form className="relative mx-[20px]" onSubmit={(e) => e.preventDefault()}>
                <input
                  className="rounded border border-netral-1-stroke bg-white p-1 pl-[42px]"
                  onChange={(e) => setSearchRegister(e.target.value)}
                  placeholder="Cari"
                  type="search"
                  value={searchRegister}
                  // {...rest}
                />
                <div className="absolute top-0 left-2 flex h-full items-center">
                  <SearchIcon className="h-6 w-6 text-[#323232]" />
                </div>
              </form>
              <PrimaryButton
                // color={course.status === 'Publish' ? 'tomatto' : 'light-green'}
                // loading={loadingUpdateCourseById === course._id}
                // onClick={() => sendNotif('register', dataRegister.data)}
                onClick={() => openSelectNotifModal(dataRegister.data, dataTemplate)}
                rounded="large"
                size="small"
                type="button"
                width={150}
              >
                {/* {course.status === 'Publish' ? 'Take Down' : 'Publish'} */}
                Kirim Semua
              </PrimaryButton>
            </div>
          </div>
          <DashboardTable tableBodies={tableBodiesRegister} tableHeads={tableHeadsRegister} />
        </div>
      )}
      {tab === 'Reminder Redeem' && queryCode === '' && (
        <div className="space-y-4 rounded-xl bg-white p-4">
          <div className="flex items-center justify-between">
            <h5 className="ch text-lg font-semibold text-netral-10">Reminder Redeem</h5>
            <div className="flex">
              <form className="relative" onSubmit={(e) => e.preventDefault()}>
                <input
                  className="rounded border border-netral-1-stroke bg-white p-1 pl-[42px]"
                  onChange={(e) => setSearchRedeemClass(e.target.value)}
                  placeholder="Cari"
                  type="search"
                  value={searchRedeemClass}
                  // {...rest}
                />
                <div className="absolute top-0 left-2 flex h-full items-center">
                  <SearchIcon className="h-6 w-6 text-[#323232]" />
                </div>
              </form>
            </div>
          </div>
          <DashboardTable tableBodies={tableBodiesRedeemClass} tableHeads={tableHeadsRedeemClass} />
          <TablePagination
            changePage={(page) => filterData({ page: String(page) })}
            page={Number(queryPage)}
            totalPage={dataRedeemClass.totalPage + 1}
          />
        </div>
      )}
      {tab === 'Reminder Redeem' && queryCode !== '' && (
        <div className="space-y-4 rounded-xl bg-white p-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <img
                className="h-[15px] w-[15px] cursor-pointer"
                onClick={() => history.back()}
                src="/assets/logos/left-arrow.svg"
              />
              <p
                className="
                   text-xs text-netral-5
                hover:text-netral-10"
              >
                Skills.id Admin
              </p>
              <p
                className="text-xs
                text-netral-10"
              >
                / detail
              </p>
            </div>
            <PrimaryButton onClick={openImportRedeemModal}>Import Data Peserta</PrimaryButton>
          </div>
          <div className="mt-4 mb-1 flex justify-end">
            <div className="flex">
              <form className="relative mx-[20px]" onSubmit={(e) => e.preventDefault()}>
                <input
                  className="rounded border border-netral-1-stroke bg-white p-1 pl-[42px]"
                  onChange={(e) => setSearchRedeem(e.target.value)}
                  placeholder="Cari"
                  type="search"
                  value={searchRedeem}
                />
                <div className="absolute top-0 left-2 flex h-full items-center">
                  <SearchIcon className="h-6 w-6 text-[#323232]" />
                </div>
              </form>
              <PrimaryButton
                // onClick={() => sendNotif('reminderRedeem', dataRedeem.data)}
                onClick={() => openSelectNotifModal(dataRedeem.data, dataRedeemTemplate)}
                rounded="large"
                size="small"
                type="button"
                width={150}
              >
                Kirim Semua
              </PrimaryButton>
            </div>
          </div>
          <DashboardTable tableBodies={tableBodiesRedeem} tableHeads={tableHeadsRedeem} />
        </div>
      )}
    </div>
  );
}

export default Notification;

function mapDataToTableData(
  course: CourseType[],
  sendNotif: (
    templateId: string,
    // eslint-disable-next-line no-unused-vars
    data: {
      name: string;
      phoneNumber: string;
    }[],
  ) => void,
  // loadingUpdateCourseById: string,
  // // eslint-disable-next-line no-unused-vars
) {
  const tableHeads = [
    [
      { node: 'Nama Kelas', justify: 'start' },
      { node: 'Tanggal', justify: 'start' },
      { node: 'Waktu Mulai', justify: 'start' },
      { node: 'Jumlah Peserta', justify: 'start' },
      { node: '', justify: 'start' },
    ],
  ];

  const tableBodies = course.map((course) => [
    {
      node: (
        <p className="w-[300px] text-sm font-semibold text-primary-500 underline">
          {course.courseTitle}
        </p>
      ),
      justify: 'start',
    },
    { node: new Date(course.startDate).toLocaleDateString() },
    { node: course.startHour },
    { node: course.learners.length },
    {
      node: (
        <div className="flex items-center space-x-3">
          <PrimaryButton
            color={course.learners.length > 0 ? 'normal' : 'disabled'}
            disabled={course.learners.length < 1}
            onClick={() => sendNotif('classReminder', course.learners)}
            rounded="large"
            size="small"
            type="button"
            width={150}
          >
            Kirim Reminder
          </PrimaryButton>
        </div>
      ),
      justify: 'end',
      // noshrink: true,
      // width: '250px',
    },
  ]);

  return [tableHeads, tableBodies];
}

function mapLearnerDataToTableData(
  course: GetNotifLearner['data'],
  sendNotif: (
    templateId: string,

    data: {
      name: string;
      phoneNumber: string;
    }[],
  ) => void,
  // loadingUpdateCourseById: string,
  // // eslint-disable-next-line no-unused-vars
  // updateCourseStatus: (id: string, status: string) => void,
) {
  const tableHeads = [
    [
      { node: 'Nama Learner', justify: 'start' },
      { node: 'Kelas', justify: 'start' },
      { node: 'Kode Voucher', justify: 'start' },
      { node: '', justify: 'start' },
    ],
  ];

  const tableBodies = course.map((course) => [
    { node: course.user.name },
    {
      node: (
        <p className="w-[300px] text-sm font-semibold text-primary-500 underline">
          {course.class.courseTitle}
        </p>
      ),
      justify: 'start',
    },
    {
      node: (
        <div className="flex w-full ">
          <p className="w-[180px]">{course.voucher.voucher_code}</p>
          {course.voucher.isReported ? (
            <ChecklistIcon className=" h-[15px] w-[15px]" />
          ) : (
            <CloseIconRed className=" h-[15px] w-[15px]" />
          )}
        </div>
      ),
    },
    {
      node: (
        <div className="flex items-center space-x-3">
          <PrimaryButton
            // color={course.status === 'Publish' ? 'tomatto' : 'light-green'}
            // loading={loadingUpdateCourseById === course._id}
            // onClick={() => updateCourseStatus(course._id, course.status)}
            onClick={() => sendNotif('manualValidation', [course.user])}
            rounded="large"
            size="small"
            type="button"
            width={150}
          >
            Kirim Reminder
          </PrimaryButton>
        </div>
      ),
      justify: 'end',
      // noshrink: true,
      // width: '250px',
    },
  ]);

  return [tableHeads, tableBodies];
}

function mapLearnerDataToPromoTableData(
  course: PromoType['data'],
  sendNotif: (
    templateId: string,
    data: {
      name: string;
      phoneNumber: string;
    }[],
  ) => void,
  // loadingUpdateCourseById: string,
  // // eslint-disable-next-line no-unused-vars
  // updateCourseStatus: (id: string, status: string) => void,
) {
  const tableHeads = [
    [
      { node: 'Nama', justify: 'start' },
      { node: '', justify: 'start' },
    ],
  ];

  const tableBodies = course.data.map((course) => [
    { node: course.name },
    {
      node: (
        <div className="flex items-center space-x-3">
          <PrimaryButton
            // color={course.status === 'Publish' ? 'tomatto' : 'light-green'}
            // loading={loadingUpdateCourseById === course._id}
            // onClick={() => updateCourseStatus(course._id, course.status)}
            onClick={() => sendNotif('regulerNoLink', [course])}
            rounded="large"
            size="small"
            type="button"
            width={150}
          >
            Kirim Reminder
          </PrimaryButton>
        </div>
      ),
      justify: 'end',
      // noshrink: true,
      // width: '250px',
    },
  ]);

  return [tableHeads, tableBodies];
}

function mapLearnerDataToRegistrationTableData(
  course: RegisterType,
  sendNotif: (
    data: {
      name: string;
      phoneNumber: string;
    }[],
  ) => void,
  // loadingUpdateCourseById: string,
  // // eslint-disable-next-line no-unused-vars
  // updateCourseStatus: (id: string, status: string) => void,
) {
  const tableHeads = [
    [
      { node: 'Nama', justify: 'start' },
      { node: '', justify: 'start' },
    ],
  ];

  const tableBodies = course.data.map((course) => [
    { node: course.name },

    {
      node: (
        <div className="flex items-center space-x-3">
          <PrimaryButton
            // color={course.status === 'Publish' ? 'tomatto' : 'light-green'}
            // loading={loadingUpdateCourseById === course._id}
            // onClick={() => updateCourseStatus(course._id, course.status)}
            onClick={() => sendNotif([course])}
            rounded="large"
            size="small"
            type="button"
            width={150}
          >
            Kirim Reminder
          </PrimaryButton>
        </div>
      ),
      justify: 'end',
      // noshrink: true,
      // width: '250px',
    },
  ]);

  return [tableHeads, tableBodies];
}

function mapLearnerDataToRedeemTableData(
  course: {
    data: {
      name: string;
      phoneNumber: string;
      variable: string;
    }[];
  },
  sendNotif: (
    data: {
      name: string;
      phoneNumber: string;
      variable: string;
    }[],
  ) => void,
  // loadingUpdateCourseById: string,
  // // eslint-disable-next-line no-unused-vars
  // updateCourseStatus: (id: string, status: string) => void,
) {
  const tableHeads = [
    [
      { node: 'Nama', justify: 'start' },
      { node: '', justify: 'start' },
    ],
  ];

  const tableBodies = course.data.map((course) => [
    { node: course.name },
    {
      node: (
        <div className="flex items-center space-x-3">
          <PrimaryButton
            // color={course.status === 'Publish' ? 'tomatto' : 'light-green'}
            // loading={loadingUpdateCourseById === course._id}
            // onClick={() => updateCourseStatus(course._id, course.status)}
            onClick={() => sendNotif([course])}
            rounded="large"
            size="small"
            type="button"
            width={150}
          >
            Kirim Reminder
          </PrimaryButton>
        </div>
      ),
      justify: 'end',
      // noshrink: true,
      // width: '250px',
    },
  ]);

  return [tableHeads, tableBodies];
}

function mapLearnerDataToRedeemClassTableData(
  data: GetAllRedeemClass['data'],
  onClick: (data: string) => void,
) {
  const tableHeads = [
    [
      { node: 'Nama', justify: 'start' },
      { node: 'Instructor', justify: 'start' },
      { node: '', justify: 'start' },
    ],
  ];

  const tableBodies = data.map((course) => [
    { node: course.courseTitle },
    { node: course.instructor },

    {
      node: (
        <div className="flex items-center space-x-3">
          <PrimaryButton
            // color={course.status === 'Publish' ? 'tomatto' : 'light-green'}
            // loading={loadingUpdateCourseById === course._id}
            // onClick={() => updateCourseStatus(course._id, course.status)}
            onClick={() => onClick(course._id)}
            rounded="large"
            size="small"
            type="button"
            width={150}
          >
            Detail
          </PrimaryButton>
        </div>
      ),
      justify: 'end',
      // noshrink: true,
      // width: '250px',
    },
  ]);

  return [tableHeads, tableBodies];
}
