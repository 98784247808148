import { createSlice } from '@reduxjs/toolkit';
import { Admin } from '../../interfaces';
import {
  addAdminAction,
  adminExtraReducer,
  deleteAdminAction,
  getAdminsAction,
  resetAdminAction,
  updateAdminAction,
} from './action';

export interface AdminState {
  loading: boolean;
  data: Admin[];
  totalPage: number;
}

const initialState: AdminState = {
  loading: false,
  data: [],
  totalPage: 0,
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    resetAdmin: resetAdminAction,
  },
  extraReducers: adminExtraReducer,
});

export default adminSlice.reducer;

export const { resetAdmin } = adminSlice.actions;

export {
  getAdminsAction as getAdmins,
  addAdminAction as addAdmin,
  deleteAdminAction as deleteAdmin,
  updateAdminAction as updateAdmin,
};
