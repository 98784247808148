import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { TasksState } from './slice';
import { AlertModal } from '../../components/modals';
import { Task } from '../../interfaces';
import { closeModal, openModal } from '../modal/slice';
import { AppDispatch, RootState } from '../../store';
import { getTasksAPI } from '../../services/api/task';

export function resetTasksAction(state: TasksState) {
  state.loading = false;

  state.data = [];

  state.totalPage = 0;
}

export const getTasksAction = createAsyncThunk<
  { data: Task[]; total_page: number },
  { query: string },
  { state: RootState; dispatch: AppDispatch }
>('tasks/getTasks', async ({ query }, { getState, dispatch }) => {
  try {
    const { token } = getState().auth;

    if (!token) throw null;

    const result = await getTasksAPI(token, query);

    return result;
  } catch (error) {
    const message = (error as Error).message;

    const close = () => dispatch(closeModal());

    dispatch(openModal(AlertModal({ close, message })));

    throw error;
  }
});

export function tasksExtraReducer(builder: ActionReducerMapBuilder<TasksState>) {
  builder
    .addCase(getTasksAction.pending, (state) => {
      state.loading = true;
    })
    .addCase(getTasksAction.fulfilled, (state, action) => {
      state.loading = false;

      state.data = action.payload.data;

      state.totalPage = action.payload.total_page;
    })
    .addCase(getTasksAction.rejected, (state) => {
      state.loading = false;
    });
}
