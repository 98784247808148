import { ComponentPropsWithoutRef, ReactNode } from 'react';

interface PrimaryButtonProps extends ComponentPropsWithoutRef<'button'> {
  children: ReactNode;
  loading?: boolean;
  color?:
    | 'normal'
    | 'dark-green'
    | 'tomatto'
    | 'light-green'
    | 'red'
    | 'secondary-button-700'
    | 'secondary-button-200'
    | 'secondary-button-500'
    | 'disabled';
  rounded?: 'normal' | 'large' | 'larger';
  width?: number;
  size?: string;
}

export default function PrimaryButton({
  children,
  loading,
  color,
  rounded,
  width,
  size,
  ...rest
}: PrimaryButtonProps) {
  const customStyle = convertToClassName({ color, rounded, size });
  return (
    <button
      className={`${
        loading
          ? 'cursor-not-allowed rounded-lg border border-netral-4 bg-netral-4 text-netral-5'
          : `text-white ${customStyle}`
      } whitespace-nowrap border  w-[${width}px] font-semibold`}
      disabled={rest.disabled || loading}
      {...rest}
    >
      {children}
    </button>
  );
}

interface convertToClassNameProps {
  color?: string;
  rounded?: string;
  size?: string;
}

function convertToClassName({
  color = 'normal',
  rounded = 'normal',
  size = 'normal',
}: convertToClassNameProps) {
  if (color === 'normal') color = 'bg-secondary-button-600 border-secondary-button-600';
  if (color === 'dark-green') color = 'bg-primary-800 border-primary-800';
  if (color === 'tomatto') color = 'bg-secondary3-500 border-secondary3-500';
  if (color === 'light-green') color = 'bg-primary-500 border-primary-500';
  if (color === 'secondary-button-700')
    color = 'bg-secondary-button-700 border-secondary-button-700';
  if (color === 'secondary-button-200')
    color = 'bg-secondary-button-200 border-secondary-button-200';
  if (color === 'secondary-button-500')
    color = 'bg-secondary-button-500 border-secondary-button-500';
  if (color === 'red') color = 'bg-secondary3-600 border-secondary3-600';
  if (color === 'disabled') color = 'bg-netral-5 border-netral-5';

  if (rounded === 'normal') rounded = 'rounded';
  if (rounded === 'large') rounded = 'rounded-lg';
  if (rounded === 'larger') rounded = 'rounded-xl';

  if (size === 'normal') size = 'py-2 px-3';
  if (size === 'small') size = 'py-1 px-2 text-sm';

  return `${color} ${rounded} ${size}`;
}
