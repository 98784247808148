import { ReactNode } from 'react';
import ParticipantDetailModal from './ParticipantDetailModal';
import EducatorDetailModal from './EducatorDetailModal';
import SimpleInputModal from './SimpleInputModal';
import TransactionModal from './TransactionModal';
import ImageModal from './ImageModal';
import PaymentDetailModal from './PaymentDetailModal';
import GenerateCodeModal from './GenerateCodeModal';
import AddPromoModal from './AddPromoModal';
import AssignCourseModal from './AssignCourseModal';
import AddAdminModal from './AddAdminModal';
import AlertModal from './AlertModal';
import AddProductCodeModal from './AddProductCodeModal';
import SuccessNotifModal from './SuccessNotifModal';
import ResetPasswordModal from './ResetPasswordModal';
import CreateUserModal from './CreateUserModal';
import SelectTemplateNotifModal from './SelectTemplateNotifModal';
import RatingDetailModal from './RatingDetailModal';
import ConfirmModal from './ConfirmModal';

interface ModalProps {
  children?: ReactNode;
  showModal: boolean;
  closeModal: () => void;
}

export default function Modal({ showModal, closeModal, children }: ModalProps) {
  return (
    <div
      className={`${
        showModal ? 'z-[9999] backdrop-blur-[8px]' : 'z-[-9999] opacity-0'
      } absolute top-0 left-0 flex h-full w-full items-center justify-center duration-300`}
      onClick={closeModal}
      style={{
        background: 'linear-gradient(103.95deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%)',
      }}
    >
      <div onClick={(e) => e.stopPropagation()}>{children}</div>
    </div>
  );
}

export {
  ParticipantDetailModal,
  EducatorDetailModal,
  SimpleInputModal,
  TransactionModal,
  ImageModal,
  PaymentDetailModal,
  GenerateCodeModal,
  AddPromoModal,
  AssignCourseModal,
  AddAdminModal,
  AlertModal,
  AddProductCodeModal,
  SuccessNotifModal,
  ResetPasswordModal,
  CreateUserModal,
  SelectTemplateNotifModal,
  RatingDetailModal,
  ConfirmModal,
};
