import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import AuthenticationRoute from '../routes/AuthenticationRoute';

export default function LoginLayout() {
  return (
    <AuthenticationRoute>
      <div className="flex h-screen">
        <img alt="logo" className="h-auto w-1/2 object-cover" src="/assets/images/Group 1727.png" />
        <div className="flex h-full w-1/2 items-center justify-center">
          <Suspense fallback={<div className="h-[300px] rounded-xl bg-white" />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </AuthenticationRoute>
  );
}
