import { ComponentPropsWithoutRef, useState } from 'react';
import { PrimaryButton, SecondaryButton } from '../buttons';

interface LabelFileInputProps extends ComponentPropsWithoutRef<'input'> {
  id: string;
  label: string;
}

export default function LabelFileInput({ id, label, ...rest }: LabelFileInputProps) {
  const [file, setFile] = useState<File>();
  const url = (file && URL.createObjectURL(file)) || '';

  return (
    <div className="flex w-full flex-col space-y-2">
      <label className="text-xs font-semibold text-netral-8" htmlFor={id}>
        {label}
      </label>
      <div className="w-max space-y-2">
        {file && (
          <img
            alt={url}
            className="h-[230px] w-[270px] overflow-hidden rounded-lg object-cover"
            src={url}
          />
        )}
        <div className={`${file && 'flex justify-center'} w-full`}>
          {file ? (
            <PrimaryButton color="tomatto">
              <div className="relative">
                <p>Change Imagee</p>
                <input
                  className="absolute top-0 left-0 h-full w-full opacity-0"
                  id={id}
                  onChange={(e) => e.target.files !== null && setFile(e.target.files[0])}
                  type="file"
                  {...rest}
                />
              </div>
            </PrimaryButton>
          ) : (
            <SecondaryButton type="button">
              <div className="relative">
                <p>Pilih File</p>
                <input
                  className="absolute top-0 left-0 h-full w-full opacity-0"
                  id={id}
                  onChange={(e) => e.target.files !== null && setFile(e.target.files[0])}
                  type="file"
                  {...rest}
                />
              </div>
            </SecondaryButton>
          )}
        </div>
      </div>
    </div>
  );
}
