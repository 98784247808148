import { CloseButton, PrimaryButton } from '../buttons';
import { LabelInput, LabelSelectionInput } from '../inputs';

interface AddPromoModalProps {
  close: () => void;
}

export default function AddPromoModal({ close }: AddPromoModalProps) {
  return (
    <div className="w-[438px] rounded-2xl border border-netral-5 bg-white p-6">
      <div className="flex items-center justify-between border-b border-b-netral-4 pb-3">
        <h6 className="font-semibold text-netral-8">Generate Code</h6>
        <CloseButton onClick={close} type="button" />
      </div>
      <div className="mt-[13px] space-y-4">
        <div className="flex items-center space-x-6">
          <LabelInput id="promo-code" label="Kode Promo" />
          <LabelInput id="promo-expired" label="Expired" type="date" />
        </div>
        <div className="flex items-center space-x-6">
          <LabelSelectionInput
            id="promo-discount-type"
            label="Tipe  Potongan"
            options={['Persen']}
          />
          <LabelInput id="discount" label="Potongan" type="number" />
        </div>
        <div className="flex items-center space-x-6">
          <LabelSelectionInput
            id="promo-status"
            label="Status"
            options={['Aktif', 'Tidak Aktif']}
          />
          <div className="w-full"></div>
        </div>
      </div>
      <div className="mt-6 flex justify-center">
        <PrimaryButton color="dark-green">Generate</PrimaryButton>
      </div>
    </div>
  );
}
