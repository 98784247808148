import { Dashboard } from '../interfaces';

const initialDashboardData: Dashboard = {
  status: true,
  message: '',
  total_learner: 0,
  total_instructor: 0,
  total_voucher_skills: {
    total_voucher_skills_unused: 0,
    total_voucher_skills_used: 0,
    total_voucher_skills: 0,
  },
  total_voucher_partner: {
    total_voucher_partner_used: 0,
    total_voucher_partner_unused: 0,
    total_voucher_partner: 0,
  },
  total_class: {
    total_class_publish: 0,
    total_class_takedown: 0,
    total_class_wait_modetarion: 0,
    total_class: 0,
  },
  total_nilai_invoice_terbayar: {
    total_invoice_terbayar_this_month: 0,
    total_invoice_terbayar_last_month: 0,
    conversion: 'Down' as const,
    conversion_persen: '',
  },
  new_user: {
    user_this_week: { Kamis: 0, Rabu: 0, Selasa: 0, Senin: 0, Minggu: 0, Sabtu: 0, Jumat: 0 },
    user_last_week: { Kamis: 0, Rabu: 0, Selasa: 0, Senin: 0, Minggu: 0, Sabtu: 0, Jumat: 0 },
  },
  new_class: {
    class_this_week: { Kamis: 0, Rabu: 0, Selasa: 0, Senin: 0, Minggu: 0, Sabtu: 0, Jumat: 0 },
    class_last_week: { Kamis: 0, Rabu: 0, Selasa: 0, Senin: 0, Minggu: 0, Sabtu: 0, Jumat: 0 },
  },
};

export default initialDashboardData;
