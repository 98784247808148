import { AuthState } from './slice';
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { loginAPI } from '../../services/api/auth';
import { closeModal, openModal } from '../modal/slice';
import { AlertModal } from '../../components/modals';

export const loginAction = createAsyncThunk(
  'auth/login',
  async ({ username, password }: { username: string; password: string }, { dispatch }) => {
    try {
      const result = await loginAPI(username, password);

      const isAdmin = result.user.role.some((el) => el === 'Admin');

      const isSuperAdmin = result.user.role.some((el) => el === 'SuperAdmin');

      if (isAdmin || isSuperAdmin) return result;

      throw new Error('forbidden');
    } catch (error) {
      const message = (error as Error).message;

      const close = () => dispatch(closeModal());

      dispatch(openModal(AlertModal({ close, message })));

      throw error;
    }
  },
);

export function logoutAction(state: AuthState) {
  state.isAuthenticated = false;
  state.token = '';
  localStorage.removeItem('token');
}

export function checkTokenAction(state: AuthState) {
  const localToken = localStorage.getItem('token');

  if (!localToken) return;

  if (state.isAuthenticated) return;

  state.isAuthenticated = true;

  state.token = localToken;
}

export function authExtraReducer(builder: ActionReducerMapBuilder<AuthState>) {
  builder
    .addCase(loginAction.pending, (state) => {
      state.loading = true;
    })
    .addCase(loginAction.fulfilled, (state, action) => {
      state.loading = false;

      state.isAuthenticated = true;

      state.token = action.payload.token;

      localStorage.setItem('token', action.payload.token);
    })
    .addCase(loginAction.rejected, (state) => {
      state.loading = false;
    });
}
