export default function NotificationIcon({ ...rest }) {
  return (
    <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M8.83333 8.50065C8.83333 8.13398 8.53333 7.83398 8.16667 7.83398C7.8 7.83398 7.5 8.13398 7.5 8.50065V9.16732H8.83333V8.50065Z"
        fill="currentColor"
      />
      <path
        d="M4.5 4.66667V12.6667H11.8333V4.66667H4.5ZM6.83333 9.16667V8.5C6.83333 7.86667 7.26667 7.36667 7.83333 7.2V6.9C7.83333 6.7 7.96667 6.56667 8.16667 6.56667C8.36667 6.56667 8.5 6.7 8.5 6.9V7.2C9.06667 7.33333 9.5 7.86667 9.5 8.5V9.16667C9.7 9.16667 9.83333 9.3 9.83333 9.5C9.83333 9.7 9.7 9.83333 9.5 9.83333H8.66667C8.66667 10.1 8.43333 10.3333 8.16667 10.3333C7.9 10.3333 7.66667 10.1 7.66667 9.83333H6.83333C6.63333 9.83333 6.5 9.7 6.5 9.5C6.5 9.3 6.63333 9.16667 6.83333 9.16667ZM4.5 13.3333V15C4.5 15.2 4.63333 15.3333 4.83333 15.3333H11.5C11.7 15.3333 11.8333 15.2 11.8333 15V13.3333H4.5ZM8.16667 13.8333C8.43333 13.8333 8.66667 14.0667 8.66667 14.3333C8.66667 14.6 8.43333 14.8333 8.16667 14.8333C7.9 14.8333 7.66667 14.6 7.66667 14.3333C7.66667 14.0667 7.9 13.8333 8.16667 13.8333ZM11.5 2H4.83333C4.63333 2 4.5 2.13333 4.5 2.33333V4H11.8333V2.33333C11.8333 2.13333 11.7 2 11.5 2ZM8.83333 3.33333H7.5C7.3 3.33333 7.16667 3.2 7.16667 3C7.16667 2.8 7.3 2.66667 7.5 2.66667H8.83333C9.03333 2.66667 9.16667 2.8 9.16667 3C9.16667 3.2 9.03333 3.33333 8.83333 3.33333Z"
        fill="currentColor"
      />
    </svg>
  );
}
