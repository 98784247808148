import { KeyboardArrowRightIcon } from '../icons';

interface TablePaginationProps {
  totalPage: number;
  page: number;
  // eslint-disable-next-line no-unused-vars
  changePage: (page: number) => void;
}

export default function TablePagination({ totalPage, changePage, page }: TablePaginationProps) {
  const disabledPrev = page <= 1;
  const disabledNext = page >= totalPage;

  return (
    <div className="flex before:flex-1">
      <div className="flex flex-1 justify-center">
        <p className="text-sm text-netral-8">
          Page {page} / {totalPage}
        </p>
      </div>
      <div className="flex flex-1 items-center justify-end space-x-4">
        <button disabled={disabledPrev} onClick={() => changePage(page - 1)} type="button">
          <KeyboardArrowRightIcon
            className={`${disabledPrev ? 'text-netral-5' : 'text-netral-10'} h-6 w-6 `}
          />
        </button>
        {paginationNum(page, totalPage, 3).map((el, i) => (
          <button
            key={i}
            className={page !== el ? 'text-netral-5' : 'cursor-not-allowed text-netral-10'}
            disabled={page === el}
            onClick={() => changePage(el)}
          >
            {el}
          </button>
        ))}
        <button disabled={disabledNext} onClick={() => changePage(page + 1)} type="button">
          <KeyboardArrowRightIcon
            className={`${disabledNext ? 'text-netral-5' : 'text-netral-10'} h-6 w-6 rotate-180`}
          />
        </button>
      </div>
    </div>
  );
}

function paginationNum(page: number, totalPage: number, interval: number) {
  const result = [];

  if (page === totalPage && page > interval)
    for (let i = page - (interval - 1); i <= totalPage; i++) result.push(i);

  if (page >= interval && page !== totalPage)
    for (let i = page - Math.floor(interval / 2); i <= page + Math.floor(interval / 2); i++)
      result.push(i);

  if (page < interval)
    for (let i = 1; i <= totalPage; i++) {
      result.push(i);

      if (result.length >= interval) break;
    }

  return result;
}

TablePagination.defaultProps = {
  page: 1,
  totalPage: 1,
  changePage: (page: number) => alert('unhandle func\npage = ' + page),
};
