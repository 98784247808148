import axios from 'axios';
import { APIBaseURL } from '../../environment';
import { Attendances, Course, CourseDetail } from '../../interfaces';

interface GetCoursesAPI {
  status: boolean;
  totalPage: number;
  data: Course[];
  linkDownload: string;
}

interface UpdateCourseStatusAPI {
  status: boolean;
  message: string;
}

interface GetCourseAPI {
  status: boolean;
  message: string;
  data: CourseDetail;
}

interface GetLearnerCourseAPI {
  status: boolean;
  totalPage: number;
  result: {
    name: string;
    isReported: boolean;
    status: string;
    voucherCode: string;
    courseTitle: string;
    redeemDate: string;
    pretest: number;
    posttest: number;
    skilltest: number;
    progress_id: string;
    attendances: Attendances[] | [];
    _id: string;
  }[];
}

export async function getCoursesAPI(token: string, query: string): Promise<GetCoursesAPI> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(`${APIBaseURL}/api/admin/course${query}`, requestOptions);

  const result = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (!result.status) throw new Error(result.message);

  return result;
}

export async function updateCourseStatusAPI(
  token: string,
  courseId: string,
  status: 'Publish' | 'Unpublish',
): Promise<UpdateCourseStatusAPI> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

  const urlencoded = new URLSearchParams();
  urlencoded.append('status', status);

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow',
  };

  const response = await fetch(`${APIBaseURL}/api/admin/publishClass/${courseId}`, requestOptions);

  const result = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (!result.status) throw new Error(result.message);

  return result;
}

export async function getCourseAPI(token: string, courseId: string): Promise<GetCourseAPI> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(`${APIBaseURL}/api/class/detailCourse/${courseId}`, requestOptions);

  const result = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (!result.status) throw new Error(result.message);

  return result;
}

export async function editDiscountAPI(
  token: string,
  courseId: string,
  discount: string,
): Promise<GetCourseAPI> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  const urlencoded = new URLSearchParams();
  urlencoded.append('discount', discount);

  const requestOptions: RequestInit = {
    method: 'PATCH',
    headers: myHeaders,
    redirect: 'follow',
    body: urlencoded,
  };

  const response = await fetch(`${APIBaseURL}/api/admin/discount/${courseId}`, requestOptions);

  const result = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (!result.status) throw new Error(result.message);

  return result;
}

export async function getLearnerCourseAPI(
  token: string,
  courseId: string,
  query: string,
  name: string,
): Promise<GetLearnerCourseAPI> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(
    `${APIBaseURL}/api/admin/class-learners/${courseId}?${query}&name=${name}`,
    requestOptions,
  );

  const result = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (!result.status) throw new Error(result.message);

  return result;
}

export async function UpdateProductCode(
  token: string,
  productCode: string,
  courseTitle: string,
  vendor: string,
): Promise<any> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

  const urlencoded = new URLSearchParams();
  urlencoded.append('product_code', productCode);
  urlencoded.append('courseTitle', courseTitle);
  urlencoded.append('vendor', vendor);

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow',
  };
  const response = await fetch(`${APIBaseURL}/api/class/updateProductCode`, requestOptions);

  const result = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (!result.status) throw new Error(result.message);

  return result;
}

export async function updateAttendancesAPI(
  token: string,
  data: { _id: string; day: number }[],
): Promise<any> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  // const requestOptions: RequestInit = {
  //   method: 'PUT',
  //   headers: myHeaders,
  //   redirect: 'follow',
  //   body: JSON.stringify({ codes: data }),
  // };

  // const response = await fetch(`${APIBaseURL}/api/admin/update-report`, requestOptions);

  const response = await axios({
    method: 'put',
    url: `${APIBaseURL}/api/admin/class-learners/attendances`,
    headers: { Authorization: `Bearer ${token}` },
    data: data,
  });
  const result = await response.data;

  // const result = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (result.status === false) throw new Error(result.message);

  return result;
}
