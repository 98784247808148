import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Modal from './components/modals';
import Page from './routes';
import { useTypedDispatch, useTypedSelector } from './store';
import { closeModal } from './features/modal/slice';
import { checkToken } from './features/auth/slice';

function App() {
  const { pathname, search } = useLocation();
  const { showModal, modalComponent } = useTypedSelector((state) => state.modal);
  const dispatch = useTypedDispatch();

  useEffect(() => {
    dispatch(checkToken());
  }, [pathname, search]);

  return (
    <div className="relative h-screen w-screen overflow-hidden">
      <Modal closeModal={() => dispatch(closeModal())} showModal={showModal}>
        {modalComponent}
      </Modal>
      <Page />
    </div>
  );
}

export default App;
