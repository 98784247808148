import { createSlice } from '@reduxjs/toolkit';
import { TaskDetail } from '../../interfaces';
import { getTaskAction, resetTaskAction, taskExtraReducer } from './action';

export interface TaskState {
  data: TaskDetail | null;
  totalPage: number;
  loading: boolean;
}

const initialState: TaskState = {
  data: null,
  totalPage: 0,
  loading: false,
};

export const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: { resetTask: resetTaskAction },
  extraReducers: taskExtraReducer,
});

export default taskSlice.reducer;

export const { resetTask } = taskSlice.actions;

export { getTaskAction as getTask };
