import { createSlice } from '@reduxjs/toolkit';
import { Learner } from '../../interfaces';
import { getLearnersAction, learnersExtraReducer, resetLeanersAction } from './action';

export interface LearnersState {
  data: Learner[];
  totalPage: number;
  loadingData: boolean;
  name: string;
}

const initialState: LearnersState = {
  data: [],
  totalPage: 0,
  loadingData: false,
  name: '',
};

export const learnersSlice = createSlice({
  name: 'learners',
  initialState,
  reducers: {
    resetLeaner: resetLeanersAction,
  },
  extraReducers: learnersExtraReducer,
});

export default learnersSlice.reducer;

export const { resetLeaner } = learnersSlice.actions;

export { getLearnersAction as getLearners };
