import { createSlice } from '@reduxjs/toolkit';
import { CourseDetail } from '../../interfaces';
import { courseExtraReducer, getCourseAction, resetCourseAction } from './action';

export interface CourseState {
  data: CourseDetail | null;
  loading: boolean;
}

const initialState: CourseState = {
  data: null,
  loading: false,
};

export const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: { resetCourse: resetCourseAction },
  extraReducers: courseExtraReducer,
});

export default courseSlice.reducer;

export const { resetCourse } = courseSlice.actions;

export { getCourseAction as getCourse };
