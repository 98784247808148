import { APIBaseURL } from '../../environment';
import { Task, TaskDetail } from '../../interfaces';

interface GetTasksAPI {
  message: string;
  code: number;
  total_page: number;
  data: Task[];
}

interface GetTaskAPI {
  message: string;
  code: number;
  total_page: number;
  data: [TaskDetail];
}

export async function getTasksAPI(token: string, query: string): Promise<GetTasksAPI> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };
  const response = await fetch(`${APIBaseURL}/api/admin/question/all${query}`, requestOptions);

  const result: GetTasksAPI = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (result.code !== 200) throw new Error(result.message);

  return result;
}

export async function getTaskAPI(
  token: string,
  taskId: string,
  query: string,
): Promise<GetTaskAPI> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };
  const response = await fetch(
    `${APIBaseURL}/api/admin/question/detail/${taskId + query}`,
    requestOptions,
  );

  const result: GetTaskAPI = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (result.code !== 200) throw new Error(result.message);

  return result;
}
