import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ReactNode } from 'react';
import { loading } from '../../constants';
import { ModalState } from './slice';

export const closeModalAction = (state: ModalState) => {
  state.showModal = false;
  state.modalComponent = '';
};

export const toggleModalAction = (state: ModalState) => {
  state.showModal = !state.showModal;
};

export const openModal = createAsyncThunk('modal/openModal', async (modalComponent: ReactNode) => {
  await loading(150);
  return modalComponent;
});

export const modalExtraReducer = (builder: ActionReducerMapBuilder<ModalState>) => {
  builder
    .addCase(openModal.pending, (state) => {
      state.showModal = true;
    })
    .addCase(openModal.fulfilled, (state, action: PayloadAction<ReactNode>) => {
      state.modalComponent = action.payload;
    });
};
