import { Rating } from '../../interfaces/rating';
import { CloseButton } from '../buttons';
import { ProfileCard } from '../cards';
import Stars from '../stars';

interface RatingDetailModalProps {
  close: () => void;
  data: Rating;
}

export default function RatingDetailModal({ close, data }: RatingDetailModalProps) {
  return (
    <div className="relative w-full px-4 lg:w-auto lg:p-0">
      <div className="w-[850px] space-y-[16px] rounded-lg bg-white p-6">
        <div className="flex justify-between">
          <ProfileCard imageURL={data.profile_picture} name={data.name} />
          <CloseButton onClick={close} type="button" />
        </div>
        <div className="flex flex-col space-y-[4px]">
          <Stars n={data.rating} size="h-[20px] w-[20px]" />
          <p>{data.review}</p>
          <p className="text-xs text-netral-6">{data.createdAt}</p>
        </div>
      </div>
    </div>
  );
}
