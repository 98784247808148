import { createSlice } from '@reduxjs/toolkit';
import initialDashboardData from '../../constants/initial';
import { Dashboard } from '../../interfaces';
import { dashboardExtraReducer, getDashboardAction, resetDashboardAction } from './action';

export interface DashboardState {
  loadingData: boolean;
  data: Dashboard;
}

const initialState: DashboardState = {
  loadingData: false,
  data: initialDashboardData,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    resetDashboard: resetDashboardAction,
  },
  extraReducers: dashboardExtraReducer,
});

export default dashboardSlice.reducer;

export const { resetDashboard } = dashboardSlice.actions;

export { getDashboardAction as getDashboard };
