import { APIBaseURL } from '../../environment';
import { Certificate } from '../../interfaces';

interface GetCertificatesAPI {
  message: string;
  status: boolean;
  totalPage: number;
  result: Certificate[];
}

export async function getCertificatesAPI(
  token: string,
  query: string,
): Promise<GetCertificatesAPI> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(`${APIBaseURL}/api/admin/certificateData${query}`, requestOptions);

  const result: GetCertificatesAPI = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (!result.status) throw new Error(result.message);

  return result;
}
