import { Suspense, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import AuthenticatedRoute from '../routes/AuthenticatedRoute';
import { DashboardNavButton, HomepageButton } from '../components/buttons';
import { DashboardHeader } from '../components/headers';
import { useTypedDispatch, useTypedSelector } from '../store';
import { getUser } from '../features/user/slice';
import {
  DashboardIcon,
  MaleStudentIcon,
  ProfessorIcon,
  EbookIcon,
  CashierMachineIcon,
  WalletIcon,
  CertificateIcon,
  OnlineBlogIcon,
  EVoucherIcon,
  PromotionIcon,
  NotificationIcon,
  MultipleChoiceQuizIcon,
} from '../components/icons';
import RatingIcon from '../components/icons/RatingIcon';

export default function DashboardLayout() {
  const { data } = useTypedSelector((state) => state.user);
  const dispatch = useTypedDispatch();

  const { pathname } = useLocation();
  const isProfilePage =
    pathname.split('/')[1] === 'profile' ||
    (pathname.split('/').length > 2 && pathname.split('/')[1] === 'learner') ||
    (pathname.split('/').length > 2 && pathname.split('/')[1] === 'educator');

  useEffect(() => {
    dispatch(getUser());
  }, []);

  return (
    <AuthenticatedRoute>
      <div className="flex h-full bg-[#F8F9FA]">
        <aside className="py-4 pl-8">
          <div className="overlay h-full w-[155px] overflow-auto">
            <HomepageButton />
            <nav className="mt-4">
              <ul className="space-y-3">
                {navLinks.map((nav, i) => (
                  <li key={i}>
                    <DashboardNavButton Icon={nav.icon} link={nav.link}>
                      {nav.name}
                    </DashboardNavButton>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </aside>
        <div className="flex h-full w-full flex-col overflow-auto pl-[52px]">
          {!isProfilePage ? (
            <>
              <header className="pr-8 pt-4 pb-2">
                <DashboardHeader
                  imageURL={data?.profile_picture.link || ''}
                  name={data?.name || ''}
                />
              </header>
              <div className="overlay h-full w-full  pr-8 pt-6 pb-[46px]">
                <main>
                  <Suspense fallback={<div className="h-[300px] rounded-xl bg-white" />}>
                    <Outlet />
                  </Suspense>
                </main>
              </div>
            </>
          ) : (
            <main className="overlay h-full w-full ">
              <Suspense fallback={<div className="h-[300px] rounded-xl bg-white" />}>
                <Outlet />
              </Suspense>
            </main>
          )}
        </div>
      </div>
    </AuthenticatedRoute>
  );
}

const navLinks = [
  { name: 'Dashboard', link: '/', icon: DashboardIcon },
  { name: 'Learner  ', link: '/learner', icon: MaleStudentIcon },
  { name: 'Educator', link: '/educator', icon: ProfessorIcon },
  { name: 'Kursus', link: '/course', icon: EbookIcon },
  { name: 'Rating', link: '/rating', icon: RatingIcon },
  { name: 'Transaksi', link: '/transaction', icon: CashierMachineIcon },
  { name: 'Prakerja', link: '/prakerja', icon: WalletIcon },
  { name: 'Sertifikat', link: '/certificate', icon: CertificateIcon },
  { name: 'Blog', link: '/blog', icon: OnlineBlogIcon },
  { name: 'Artikel & Banner', link: '/article-and-banner', icon: OnlineBlogIcon },
  { name: 'Voucher', link: '/voucher', icon: EVoucherIcon },
  { name: 'Promo', link: '/promo', icon: PromotionIcon },
  { name: 'Kirim Pesan', link: '/notification', icon: NotificationIcon },
  { name: 'Bank Soal', link: '/tasks', icon: MultipleChoiceQuizIcon },
];
