import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { InstructorsState } from './slice';
import { AlertModal } from '../../components/modals';
import { getInstructorsAPI } from '../../services/api/instructor';
import { Instructor } from '../../interfaces';
import { closeModal, openModal } from '../modal/slice';
import { AppDispatch, RootState } from '../../store';

export function resetInstructosAction(state: InstructorsState) {
  state.loadingData = false;

  state.data = [];

  state.totalPage = 0;
}

export const getInstructorsAction = createAsyncThunk<
  { data: Instructor[]; status: boolean; total_page: number },
  { limit: number; page: number; search: string },
  { state: RootState; dispatch: AppDispatch }
>('instructors/getInstructors', async ({ limit, page, search }, { getState, dispatch }) => {
  try {
    const { token } = getState().auth;

    if (!token) throw null;

    const result = await getInstructorsAPI(token, limit, page, search);

    return result;
  } catch (error) {
    const message = (error as Error).message;

    const close = () => dispatch(closeModal());

    dispatch(openModal(AlertModal({ close, message })));

    throw error;
  }
});

export function instructorsExtraReducer(builder: ActionReducerMapBuilder<InstructorsState>) {
  builder
    .addCase(getInstructorsAction.pending, (state) => {
      state.loadingData = true;
    })
    .addCase(getInstructorsAction.fulfilled, (state, action) => {
      state.loadingData = false;

      state.data = action.payload.data;

      state.totalPage = action.payload.total_page;
    })
    .addCase(getInstructorsAction.rejected, (state) => {
      state.loadingData = false;
    });
}
