export default function WalletIcon({ ...rest }) {
  return (
    <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_855_1360)">
        <path
          d="M13.3334 4.00065H11.8767C11.95 3.79065 12 3.57065 12 3.33398C12 2.23065 11.1034 1.33398 10 1.33398C9.30337 1.33398 8.69004 1.69065 8.33337 2.23065L8.00004 2.68398L7.66671 2.23065C7.31004 1.69065 6.69671 1.33398 6.00004 1.33398C4.89671 1.33398 4.00004 2.23065 4.00004 3.33398C4.00004 3.57065 4.04671 3.79065 4.12337 4.00065H2.66671C1.93004 4.00065 1.34004 4.59732 1.34004 5.33398L1.33337 12.6673C1.33337 13.404 1.93004 14.0007 2.66671 14.0007H13.3334C14.07 14.0007 14.6667 13.404 14.6667 12.6673V5.33398C14.6667 4.59732 14.07 4.00065 13.3334 4.00065ZM10 2.66732C10.3667 2.66732 10.6667 2.96732 10.6667 3.33398C10.6667 3.70065 10.3667 4.00065 10 4.00065C9.63337 4.00065 9.33337 3.70065 9.33337 3.33398C9.33337 2.96732 9.63337 2.66732 10 2.66732ZM6.00004 2.66732C6.36671 2.66732 6.66671 2.96732 6.66671 3.33398C6.66671 3.70065 6.36671 4.00065 6.00004 4.00065C5.63337 4.00065 5.33337 3.70065 5.33337 3.33398C5.33337 2.96732 5.63337 2.66732 6.00004 2.66732ZM13.3334 12.6673H2.66671V11.334H13.3334V12.6673ZM13.3334 9.33398H2.66671V5.33398H6.05337L4.66671 7.22398L5.75004 8.00065L7.33337 5.84398L8.00004 4.93732L8.66671 5.84398L10.25 8.00065L11.3334 7.22398L9.94671 5.33398H13.3334V9.33398Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_855_1360">
          <rect fill="white" height="16" width="16" />
        </clipPath>
      </defs>
    </svg>
  );
}
