import { ComponentPropsWithoutRef } from 'react';

interface LabelSelectionInputProps extends ComponentPropsWithoutRef<'select'> {
  id: string;
  label: string;
  options: string[] | number[];
  defaultOption?: string;
}

export default function LabelSelectionInput({
  id,
  label,
  defaultOption,
  options,
  ...rest
}: LabelSelectionInputProps) {
  return (
    <div className="flex w-full flex-col space-y-2">
      <label className="w-full text-sm font-semibold text-netral-8" htmlFor={id}>
        {label}
      </label>
      <div className="w-full rounded border border-netral-7  py-[9.05px] px-2">
        <select className="w-full text-netral-9 focus:outline-none" {...rest}>
          {defaultOption && (
            <option disabled value={defaultOption}>
              {defaultOption}
            </option>
          )}
          {options.map((option, i) => (
            <option key={i}>{option}</option>
          ))}
        </select>
      </div>
    </div>
  );
}

LabelSelectionInput.defaultProps = {
  options: [],
};
