import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { CourseState } from './slice';
import { AlertModal } from '../../components/modals';
import { CourseDetail } from '../../interfaces';
import { closeModal, openModal } from '../modal/slice';
import { AppDispatch, RootState } from '../../store';
import { getCourseAPI } from '../../services/api/course';

export function resetCourseAction(state: CourseState) {
  state.loading = false;

  state.data = null;
}

export const getCourseAction = createAsyncThunk<
  CourseDetail,
  { courseId: string },
  { state: RootState; dispatch: AppDispatch }
>('course/getCourse', async ({ courseId }, { getState, dispatch }) => {
  try {
    const { token } = getState().auth;

    if (!token) throw null;

    const result = await getCourseAPI(token, courseId);

    return result.data;
  } catch (error) {
    const message = (error as Error).message;

    const close = () => dispatch(closeModal());

    dispatch(openModal(AlertModal({ close, message })));

    throw error;
  }
});

export function courseExtraReducer(builder: ActionReducerMapBuilder<CourseState>) {
  builder
    .addCase(getCourseAction.pending, (state) => {
      state.loading = true;
    })
    .addCase(getCourseAction.fulfilled, (state, action) => {
      state.loading = false;

      state.data = action.payload;
    })
    .addCase(getCourseAction.rejected, (state) => {
      state.loading = false;
    });
}
