import { ChangeEvent, useState } from 'react';
import { loading } from '../../constants';
import { getInstructors } from '../../features/instructors/slice';
import { createUser } from '../../services/api/auth';
import { useTypedDispatch, useTypedSelector } from '../../store';
import { CloseButton } from '../buttons';
import { ChecklistIcon, CloseIconRed } from '../icons';
import { LabelLoginInput } from '../inputs';

function CreateUserModal({ close }: { close: () => void }) {
  const [dataToSend, setDataToSend] = useState({
    email: '',
    phoneNumber: '',
    name: '',
    password: '',
    confirm_password: '',
    role: ['Instructor'],
  });
  const [successResponse, setSuccessResponse] = useState<boolean>(Boolean);
  const [errorResponse, setErrorResponse] = useState<boolean>(Boolean);
  const [message, setMessage] = useState<string>('');
  const dispatch = useTypedDispatch();

  const { token } = useTypedSelector((state) => state.auth);

  function setFormInput(e: ChangeEvent<HTMLInputElement>) {
    setDataToSend({ ...dataToSend, [e.target.name]: e.target.value });
  }

  const handleSubmit = async () => {
    try {
      const res = await createUser(
        { ...dataToSend, phoneNumber: '62' + dataToSend.phoneNumber },
        token,
      );
      await setSuccessResponse(res.status);
      dispatch(getInstructors({ limit: 5, page: 1, search: '' }));
      await loading(1500);
      close();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setErrorResponse(!error.response.data.status);
      setMessage(error.response.data.message);
      await loading(2000);
      setErrorResponse(false);
      setMessage('');
    }
  };

  if (successResponse) {
    return (
      <div
        className="relative flex w-[438px] items-center justify-between rounded-2xl bg-white p-[24px]"
        style={{ filter: 'drop-shadow(1px 4px 16px rgba(0, 0, 0, 0.1))' }}
      >
        <p className="font-semibold text-netral-10">Berhasil Tambah Educator</p>
        <ChecklistIcon className="h-8 w-8 text-secondary3-500" />
      </div>
    );
  }

  if (errorResponse) {
    return (
      <div
        className="relative flex w-[438px] items-center justify-between rounded-2xl bg-white p-[24px]"
        style={{ filter: 'drop-shadow(1px 4px 16px rgba(0, 0, 0, 0.1))' }}
      >
        <p className="font-semibold text-netral-10">{message}</p>
        <CloseIconRed className="h-8 w-8 text-secondary3-500" />
      </div>
    );
  }

  return (
    <div
      className="relative flex w-[438px] flex-col rounded-2xl bg-white p-6"
      style={{ filter: 'drop-shadow(1px 4px 16px rgba(0, 0, 0, 0.1))' }}
    >
      <div className="absolute right-5">
        <CloseButton onClick={close} type="button" />
      </div>
      <div>
        <p className="text-base font-semibold text-netral-10">Tambah Educator</p>
        <p className="text-xs text-netral-6">Isi data educator dibawah ini untuk menambah</p>
      </div>
      <div className="mt-[15px]">
        <form onSubmit={(e) => e.preventDefault()}>
          <LabelLoginInput
            autoComplete="new-password"
            name="name"
            onChange={setFormInput}
            placeholder="Nama"
            type="text"
            value={dataToSend.name}
          />
          <LabelLoginInput
            autoComplete="new-password"
            name="email"
            onChange={setFormInput}
            placeholder="Email"
            type="email"
            value={dataToSend.email}
          />
          <form className="relative mt-[18px] w-full" onSubmit={(e) => e.preventDefault()}>
            <input
              className="w-full rounded border border-netral-7 bg-white p-3 pl-[42px]"
              name="phoneNumber"
              onChange={setFormInput}
              placeholder="Nomor handphone"
              type="text"
              value={dataToSend.phoneNumber}
            />
            <div className="absolute top-0 left-2 flex h-full items-center">+62</div>
          </form>
        </form>
      </div>
      <div className="mt-[15px]">
        <form onSubmit={(e) => e.preventDefault()}>
          <LabelLoginInput
            autoComplete="new-password"
            name="password"
            onChange={setFormInput}
            placeholder="Password"
            type="password"
            value={dataToSend.password}
          />
          <LabelLoginInput
            autoComplete="new-password"
            name="confirm_password"
            onChange={setFormInput}
            placeholder="Konfirmasi password"
            type="password"
            value={dataToSend.confirm_password}
            withEye
          />
        </form>
        <p className="mt-[12px]">* Minimal 8 digit, huruf besar dan angka</p>
      </div>
      <div className="mt-[24px] w-full">
        <button
          className={`${
            dataToSend.confirm_password === '' || dataToSend.password === ''
              ? 'border-netral-5 bg-netral-5'
              : 'border-secondary-button-600 bg-secondary-button-600'
          } w-full whitespace-nowrap rounded border px-3 py-2 font-semibold text-white`}
          disabled={dataToSend.confirm_password === '' || dataToSend.password === ''}
          onClick={() => handleSubmit()}
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default CreateUserModal;
