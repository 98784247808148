import { createSlice } from '@reduxjs/toolkit';
import { Course } from '../../interfaces';
import {
  getCoursesAction,
  coursesExtraReducer,
  resetCoursesAction,
  updateCourseStatusAction,
  setLoadingUpdateCourseAction,
} from './action';

export interface CoursesState {
  data: Course[];
  totalPage: number;
  loadingData: boolean;
  loadingUpdateCourseById: string;
  linkDownload: string;
}

const initialState: CoursesState = {
  data: [],
  totalPage: 0,
  loadingData: false,
  loadingUpdateCourseById: '',
  linkDownload: '',
};

export const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    resetCourses: resetCoursesAction,
    setLoadingUpdateCourse: setLoadingUpdateCourseAction,
  },
  extraReducers: coursesExtraReducer,
});

export default coursesSlice.reducer;

export const { resetCourses, setLoadingUpdateCourse } = coursesSlice.actions;

export { getCoursesAction as getCourses, updateCourseStatusAction as updateCourseStatus };
