import { APIBaseURL } from '../../environment';
import { Learner } from '../../interfaces';

interface GetLearnersAPI {
  status: boolean;
  total_page: number;
  result: Learner[];
}

export async function getLearnersAPI(
  token: string,
  limit: number,
  page: number,
  name: string,
  startDate: string,
  endDate: string,
): Promise<GetLearnersAPI> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(
    `${APIBaseURL}/api/admin/learner/all?page_size=${limit}&page_number=${page}&name=${name}&startDate=${startDate}&endDate=${endDate}`,
    requestOptions,
  );

  const result = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (!result.status) throw new Error(result.message);

  return result;
}

export async function downloadLearnersAPI(
  token: string,
  name: string,
  startDate: string,
  endDate: string,
): Promise<GetLearnersAPI> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(
    `${APIBaseURL}/api/admin/download-learner?&name=${name}&startDate=${startDate}&endDate=${endDate}`,
    requestOptions,
  );

  const result = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (!result.status) throw new Error(result.message);

  return result;
}
