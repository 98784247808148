import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { TaskState } from './slice';
import { AlertModal } from '../../components/modals';
import { TaskDetail } from '../../interfaces';
import { closeModal, openModal } from '../modal/slice';
import { AppDispatch, RootState } from '../../store';
import { getTaskAPI } from '../../services/api/task';

export function resetTaskAction(state: TaskState) {
  state.loading = false;

  state.data = null;

  state.totalPage = 0;
}

export const getTaskAction = createAsyncThunk<
  { data: [TaskDetail]; total_page: number },
  { query: string; taskId: string },
  { state: RootState; dispatch: AppDispatch }
>('task/getTask', async ({ query, taskId }, { getState, dispatch }) => {
  try {
    const { token } = getState().auth;

    if (!token) throw null;

    const result = await getTaskAPI(token, taskId, query);

    return result;
  } catch (error) {
    const message = (error as Error).message;

    const close = () => dispatch(closeModal());

    dispatch(openModal(AlertModal({ close, message })));

    throw error;
  }
});

export function taskExtraReducer(builder: ActionReducerMapBuilder<TaskState>) {
  builder
    .addCase(getTaskAction.pending, (state) => {
      state.loading = true;
    })
    .addCase(getTaskAction.fulfilled, (state, action) => {
      state.loading = false;

      state.data = action.payload.data[0];

      state.totalPage = action.payload.total_page;
    })
    .addCase(getTaskAction.rejected, (state) => {
      state.loading = false;
    });
}
