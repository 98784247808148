export default function DownloadIcon({ ...rest }) {
  return (
    <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M12 6.00065H10.6667C10.2667 6.00065 10 6.26732 10 6.66732C10 7.06732 10.2667 7.33398 10.6667 7.33398H12C12.4 7.33398 12.6667 7.60065 12.6667 8.00065V12.6673C12.6667 13.0673 12.4 13.334 12 13.334H4C3.6 13.334 3.33333 13.0673 3.33333 12.6673V8.00065C3.33333 7.60065 3.6 7.33398 4 7.33398H5.33333C5.73333 7.33398 6 7.06732 6 6.66732C6 6.26732 5.73333 6.00065 5.33333 6.00065H4C2.86667 6.00065 2 6.86732 2 8.00065V12.6673C2 13.8007 2.86667 14.6673 4 14.6673H12C13.1333 14.6673 14 13.8007 14 12.6673V8.00065C14 6.86732 13.1333 6.00065 12 6.00065ZM5.53333 9.80065L7.53333 11.8007C7.66667 11.934 7.8 12.0007 8 12.0007C8.2 12.0007 8.33333 11.934 8.46667 11.8007L10.4667 9.80065C10.7333 9.53398 10.7333 9.13398 10.4667 8.86732C10.2 8.60065 9.8 8.60065 9.53333 8.86732L8.66667 9.73398V2.00065C8.66667 1.60065 8.4 1.33398 8 1.33398C7.6 1.33398 7.33333 1.60065 7.33333 2.00065V9.73398L6.46667 8.86732C6.2 8.60065 5.8 8.60065 5.53333 8.86732C5.26667 9.13398 5.26667 9.53398 5.53333 9.80065Z"
        fill="currentColor"
      />
    </svg>
  );
}
