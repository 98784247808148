import { APIBaseURL } from '../../environment';
import User from '../../interfaces/user';

interface GetUserAPI {
  code: number;
  data: User;
  status: boolean;
}

export async function getUserAPI(token: string): Promise<GetUserAPI> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(`${APIBaseURL}/api/user/profile`, requestOptions);

  const result = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (!result.status) throw new Error(result.message);

  return result;
}
