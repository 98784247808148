import { APIBaseURL } from '../../environment';
import { Dashboard } from '../../interfaces';

export async function getDashboardAPI(token: string): Promise<Dashboard> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response: Response = await fetch(`${APIBaseURL}/api/admin/dashboard`, requestOptions);

  const result: Dashboard = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (!result.status) throw new Error(result.message);

  return result;
}
