import { ComponentPropsWithoutRef, ReactNode } from 'react';

interface TabButtonProps extends ComponentPropsWithoutRef<'button'> {
  children: ReactNode;
  active: boolean;
}

export default function TabButton({ active, children, ...rest }: TabButtonProps) {
  return (
    <button
      className={`${
        active ? 'bg-[#23C4F4] text-white' : 'text-netral-6'
      } rounded-lg p-2 text-lg font-semibold`}
      {...rest}
    >
      {children}
    </button>
  );
}
