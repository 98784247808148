import { useRef, useState } from 'react';
import { CloseButton, PrimaryButton, SecondaryButton } from '../buttons';
import { ChecklistIcon } from '../icons';
import { useDropzone } from 'react-dropzone';
import { APIBaseURL } from '../../environment';
import { useTypedSelector } from '../../store';

interface importProductCode {
  status: boolean;
  masssage: string;
  failed: string[];
}

interface AddProductCodeModalProps {
  close: () => void;
  // handleUpload: (file: File) => void;
  type: string;
  setData: any;
  url: string;
}

export default function AddReminderModal({ close, type, setData, url }: AddProductCodeModalProps) {
  // const [courses, setCourses] = useState<string[]>([]);
  const [response, setResponse] = useState({
    message: '',
    failed: [],
    status: false,
  });
  const ref = useRef<HTMLInputElement>(null);
  const { token } = useTypedSelector((state) => state.auth);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    // onDrop,
    // accept: 'image/jpeg, image/png',
    multiple: false,
  });

  async function uploadFile(file: File): Promise<importProductCode> {
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const urlencoded = new FormData();
    urlencoded.append('file', file);

    const requestOptions: RequestInit = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow',
    };

    const response = await fetch(`${APIBaseURL}/api/admin/${url}`, requestOptions);
    const result = await response.json();

    if (!result.status) throw new Error(result.message);

    setResponse(result);
    setData({ data: result.data });
    return result;
  }

  if (response.status) {
    return (
      <div
        className="relative flex w-[438px] items-center justify-between rounded-2xl bg-white p-[24px]"
        style={{ filter: 'drop-shadow(1px 4px 16px rgba(0, 0, 0, 0.1))' }}
      >
        <p className="font-semibold text-netral-10">Import Succeed</p>
        <ChecklistIcon className="h-8 w-8 text-secondary3-500" />
      </div>
    );
  }

  return (
    <div
      className="relative flex w-[438px] flex-col rounded-2xl bg-white p-6"
      style={{ filter: 'drop-shadow(1px 4px 16px rgba(0, 0, 0, 0.1))' }}
    >
      <div className="absolute right-5">
        <CloseButton onClick={close} type="button" />
      </div>
      <div>
        <p className="text-base font-semibold text-netral-10">Import File</p>
        <p className="text-xs text-netral-6">{type}</p>
      </div>
      <div {...getRootProps()} className="mt-[16px] ">
        <input {...getInputProps()} hidden name="image" ref={ref} type="file" />
        {acceptedFiles.length < 1 ? (
          <div className="flex flex-col items-center justify-center border border-dashed border-secondary-button-500 bg-[#F4FFFE] py-[53px] px-[128px]">
            <div className="text-center ">
              <p className="font-semibold text-netral-10">Tarik & Taruh file</p>
              <p className="whitespace-nowrap text-xs text-netral-6">
                Jenis file: Excel (.xls), (.csv)
              </p>
              <p className="text-xs text-netral-6">atau</p>
            </div>
            <div className="mt-[16px]">
              <SecondaryButton
                color="light-green"
                onClick={() => ref?.current?.click()}
                rounded="normal"
                size="small"
              >
                Upload File
              </SecondaryButton>
            </div>
          </div>
        ) : (
          <div>
            <div
              className={`flex items-center justify-between rounded border ${
                response.failed.length === 0
                  ? 'border-secondary-button-500'
                  : 'border-secondary3-500'
              } p-[8px]`}
            >
              <div>
                <p className="text-sm font-semibold text-netral-10">
                  {acceptedFiles[0].name.split('.')[0]}
                </p>
                <p className="text-xs text-netral-10">.{acceptedFiles[0].name.split('.')[1]}</p>
              </div>
              <div>
                <SecondaryButton
                  color="light-green"
                  onClick={() => ref?.current?.click()}
                  rounded="normal"
                  size="small"
                >
                  Ubah File
                </SecondaryButton>
              </div>
            </div>
          </div>
        )}
      </div>
      {response.failed.length > 0 && (
        <div className="mt-[8px] text-xs text-secondary3-500">
          <p>Kesalahan isi data excel :</p>
          <ul className="ml-3 list-disc">
            {response.failed.map((el, idx) => (
              <li key={idx}>{el}</li>
            ))}
          </ul>
          <p>mohon ubah isi data excel dan ulangi upload</p>
        </div>
      )}
      {acceptedFiles.length > 0 && (
        <div className="mt-[16px] text-end">
          <PrimaryButton color="secondary-button-700" onClick={() => uploadFile(acceptedFiles[0])}>
            Submit
          </PrimaryButton>
        </div>
      )}
    </div>
  );
}
