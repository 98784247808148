import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PrimaryButton } from '../../components/buttons';
import { ProfileCard } from '../../components/cards';
import { SearchIcon } from '../../components/icons';
import { RatingDetailModal } from '../../components/modals';
import { TablePagination } from '../../components/paginations';
import Stars from '../../components/stars';
import { DashboardTable } from '../../components/tables';
import { openModal } from '../../features/modal/action';
import { useQuery } from '../../hooks';
import { ClassWithRating } from '../../interfaces';
import { Rating } from '../../interfaces/rating';
import { getClassWithRatingAPI, getRatingByClassIdAPI } from '../../services/api/rating';
import { useTypedDispatch, useTypedSelector } from '../../store';
import { closeModal } from '../../features/modal/slice';

interface GetRating {
  totalData: number;
  totalPage: number;
  status: boolean;
  result: Rating[];
}

export default function RatingDetail() {
  const [data, setData] = useState<GetRating>({
    totalData: 0,
    totalPage: 0,
    status: false,
    result: [
      {
        name: '',
        _id: '',
        createdAt: '',
        review: '',
        file: '',
        rating: 0,
        profile_picture: '',
      },
    ],
  });
  const { token } = useTypedSelector((state) => state.auth);
  const dispatch = useTypedDispatch();
  const query = useQuery();
  const { pathname } = useLocation();
  const { rating_id } = useParams();

  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');
  const queryPage = query.get('page') || '1';

  const getClassWithRating = async (query: string) => {
    try {
      const res = await getRatingByClassIdAPI(token, query, rating_id || '');
      setData(res);
    } catch (error) {
      console.log(error);
    }
    return;
  };

  function openRatingDetailModal(data: Rating) {
    dispatch(openModal(<RatingDetailModal close={() => dispatch(closeModal())} data={data} />));
  }

  const [tableHeads, tableBodies] = useMemo(
    () => mapDataToLearnerTableData(data.result, (data) => openRatingDetailModal(data)),
    [data],
  );

  useEffect(() => {
    getClassWithRating(
      `page_size=10&page_number=${queryPage}&courseTitle=${search}&filter=${
        filter === 'semua rating ‎' ? '' : filter
      }`,
    );
  }, [token, filter, search, queryPage]);

  return (
    <div className="space-y-4 rounded-xl bg-white p-4">
      <div className="flex justify-between">
        <p className="text-lg font-semibold text-netral-10">Data Rating Kelas</p>
        <div className="flex">
          <div className="flex">
            <form className="relative mx-[20px]" onSubmit={(e) => e.preventDefault()}>
              <input
                className="rounded border border-netral-1-stroke bg-white p-1 pl-[42px]"
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Cari"
                type="search"
                value={search}
                // {...rest}
              />
              <div className="absolute top-0 left-2 flex h-full items-center">
                <SearchIcon className="h-6 w-6 text-[#323232]" />
              </div>
            </form>
          </div>
          <div className="flex w-full rounded border border-netral-7 px-2">
            <select
              className="w-full text-netral-9 focus:outline-none"
              onChange={(e) => setFilter(e.target.value)}
              value={filter}
            >
              {['semua rating ‎', '5', '4', '3', '2', '1'].map((option, i) => (
                <option key={i}>{option}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div>
        <div className="mb-[10px] border-b-2 border-netral-5 pb-[10px]">
          <DashboardTable tableBodies={tableBodies} tableHeads={tableHeads} />
        </div>
        <TablePagination
          changePage={(page) => navigate(pathname + '?page=' + page)}
          page={Number(queryPage)}
          totalPage={data.totalPage}
        />
      </div>
    </div>
  );
}

function mapDataToLearnerTableData(
  data: Rating[],
  // eslint-disable-next-line no-unused-vars
  handleClickDetail: (data: Rating) => void,
) {
  const tableHeads = [
    [
      { node: 'Nama Siswa', justify: 'start' },
      { node: 'Rating', justify: 'start' },
      { node: 'Tanggal', justify: 'start' },
      { node: 'Review', justify: 'start', noshrink: true, width: '400px' },
      { node: '', justify: 'end' },
    ],
  ];

  const tableBodies = data?.map((el) => [
    {
      node: <ProfileCard imageURL={el.profile_picture} name={el.name} />,
      // node: el.name,
      justify: 'start',
    },
    {
      node: <Stars n={el.rating} size="h-4 w-4" />,
      justify: 'start',
    },
    { node: el.createdAt, justify: 'start' },
    {
      node: <p className="w-[400px] truncate whitespace-nowrap text-sm">{el.review}</p>,
      justify: 'start',
      width: '400px',
      noshrink: true,
    },
    {
      node: (
        <div className="flex items-center space-x-3">
          <PrimaryButton
            onClick={() => handleClickDetail(el)}
            rounded="large"
            size="small"
            type="button"
            width={150}
          >
            Detail
          </PrimaryButton>
        </div>
      ),
      justify: 'end',
    },
  ]);

  return [tableHeads, tableBodies];
}
