export default function CancelIcon({ ...rest }) {
  return (
    <svg fill="none" viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M105.625 4.0625H24.375C13.1567 4.0625 4.0625 13.1567 4.0625 24.375V105.625C4.0625 116.843 13.1567 125.938 24.375 125.938H105.625C116.843 125.938 125.938 116.843 125.938 105.625V24.375C125.938 13.1567 116.843 4.0625 105.625 4.0625Z"
        fill="#FF7D97"
      />
      <path
        d="M32.5001 101.563C31.9655 101.566 31.4355 101.463 30.9405 101.261C30.4455 101.059 29.9953 100.761 29.6157 100.384C29.235 100.007 28.9327 99.5574 28.7265 99.0624C28.5203 98.5673 28.4141 98.0363 28.4141 97.5C28.4141 96.9637 28.5203 96.4327 28.7265 95.9377C28.9327 95.4426 29.235 94.9933 29.6157 94.6156L94.6157 29.6156C95.3807 28.8507 96.4183 28.4209 97.5001 28.4209C98.582 28.4209 99.6195 28.8507 100.384 29.6156C101.149 30.3806 101.579 31.4182 101.579 32.5C101.579 33.5819 101.149 34.6194 100.384 35.3844L35.3845 100.384C35.0049 100.761 34.5547 101.059 34.0597 101.261C33.5648 101.463 33.0348 101.566 32.5001 101.563Z"
        fill="#F0E6E2"
      />
      <path
        d="M97.499 101.563C96.9644 101.566 96.4344 101.463 95.9394 101.261C95.4445 101.059 94.9943 100.761 94.6147 100.384L29.6147 35.3844C28.8497 34.6194 28.4199 33.5819 28.4199 32.5C28.4199 31.9643 28.5254 31.4339 28.7304 30.939C28.9354 30.4441 29.2359 29.9944 29.6147 29.6156C29.9935 29.2369 30.4431 28.9364 30.938 28.7314C31.4329 28.5264 31.9634 28.4209 32.499 28.4209C33.5809 28.4209 34.6184 28.8507 35.3834 29.6156L100.383 94.6156C100.764 94.9933 101.066 95.4426 101.273 95.9377C101.479 96.4327 101.585 96.9637 101.585 97.5C101.585 98.0363 101.479 98.5673 101.273 99.0624C101.066 99.5574 100.764 100.007 100.383 100.384C100.004 100.761 99.5536 101.059 99.0587 101.261C98.5637 101.463 98.0337 101.566 97.499 101.563Z"
        fill="#F0E6E2"
      />
      <path
        d="M113.75 117.813H32.5C27.1128 117.813 21.9462 115.673 18.1369 111.863C14.3276 108.054 12.1875 102.887 12.1875 97.5002V16.2502C12.2108 12.8758 13.0915 9.56263 14.7469 6.62207C11.5367 8.35007 8.85073 10.9102 6.97081 14.0339C5.0909 17.1575 4.08633 20.7295 4.0625 24.3752V105.625C4.0625 111.012 6.20256 116.179 10.0119 119.988C11.8981 121.874 14.1373 123.371 16.6017 124.392C19.0662 125.412 21.7075 125.938 24.375 125.938H105.625C109.271 125.914 112.843 124.909 115.966 123.029C119.09 121.149 121.65 118.464 123.378 115.253C120.438 116.909 117.124 117.789 113.75 117.813Z"
        fill="#FF5D7D"
      />
    </svg>
  );
}
