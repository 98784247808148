import { useState } from 'react';

interface ProfileButtonProps {
  imageURL: string;
  name: string;
  logout: () => void;
  setting: () => void;
  profile: () => void;
  isWhite?: boolean;
}

export default function ProfileButton({
  isWhite,
  imageURL,
  name,
  setting,
  profile,
  logout,
}: ProfileButtonProps) {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  return (
    <div className="relative">
      <button
        className="flex items-center space-x-2 text-left"
        onClick={() => setShowDropdown(!showDropdown)}
        type="button"
      >
        <div>
          <img
            alt={imageURL}
            className="h-12 w-12 overflow-hidden rounded-full bg-[#23C4F4] object-cover"
            src={imageURL}
          />
        </div>
        <div className="space-y-1">
          <p className={`${isWhite ? 'text-white' : 'text-netral-9'} font-semibold`}>{name}</p>
          <p className={`${isWhite ? 'text-white' : 'text-netral-8'} text-xs text-netral-8`}>
            Admin
          </p>
        </div>
      </button>
      {showDropdown && (
        <div
          className="absolute top-14 left-0 w-[170px] space-y-3 bg-white p-3"
          style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.06)' }}
        >
          <button
            className="w-full text-left text-sm text-netral-10"
            onClick={profile}
            type="button"
          >
            Profile
          </button>
          <button
            className="w-full text-left text-sm text-netral-10"
            onClick={setting}
            type="button"
          >
            Setting
          </button>
          <div className="h-[1px] w-full bg-netral-5"></div>
          <button
            className="w-full text-left text-sm text-primary-600"
            onClick={logout}
            type="button"
          >
            Logout
          </button>
        </div>
      )}
    </div>
  );
}
