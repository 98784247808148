export default function SearchIcon({ ...rest }) {
  return (
    <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_864_9368)">
        <path
          d="M15.5 14.0006H14.71L14.43 13.7306C15.63 12.3306 16.25 10.4206 15.91 8.39063C15.44 5.61063 13.12 3.39063 10.32 3.05063C6.09001 2.53063 2.53002 6.09063 3.05002 10.3206C3.39002 13.1206 5.61002 15.4406 8.39002 15.9106C10.42 16.2506 12.33 15.6306 13.73 14.4306L14 14.7106V15.5006L18.25 19.7506C18.66 20.1606 19.33 20.1606 19.74 19.7506C20.15 19.3406 20.15 18.6706 19.74 18.2606L15.5 14.0006ZM9.50002 14.0006C7.01002 14.0006 5.00002 11.9906 5.00002 9.50063C5.00002 7.01063 7.01002 5.00063 9.50002 5.00063C11.99 5.00063 14 7.01063 14 9.50063C14 11.9906 11.99 14.0006 9.50002 14.0006Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_864_9368">
          <rect fill="white" height="24" width="24" />
        </clipPath>
      </defs>
    </svg>
  );
}
