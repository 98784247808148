import { ComponentPropsWithoutRef } from 'react';

interface LabelTextAreaProps extends ComponentPropsWithoutRef<'textarea'> {
  id: string;
  label: string;
}

export default function LabelTextArea({ id, label, ...rest }: LabelTextAreaProps) {
  return (
    <div className="flex w-full flex-col space-y-2">
      <label className="w-full text-sm font-semibold text-netral-8" htmlFor={id}>
        {label}
      </label>
      <div className="relative">
        <textarea
          className="h-[85px] w-full rounded border border-netral-7 p-2 text-netral-9"
          id={id}
          {...rest}
        ></textarea>
      </div>
    </div>
  );
}
