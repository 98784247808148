import { APIBaseURL } from '../../environment';
import { Instructor } from '../../interfaces';

interface GetInstructorsAPI {
  // data(data: any): unknown;
  status: boolean;
  total_page: number;
  data: Instructor[];
}

export async function getInstructorsAPI(
  token: string,
  limit: number,
  page: number,
  search: string,
): Promise<GetInstructorsAPI> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(
    `${APIBaseURL}/api/admin/instructor/all?page_size=${limit}&page_number=${page}&name=${search}`,
    requestOptions,
  );

  const result = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (!result.status) throw new Error(result.message);

  return result;
}
