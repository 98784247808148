interface SmallCardProps {
  text: string;
}

export default function SmallCard({ text }: SmallCardProps) {
  return (
    <div className="flex items-center space-x-3">
      <div>
        <div className="h-[44px] w-[114px] rounded-xl bg-black"></div>
      </div>
      <p className="text-sm font-semibold text-netral-10">{text}</p>
    </div>
  );
}
