interface ProfileCardProps {
  imageURL: string;
  name: string;
  etc?: string;
}

import { formatLink } from '../../constants/getLink';

export default function ProfileCard({ name, etc, imageURL }: ProfileCardProps) {
  const image = formatLink(imageURL);
  return (
    <div className="flex items-center space-x-3">
      <div>
        <div className="h-[44px] w-[44px]">
          <img
            alt={imageURL}
            className="h-full w-full overflow-hidden rounded-xl object-cover"
            src={imageURL !== '' ? image : ''}
          />
        </div>
      </div>
      <div className="w-full space-y-1">
        <p className="text-sm font-semibold text-netral-10">{name}</p>
        <p className="text-sm text-netral-8">{etc}</p>
      </div>
    </div>
  );
}
