import { ComponentPropsWithoutRef, ReactNode } from 'react';

interface SimpleButtonProps extends ComponentPropsWithoutRef<'button'> {
  children: ReactNode;
}

export default function SimpleButton({ children, ...rest }: SimpleButtonProps) {
  return (
    <button className="text-sm font-semibold text-secondary-button-600" {...rest}>
      {children}
    </button>
  );
}
