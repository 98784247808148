import { createSlice } from '@reduxjs/toolkit';
import { ReactNode } from 'react';
import { closeModalAction, toggleModalAction, modalExtraReducer, openModal } from './action';

export interface ModalState {
  showModal: boolean;
  modalComponent?: ReactNode;
}

const initialState: ModalState = {
  showModal: false,
  modalComponent: '',
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    closeModal: closeModalAction,
    toggleModal: toggleModalAction,
  },
  extraReducers: modalExtraReducer,
});

export default modalSlice.reducer;

export const { closeModal, toggleModal } = modalSlice.actions;

export { openModal };
