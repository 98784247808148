import { APIBaseURL } from '../../environment';
import { ClassWithRating } from '../../interfaces';
import { Rating } from '../../interfaces/rating';

interface GetClassWithRating {
  message: string;
  code: number;
  totalData: number;
  totalPage: number;
  status: boolean;
  data: ClassWithRating[];
}

interface GetRatingByClass {
  totalData: number;
  totalPage: number;
  status: boolean;
  result: Rating[];
}

export async function getClassWithRatingAPI(
  token: string,
  query: string,
): Promise<GetClassWithRating> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(`${APIBaseURL}/api/admin/class-rating?${query}`, requestOptions);

  const result = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (!result.status) throw new Error(result.message);

  return result;
}

export async function getRatingByClassIdAPI(
  token: string,
  query: string,
  id: string,
): Promise<GetRatingByClass> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(
    `${APIBaseURL}/api/admin/detail-rating/${id}?${query}`,
    requestOptions,
  );

  const result = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (!result.status) throw new Error(result.message);

  return result;
}
