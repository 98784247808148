export default function ChecklistIcon({ ...rest }) {
  return (
    <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M7.33268 0.333984C3.65268 0.333984 0.666016 3.32065 0.666016 7.00065C0.666016 10.6807 3.65268 13.6673 7.33268 13.6673C11.0127 13.6673 13.9993 10.6807 13.9993 7.00065C13.9993 3.32065 11.0127 0.333984 7.33268 0.333984ZM5.52602 9.86065L3.13268 7.46732C2.87268 7.20732 2.87268 6.78732 3.13268 6.52732C3.39268 6.26732 3.81268 6.26732 4.07268 6.52732L5.99935 8.44732L10.586 3.86065C10.846 3.60065 11.266 3.60065 11.526 3.86065C11.786 4.12065 11.786 4.54065 11.526 4.80065L6.46602 9.86065C6.21268 10.1207 5.78602 10.1207 5.52602 9.86065Z"
        fill="#12A89D"
      />
    </svg>
  );
}
