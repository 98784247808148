import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SecondaryButton } from '../../components/buttons';
import { DashboardHeader } from '../../components/headers';
import { formatLink } from '../../constants/getLink';
import { APIBaseURL } from '../../environment';
import { useTypedSelector } from '../../store';

interface downloadFileRedeem {
  _id: string;
  profile_picture: string;
  name: string;
  email: string;
  myClass: {
    class_id: string;
    voucher_code: string;
    reedem_code: unknown;
    percentage: number;
    courseTitle: string;
    instructor: string;
  }[];
}

const EducatorDetail = () => {
  // const [tableHeads, tableBodies] = promoDetailTable();
  const [dataEducator, setdataEducator] = useState({
    _id: '',
    password: '',
    email: '',
    phoneNumber: '',
    role: ['Instructor'],
    cart: [],
    myclass: [],
    wishlist: [],
    domicile: '',
    name: '',
    email_verify: false,
    phoneNumber_verify: true,
    date_of_birth: '',
    headline: '',
    profile_picture: '',
    npwp_number: '',
    npwp_name: '',
    npwp_address: '',
    npwp_picture: {
      name: '',
      link: '',
      type: '',
      storage: '',
    },
    tax_status: '',
    bank_name: '',
    bank_city: '',
    bank_branch: '',
    bank_account: '',
    account_number: '',
    bio: '',
  });
  const { data } = useTypedSelector((state) => state.user);
  const { token } = useTypedSelector((state) => state.auth);
  const { educator_id } = useParams();

  async function getEducatorData(): Promise<downloadFileRedeem> {
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions: RequestInit = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const response = await fetch(
      `${APIBaseURL}/api/admin/instructor/detail/${educator_id}`,
      requestOptions,
    );
    const result = await response.json();

    setdataEducator(result.data);
    return result;
  }

  // useMemo(() => first, [second])

  useEffect(() => {
    getEducatorData();
  }, []);

  const profilePicture = formatLink(dataEducator?.profile_picture);
  return (
    <div>
      <div
        className="relative mb-[70px] h-[250px] rounded-b-3xl bg-secondary-button-900 bg-cover px-3 pt-4"
        style={{ backgroundImage: 'url("/assets/images/1457718455276 1.png")' }}
      >
        <DashboardHeader
          imageURL={data?.profile_picture.link || ''}
          isWhite
          name={data?.name || ''}
        />
        <div className="absolute left-0 bottom-0 w-full translate-y-1/2 px-3">
          <div
            className="flex w-full items-center justify-between rounded-xl border border-netral-4 bg-netral-1 px-6 py-3"
            style={{
              backdropFilter: 'blur(10.5px)',
              filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.06))',
            }}
          >
            <div className="flex items-center space-x-5">
              <div>
                <img
                  alt={profilePicture}
                  className="h-[70px] w-[70px] rounded-xl bg-[#23C4F4]"
                  src={profilePicture}
                />
              </div>
              <div className="flex justify-between space-x-5">
                <div className="space-y-1">
                  <p className="text-sm text-netral-6">Nama</p>
                  <p className="text-sm text-netral-6">Email</p>
                  <p className="text-sm text-netral-6">Nomor Handphone</p>
                </div>
                <div className="space-y-1">
                  <p className="text-sm text-netral-6">{dataEducator.name}</p>
                  <p className="text-sm text-netral-6">{dataEducator.email}</p>
                  <p className="text-sm text-netral-6">{dataEducator.phoneNumber || ''}</p>
                  <p className="text-sm text-netral-6">-</p>
                </div>
              </div>
            </div>
            <div className="self-start">
              <SecondaryButton color="light-green" rounded="small" size="small">
                Reset Password
              </SecondaryButton>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-[16px]">
        <p className="text-lg font-semibold">Informasi Detail</p>
      </div>
      <div className="mb-[70px] space-y-[32px] rounded-xl bg-white p-[16px]">
        <div className="space-y-[16px]">
          <p className="font-semibold text-netral-10">Biografi</p>
          <p className="whitespace-pre-wrap text-sm text-netral-10">{dataEducator.bio}</p>
        </div>
        <hr />
        <EducatorProfile
          descBody={[
            dataEducator.headline,
            new Date(dataEducator.date_of_birth).toLocaleDateString(),
            dataEducator.domicile,
          ]}
          descHead={['Pekerjaan', 'Tanggal lahir', 'Alamat']}
          title="Biodata Singkat"
        />
        <hr />
        <EducatorProfile
          descBody={[
            dataEducator.npwp_name || '-',
            dataEducator.npwp_number || '-',
            dataEducator.npwp_address || '-',
            dataEducator.npwp_name || '-',
          ]}
          descHead={['Nama tertera di NPWP', 'Nomor NPWP', 'Alamat NPWP', 'Status Pajak']}
          title="NPWP"
        />
        <hr />
        <EducatorProfile
          descBody={[
            dataEducator.bank_name || '-',
            dataEducator.bank_branch || '-',
            dataEducator.bank_city || '-',
            dataEducator.bank_account || '-',
            dataEducator.account_number || '-',
          ]}
          descHead={['Nama Bank', 'Cabang Bank', 'Kota Bank', 'Nama Rekening', 'Nomor Rekening']}
          title="Bank"
        />
      </div>
    </div>
  );
};

export default EducatorDetail;

interface educatorProfile {
  title: string;
  descHead: string[];
  descBody: string[];
}

const EducatorProfile = ({ title, descHead, descBody }: educatorProfile) => {
  return (
    <div className="space-y-[16px]">
      <p className="font-semibold text-netral-10">{title}</p>
      <div className="flex">
        <div className="w-[300px] space-y-[16px]">
          {descHead.map((el, idx) => (
            <p key={idx} className="text-sm text-netral-8">
              {el}
            </p>
          ))}
        </div>
        <div className="max-w-[700px] space-y-[16px]">
          {descBody.map((el, idx) => (
            <p key={idx} className="text-sm">
              {el}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};
