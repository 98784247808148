import { createSlice } from '@reduxjs/toolkit';
import { Voucher } from '../../interfaces';
import {
  vouchersExtraReducer,
  createVouchersAction,
  resetVouchersByClassDataAction,
  getVouchersByClassAction,
} from './action';

export interface VouchersState {
  dataVoucherByClass: {
    class: [{ id: string; courseTitle: string; price: number; instructor: string }];
    voucher: Voucher[];
  } | null;
  loadingCreateVouchers: boolean;
}

const initialState: VouchersState = {
  dataVoucherByClass: null,
  loadingCreateVouchers: false,
};

export const vouchersSlice = createSlice({
  name: 'vouchers',
  initialState,
  reducers: {
    resetVouchersByClassData: resetVouchersByClassDataAction,
  },
  extraReducers: vouchersExtraReducer,
});

export default vouchersSlice.reducer;

export const { resetVouchersByClassData } = vouchersSlice.actions;

export { createVouchersAction as createVouchers, getVouchersByClassAction as getVouchersByClass };
