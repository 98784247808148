import { useState } from 'react';
import { CloseButton, PrimaryButton } from '../buttons';
import { LabelInput } from '../inputs';

interface SimpleInputModalProps {
  title: string;
  close: () => void;
  handleSubmit?: (e: string) => void;
  buttonText?: string;
  type?: 'text' | 'file';
  label?: string;
}

export default function SimpleInputModal({
  title,
  type,
  buttonText,
  close,
  label = 'Kode Kelas',
  handleSubmit,
}: SimpleInputModalProps) {
  const [value, setValue] = useState('');
  return (
    <form
      className="w-[438px] space-y-6 rounded-2xl border border-netral-5 bg-white p-6"
      onSubmit={(e) => e.preventDefault()}
    >
      <div className="flex items-center justify-between border-b border-b-netral-4 pb-3">
        <p className="font-semibold text-netral-8">{title}</p>
        <CloseButton onClick={close} type="button" />
      </div>
      {type === 'text' && (
        <LabelInput
          id="code-class-modal-detail-course"
          label={label}
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
      )}
      {/* {type === 'text' && <LabelInput id="code-class-modal-detail-course" label={label} />} */}
      {type === 'file' && <input type="file" />}
      {handleSubmit ? (
        <div className="flex justify-end">
          <PrimaryButton onClick={() => handleSubmit(value)} type="submit">
            {buttonText}
          </PrimaryButton>
        </div>
      ) : (
        <div className="flex justify-end">
          <PrimaryButton type="submit">{buttonText}</PrimaryButton>
        </div>
      )}
    </form>
  );
}

SimpleInputModal.defaultProps = {
  buttonText: 'Simpan',
  type: 'text',
};
