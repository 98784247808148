import { ComponentPropsWithoutRef } from 'react';
import { Link } from 'react-router-dom';

type HomepageButtonProps = ComponentPropsWithoutRef<'a'>;

export default function HomepageButton({ ...rest }: HomepageButtonProps) {
  return (
    <Link
      className="flex w-full items-center space-x-2 border-b border-b-netral-5 pb-2 text-left"
      to="/"
      {...rest}
    >
      <img className="h-[34px] w-[28px]" src="/assets/logos/skills.svg" />
      <div>
        <h5 className="text-lg text-[#26255B]">skills.id</h5>
        <p className="text-xs tracking-[0.2em] text-netral-9">Admin</p>
      </div>
    </Link>
  );
}
