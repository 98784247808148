import { APIBaseURL } from '../../environment';
import { Transaction, TransactionDetail } from '../../interfaces';

interface GetTransactionsAPI {
  message: string;
  status: boolean;
  total_page: number;
  data: Transaction[];
}

export async function getTransactionsAPI(
  token: string,
  query: string,
): Promise<GetTransactionsAPI> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(`${APIBaseURL}/api/admin/transaction/all${query}`, requestOptions);

  const result: GetTransactionsAPI = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (!result.status) throw new Error(result.message);

  return result;
}

export async function getTransactionAPI(
  token: string,
  invoiceNumber: string,
): Promise<TransactionDetail> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(
    `${APIBaseURL}/api/admin/detailTransaction/${invoiceNumber}`,
    requestOptions,
  );

  const result = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  return result;
}

export async function verifyTransactionAPI(token: string | null, invoice: string) {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  const urlencoded = new URLSearchParams();
  urlencoded.append('invoice', invoice);

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
    body: urlencoded,
  };

  const response = await fetch(`${APIBaseURL}/api/sale/verifyPayment`, requestOptions);

  const result = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (!result.status) throw new Error(result.message);

  return result;
}
