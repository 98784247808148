export default function EditIcon({ ...rest }) {
  return (
    <svg fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_165_16558)">
        <path
          d="M4 23.2794V27.3328C4 27.7061 4.29333 27.9994 4.66667 27.9994H8.72C8.89333 27.9994 9.06667 27.9328 9.18667 27.7994L23.7467 13.2528L18.7467 8.25276L4.2 22.7994C4.06667 22.9328 4 23.0928 4 23.2794ZM27.6133 9.38609C28.1333 8.86609 28.1333 8.02609 27.6133 7.50609L24.4933 4.38609C23.9733 3.86609 23.1333 3.86609 22.6133 4.38609L20.1733 6.82609L25.1733 11.8261L27.6133 9.38609Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_165_16558">
          <rect fill="white" height="32" width="32" />
        </clipPath>
      </defs>
    </svg>
  );
}
