export default function MultipleChoiceQuizIcon({ ...rest }) {
  return (
    <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_855_1387)">
        <path
          clipRule="evenodd"
          d="M12.286 13.9507L11.42 14.4507C11.2575 14.5442 11.0565 14.5392 10.899 14.4377C10.742 14.3367 10.654 14.1557 10.672 13.9692L11.0875 9.68823C11.103 9.52723 11.195 9.38423 11.335 9.30323C11.475 9.22273 11.645 9.21423 11.792 9.28123L15.707 11.0622C15.8775 11.1397 15.9905 11.3062 15.9995 11.4932C16.0085 11.6802 15.912 11.8567 15.75 11.9507L14.884 12.4507L15.634 13.7497C16.048 14.4672 15.8025 15.3842 15.085 15.7987C14.3675 16.2127 13.45 15.9672 13.036 15.2497L12.286 13.9507Z"
          fill="currentColor"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M9.23672 11.6707L10.1027 11.1707C10.3418 11.0327 10.4238 10.7267 10.2858 10.4877C10.1478 10.2487 9.84177 10.1667 9.60275 10.3047L8.73672 10.8047C8.4977 10.9427 8.41571 11.2487 8.55371 11.4877C8.69171 11.7267 8.9977 11.8087 9.23672 11.6707Z"
          fill="currentColor"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M13.567 9.17071L14.4331 8.67071C14.6721 8.53271 14.7541 8.22672 14.6161 7.9877C14.4781 7.74868 14.1721 7.66669 13.9331 7.80469L13.067 8.30469C12.828 8.44269 12.746 8.74868 12.884 8.9877C13.022 9.22672 13.328 9.30871 13.567 9.17071Z"
          fill="currentColor"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M11.0179 7.75449L10.5179 6.88846C10.3799 6.64944 10.0739 6.56745 9.83487 6.70545C9.59584 6.84345 9.51386 7.14944 9.65186 7.38846L10.1519 8.25449C10.2899 8.49351 10.5958 8.5755 10.8349 8.4375C11.0739 8.2995 11.1559 7.99351 11.0179 7.75449Z"
          fill="currentColor"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M1 4.50001V1.5C1 1.224 1.224 1 1.5 1V0.500001V1C1.776 1 2 1.224 2 1.5C2 2.68458 2 4.50001 2 4.50001C2 4.77601 2.224 5.00001 2.5 5.00001C2.776 5.00001 3 4.77601 3 4.50001C3 4.50001 3 2.68458 3 1.5C3 0.67143 2.3285 0 1.5 0V0.500001C1.5 0 1.5 0 1.5 0C0.6715 0 0 0.67143 0 1.5C0 2.68458 0 4.50001 0 4.50001C0 4.77601 0.224 5.00001 0.5 5.00001C0.776 5.00001 1 4.77601 1 4.50001Z"
          fill="currentColor"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M1 10V6.5C1 6.224 0.776 6 0.5 6C0.224 6 0 6.224 0 6.5V10C0 10.276 0.224 10.5 0.5 10.5C0.776 10.5 1 10.276 1 10Z"
          fill="currentColor"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M0.5 3.25H2.25C2.526 3.25 2.75 3.026 2.75 2.75C2.75 2.474 2.526 2.25 2.25 2.25H0.5C0.224 2.25 0 2.474 0 2.75C0 3.026 0.224 3.25 0.5 3.25Z"
          fill="currentColor"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M2.5 7.5H3C3 8.3285 2.3285 9 1.5 9H0.5C0.224 9 0 8.776 0 8.5V6.5C0 6.224 0.224 6 0.5 6H1.5C2.3285 6 3 6.6715 3 7.5H2.5H3H2.5ZM2 7.5C2 7.224 1.776 7 1.5 7H1V8H1.5C1.776 8 2 7.776 2 7.5H2.5H2Z"
          fill="currentColor"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M2.5 9.5H3C3 10.3285 2.3285 11 1.5 11H0.5C0.224 11 0 10.776 0 10.5V8.5C0 8.224 0.224 8 0.5 8H1.5C2.3285 8 3 8.6715 3 9.5H2.5H3H2.5ZM2 9.5C2 9.224 1.776 9 1.5 9H1V10H1.5C1.776 10 2 9.776 2 9.5H2.5H2Z"
          fill="currentColor"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M8.49953 8.5C8.49953 7.6716 7.82801 7 6.99947 7C6.5292 7 5.96984 7 5.49957 7C4.67103 7 3.99951 7.6716 3.99951 8.5H4.49953H3.99951C3.99951 9.3284 4.67103 10 5.49957 10C5.96984 10 6.5292 10 6.99947 10C7.82801 10 8.49953 9.3284 8.49953 8.5H7.99951H8.49953Z"
          fill="currentColor"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M15.5 2.5H16C16 2.102 15.842 1.7205 15.5605 1.4395C15.2795 1.158 14.898 1 14.5 1C12.408 1 7.592 1 5.5 1C5.102 1 4.7205 1.158 4.4395 1.4395C4.158 1.7205 4 2.102 4 2.5H4.5H4C4 2.898 4.158 3.2795 4.4395 3.5605C4.7205 3.842 5.102 4 5.5 4C7.592 4 12.408 4 14.5 4C14.898 4 15.2795 3.842 15.5605 3.5605C15.842 3.2795 16 2.898 16 2.5H15.5Z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_855_1387">
          <rect fill="white" height="16" width="16" />
        </clipPath>
      </defs>
    </svg>
  );
}
