import { createSlice } from '@reduxjs/toolkit';
import { logoutAction, authExtraReducer, loginAction, checkTokenAction } from './action';

export interface AuthState {
  loading: boolean;
  isAuthenticated: boolean;
  token: string;
}

const initialState: AuthState = {
  loading: false,
  isAuthenticated: false,
  token: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: logoutAction,
    checkToken: checkTokenAction,
  },
  extraReducers: authExtraReducer,
});

export default authSlice.reducer;

export const { logout, checkToken } = authSlice.actions;

export { loginAction as login };
