import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../components/buttons';
import { EditIcon, SearchIcon } from '../../components/icons';
import { TablePagination } from '../../components/paginations';
import { formatLink } from '../../constants/getLink';
import { useQuery } from '../../hooks';
import { Blog } from '../../interfaces';
import { getAllBlogAPI } from '../../services/api/blog';
import { useTypedDispatch, useTypedSelector } from '../../store';

interface AllBlogData {
  status: boolean;
  totalData: number;
  totalPage: number;
  result: Blog[];
}

const BlogPage = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const query = useQuery();
  const [search, setSearch] = useState<string>('');
  const queryPage = query.get('page_number') || '1';
  const { token } = useTypedSelector((state) => state.auth);
  const [data, setData] = useState<AllBlogData>({
    status: false,
    totalData: 1,
    totalPage: 1,
    result: [],
  });

  function filterData({ page = queryPage }) {
    navigate(`${pathname}?page_size=10&page_number=${page}`);
  }

  const handleGetAllBlogData = async (query: string) => {
    const res = await getAllBlogAPI(token, query);
    if (res.status) {
      setData(res);
    }
  };

  useEffect(() => {
    handleGetAllBlogData(`page_number=${queryPage}&page_size=6&search=${search}`);
  }, [queryPage, search]);

  return (
    <div className="space-y-6">
      <div className="space-y-[12px] rounded-xl bg-white p-4">
        <div className="flex items-center justify-between">
          <h5 className="text-lg font-semibold text-netral-10">List Blog</h5>
          <div className="flex">
            <form className="relative mx-[20px]" onSubmit={(e) => e.preventDefault()}>
              <input
                className="rounded border border-netral-1-stroke bg-white p-1 pl-[42px]"
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Cari"
                type="search"
                value={search}
                // {...rest}
              />
              <div className="absolute top-0 left-2 flex h-full items-center">
                <SearchIcon className="h-6 w-6 text-[#323232]" />
              </div>
            </form>
            {/* <DownloadButton>Download Excel</DownloadButton> */}
            <PrimaryButton color="dark-green" onClick={() => navigate('/blog/create')} size="small">
              Buat Blog
            </PrimaryButton>
          </div>
        </div>
        <div className="grid h-[500px] w-full grid-cols-3 gap-[16px] overflow-scroll border-y border-netral-5 p-[16px]">
          {data.result.map((el, idx) => (
            <div key={idx} className="relative w-full space-y-[8px]">
              <div
                className="absolute top-6 right-2 z-10 flex cursor-pointer items-center justify-between gap-[4px] rounded-[8px] bg-netral-10 py-[6px] px-[8px] opacity-50"
                onClick={() => navigate(`/blog/${el._id}`)}
              >
                <EditIcon className="h-[12px] w-[12px] text-netral-1" />
                <p className="text-netral-1 ">Atur</p>
              </div>
              <div className="w-full overflow-hidden">
                <img
                  alt="banner"
                  className="h-[180px] w-full transition duration-500 ease-in-out hover:scale-125"
                  src={formatLink(el.image)}
                />
              </div>
              <div className="space-y-[8px]">
                <p className="text-sm text-netral-6">
                  {el.author} • {el.createdAt}
                </p>
                <p className="text-base font-semibold text-netral-10">{el.title}</p>
                <p className="text-xs text-netral-7">
                  {el.content.split(' ').slice(0, 18).join(' ')}...
                </p>
              </div>
            </div>
          ))}
        </div>
        <TablePagination
          changePage={(page) => filterData({ page: String(page) })}
          page={Number(queryPage)}
          totalPage={data.totalPage}
        />
      </div>
    </div>
  );
};

export default BlogPage;
