import { createSlice } from '@reduxjs/toolkit';
import { Instructor } from '../../interfaces';
import { getInstructorsAction, instructorsExtraReducer, resetInstructosAction } from './action';

export interface InstructorsState {
  data: Instructor[];
  totalPage: number;
  loadingData: boolean;
}

const initialState: InstructorsState = {
  data: [],
  totalPage: 0,
  loadingData: false,
};

export const instructorsSlice = createSlice({
  name: 'instructors',
  initialState,
  reducers: {
    resetInstructor: resetInstructosAction,
  },
  extraReducers: instructorsExtraReducer,
});

export default instructorsSlice.reducer;

export const { resetInstructor } = instructorsSlice.actions;

export { getInstructorsAction as getInstructors };
