import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { TransactionState } from './slice';
import { AlertModal } from '../../components/modals';
import { TransactionDetail } from '../../interfaces';
import { closeModal, openModal } from '../modal/slice';
import { AppDispatch, RootState } from '../../store';
import { getTransactionAPI } from '../../services/api/transaction';

export function resetTransactionAction(state: TransactionState) {
  state.loading = false;
  state.data = null;
}

export const getTransactionAction = createAsyncThunk<
  TransactionDetail,
  { invoiceNumber: string },
  { state: RootState; dispatch: AppDispatch }
>('transaction/getTransaction', async ({ invoiceNumber }, { getState, dispatch }) => {
  try {
    const { token } = getState().auth;

    if (!token) throw null;

    const result = await getTransactionAPI(token, invoiceNumber);

    return result;
  } catch (error) {
    const message = (error as Error).message;

    const close = () => dispatch(closeModal());
    console.log(error);

    dispatch(openModal(AlertModal({ close, message })));

    throw error;
  }
});

export function transactionExtraReducer(builder: ActionReducerMapBuilder<TransactionState>) {
  builder
    .addCase(getTransactionAction.pending, (state) => {
      state.loading = true;
    })
    .addCase(getTransactionAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    })
    .addCase(getTransactionAction.rejected, (state) => {
      state.loading = false;
    });
}
