import { ComponentPropsWithoutRef, ReactNode } from 'react';
import { EditIcon } from '../icons';

interface EditButtonProps extends ComponentPropsWithoutRef<'button'> {
  children: ReactNode;
}

export default function EditButton({ children, ...rest }: EditButtonProps) {
  return (
    <button
      className="flex items-center space-x-2 rounded-lg bg-white py-2 px-4 font-semibold text-secondary-button-600"
      style={{ boxShadow: '1px 2px 8px rgba(0, 0, 0, 0.12)' }}
      {...rest}
    >
      <div>
        <EditIcon className="h-6 w-6" />
      </div>
      <p>{children}</p>
    </button>
  );
}
