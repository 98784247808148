import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTypedSelector } from '../store';
import { useQuery } from '../hooks';

interface AuthenticationRouteProps {
  children: JSX.Element;
}

export default function AuthenticationRoute({ children }: AuthenticationRouteProps) {
  const location = useLocation();
  const navigate = useNavigate();

  const query = useQuery();
  const visit = query.get('continue');

  const { isAuthenticated } = useTypedSelector((state) => state.auth);

  useEffect(() => {
    if (!isAuthenticated) return;
    if (isAuthenticated && visit) return navigate(visit, { replace: true, state: location });
    if (isAuthenticated) return navigate('/', { replace: true, state: location });
  }, [isAuthenticated, location]);

  return children;
}
