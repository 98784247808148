import { ChangeEvent, useState } from 'react';
import { CloseButton, PrimaryButton } from '../buttons';
import { LabelInput, LabelSelectionInput } from '../inputs';

interface GenerateCodeModalProps {
  close: () => void;
  // eslint-disable-next-line no-unused-vars
  generateVoucher?: (total: string, vendor: string, discount: string) => void;
}

export default function GenerateCodeModal({ close, generateVoucher }: GenerateCodeModalProps) {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({ total: '1', vendor: 'Tokopedia', discount: '100' });

  const onFormChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
    setForm({ ...form, [e.target.name]: e.target.value });

  async function onSubmit() {
    setLoading(true);

    if (!generateVoucher) return alert('unhandled func');

    generateVoucher(form.total, form.vendor, form.discount);
  }

  return (
    <form
      className="w-[438px] rounded-2xl border border-netral-5 bg-white p-6"
      onSubmit={(e) => e.preventDefault()}
    >
      <div className="flex items-center justify-between border-b border-b-netral-4 pb-3">
        <h6 className="font-semibold text-netral-8">Generate Code</h6>
        <CloseButton onClick={close} type="button" />
      </div>
      <div className="mt-[13px] space-y-4">
        <div className="flex items-center space-x-6">
          <LabelInput
            id="voucher-entity"
            label="Jumlah Voucher"
            name="total"
            onChange={onFormChange}
            type="number"
            value={form.total}
          />
          <LabelSelectionInput
            id="voucher-vendor"
            label="Vendor"
            name="vendor"
            onChange={onFormChange}
            options={['Tokopedia', 'Pintar', 'Bukalapak', 'Test']}
            value={form.vendor}
          />
        </div>
        <div className="flex items-center space-x-6">
          <LabelSelectionInput
            id="voucher-discount-type"
            label="Tipe  Potongan"
            options={['Persen']}
          />
          <LabelInput
            id="discount"
            label="Potongan"
            name="discount"
            onChange={onFormChange}
            type="number"
            value={form.discount}
          />
        </div>
        {/* <div className="flex items-center space-x-6">
          <LabelSelectionInput id="voucher-vendor" label="Vendor" options={['Tokopedia']} />
          <div className="w-full"></div>
        </div> */}
        {/* <LabelInput id="etc" label="Keterangan" /> */}
      </div>
      <div className="mt-6 flex justify-center">
        <PrimaryButton color="dark-green" loading={loading} onClick={onSubmit} type="submit">
          Generate
        </PrimaryButton>
      </div>
    </form>
  );
}
