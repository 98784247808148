import { APIBaseURL } from '../../environment';
import axios from 'axios';

interface GetNotifClass {
  status: boolean;
  totalData: number;
  data: {
    courseTitle: string;
    learners: {
      name: string;
      phoneNumber: string;
    }[];
    startDate: string;
    startHour: string;
  }[];
}

interface GetAdminRegisterTemplate {
  status: boolean;
  result: {
    templateId: string;
    id: string;
    body: string;
  }[];
}

interface GetNotifLearner {
  status: boolean;
  totalPage: number;
  allPhoneNumber: {
    name: string;
    phoneNumber: string;
  }[];
  data: {
    _id: string;
    user_id: string;
    class_id: string;
    user: {
      name: string;
      phoneNumber: string;
    };
    class: {
      courseTitle: string;
    };
    voucher: {
      voucher_code: string;
      redeemCode: string;
      isReported: boolean;
    };
  }[];
}

interface GetAllRedeemClass {
  status: boolean;
  totalPage: number;
  data: {
    _id: string;
    courseTitle: string;
    instructor: string;
  }[];
}

interface SendNotif {
  code: number;
  success: boolean;
  message: string;
}

interface PromoType {
  data: {
    totalPage: number;
    status: boolean;
    allPhoneNumber: {
      name: string;
      phoneNumber: string;
    }[];
    data: {
      _id: string;
      phoneNumber: string;
      name: string;
    }[];
  };
}

export async function getNotifClassAPI(
  token: string,
  // limit: number,
  // page: number,
  // name:string
): Promise<GetNotifClass> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(`${APIBaseURL}/api/admin/notification-class`, requestOptions);

  const result = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (!result.status) throw new Error(result.message);

  return result;
}

export async function getRegisterNotifTemplate(
  token: string,
  // limit: number,
  // page: number,
  // name:string
): Promise<GetAdminRegisterTemplate> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(`${APIBaseURL}/api/admin/register-templates`, requestOptions);

  const result = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (!result.status) throw new Error(result.message);

  return result;
}

export async function getRedeemNotifTemplate(
  token: string,
  // limit: number,
  // page: number,
  // name:string
): Promise<GetAdminRegisterTemplate> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(`${APIBaseURL}/api/admin/redeem-templates`, requestOptions);

  const result = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (!result.status) throw new Error(result.message);

  return result;
}

export async function getAllClass(
  token: string,
  limit: number,
  page: number,
  name: string,
): Promise<GetAllRedeemClass> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(
    `${APIBaseURL}/api/admin/published-class?page_size=${limit}&page_number=${page}&courseTitle=${name}`,
    requestOptions,
  );

  const result = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (!result.status) throw new Error(result.message);

  return result;
}

export async function getNotifLearnerAPI(
  token: string,
  limit: number,
  page: number,
  name: string,
): Promise<GetNotifLearner> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(
    `${APIBaseURL}/api/admin/notification-validation?page_size=${limit}&page_number=${page}&name=${name}`,
    requestOptions,
  );

  const result = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (!result.status) throw new Error(result.message);

  return result;
}

export async function getNotifPromoAPI(
  token: string,
  limit: number,
  page: number,
  name: string,
): Promise<PromoType> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(
    `${APIBaseURL}//api/admin/notification-promo?page_size=${limit}&page_number=${page}&name=${name}`,
    requestOptions,
  );

  const result = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (!result.data.status) throw new Error(result.message);

  return result;
}

export async function sendNotifAPI(
  token: string,
  templateId: string,
  data: {
    name: string;
    phoneNumber: string;
  }[],
): Promise<SendNotif> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

  const response = await axios({
    method: 'post',
    url: `${APIBaseURL}/api/notification/whatsApp`,
    data: {
      templateId,
      data,
    },
  });

  const result = await response.data;

  if (!result.success) throw new Error(result.message);

  return result;
}

export async function sendRegisterReminder(token: string, file: File): Promise<any> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

  const urlencoded = new FormData();
  urlencoded.append('file', file);

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow',
  };

  const response = await fetch(`${APIBaseURL}/api/admin/notification-register`, requestOptions);
  const result = await response.json();

  if (!result.success) throw new Error(result.message);

  return result;
}
