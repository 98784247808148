export default function DashboardIcon({ ...rest }) {
  return (
    <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_855_1411)">
        <path
          d="M2.66667 8.66667H6.66667C7.03333 8.66667 7.33333 8.36667 7.33333 8V2.66667C7.33333 2.3 7.03333 2 6.66667 2H2.66667C2.3 2 2 2.3 2 2.66667V8C2 8.36667 2.3 8.66667 2.66667 8.66667ZM2.66667 14H6.66667C7.03333 14 7.33333 13.7 7.33333 13.3333V10.6667C7.33333 10.3 7.03333 10 6.66667 10H2.66667C2.3 10 2 10.3 2 10.6667V13.3333C2 13.7 2.3 14 2.66667 14ZM9.33333 14H13.3333C13.7 14 14 13.7 14 13.3333V8C14 7.63333 13.7 7.33333 13.3333 7.33333H9.33333C8.96667 7.33333 8.66667 7.63333 8.66667 8V13.3333C8.66667 13.7 8.96667 14 9.33333 14ZM8.66667 2.66667V5.33333C8.66667 5.7 8.96667 6 9.33333 6H13.3333C13.7 6 14 5.7 14 5.33333V2.66667C14 2.3 13.7 2 13.3333 2H9.33333C8.96667 2 8.66667 2.3 8.66667 2.66667Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_855_1411">
          <rect fill="white" height="16" width="16" />
        </clipPath>
      </defs>
    </svg>
  );
}
