import { ComponentPropsWithoutRef } from 'react';
import { CloseIcon } from '../icons';

type CloseButtonProps = ComponentPropsWithoutRef<'button'>;

export default function CloseButton({ ...rest }: CloseButtonProps) {
  return (
    <button {...rest}>
      <CloseIcon className="h-7 w-7 text-[#323232]" />
    </button>
  );
}
