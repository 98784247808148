import { CloseButton } from '../buttons';

export default function PaymentDetailModal() {
  return (
    <div className="relative w-full px-4 lg:w-auto lg:p-0">
      <div className="absolute top-4 right-6">
        <CloseButton />
      </div>
      <div className="w-[850px] space-y-6 rounded-lg bg-white p-6">
        <h4 className="text-center text-xl font-semibold text-netral-10">Detail Pembayaran</h4>
        <div className="space-y-3 border-b-2 border-[#E0E0E0] pb-3">
          <div className="flex items-center justify-between">
            <div className="space-y-2">
              <p className="text-sm font-semibold text-netral-8">Tanggal</p>
              <p className="text-netral-10">17 Februari 2022</p>
            </div>
            <div className="space-y-2">
              <p className="text-sm font-semibold text-netral-8">Status</p>
              <h6 className="text-lg font-semibold text-primary2-500">Waiting for Payment</h6>
            </div>
          </div>
          <div className="space-y-2">
            <p className="text-sm font-semibold text-netral-8">Invoice</p>
            <p className="text-netral-10">SKILLS/20220225/XXII/II/16019-1645769647 </p>
          </div>
          <div className="space-y-2">
            <p className="text-sm font-semibold text-netral-8">Nama</p>
            <p className="text-netral-10">Emil Fitranshah Aliff Sipahutar</p>
          </div>
          <div className="space-y-2">
            <p className="text-sm font-semibold text-netral-8">Email</p>
            <p className="text-netral-10">emilfitranshah@gmail.com</p>
          </div>
        </div>
        <div className="space-y-2">
          <p className="text-sm font-semibold text-netral-8">Kelas yang dibeli</p>
          <div className="space-y-2">
            {data.class?.map((item, i) => (
              <div key={i} className="space-y-1">
                <div className="flex items-center justify-between">
                  <p className="text-netral-10">{item.title}</p>
                  <p className="text-netral-10">
                    Rp. {Number(item.price || 0).toLocaleString('id-ID')}
                  </p>
                </div>
                <p className="text-sm text-netral-6">Oleh {item.instructor}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <p className="text-sm font-semibold text-netral-8">Total Tagihan</p>
            <p className="text-netral-10">Rp. 520.000</p>
          </div>
          <div className="flex items-center justify-between">
            <p className="text-netral-10">Discount</p>
            <p className="text-netral-10">0%</p>
          </div>
          <div className="flex items-center justify-between border-t-2 border-[#E0E0E0] pt-3">
            <p className="font-semibold text-secondary3-600">Total Bayar</p>
            <p className="font-semibold text-secondary3-600">Rp. 520.000</p>
          </div>
        </div>
        <div className="space-y-2">
          <p className="text-sm font-semibold text-netral-8">Metode Pembayaran</p>
          <div className="flex items-center justify-between">
            <p className="text-netral-10">Permata Virtual Account</p>
            <p className="text-netral-10">Rp. 520.000</p>
          </div>
        </div>
      </div>
    </div>
  );
}

const data = {
  class: [
    {
      title: 'Menjadi UI/UX Designer Professional ',
      instructor: 'Oleh Sandhika Galih',
      price: 520000,
    },
  ],
};
