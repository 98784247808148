import { useEffect } from 'react';
import { loading } from '../../constants';
import { ChecklistIcon } from '../icons';

interface modalProps {
  close: () => void;
}

function SuccessModal({ close }: modalProps) {
  const handleClose = async () => {
    await loading(1500);
    close();
  };
  useEffect(() => {
    handleClose();
  }, []);

  return (
    <div
      className="relative flex w-[438px] items-center justify-between rounded-2xl bg-white p-[24px]"
      style={{ filter: 'drop-shadow(1px 4px 16px rgba(0, 0, 0, 0.1))' }}
    >
      <p className="font-semibold text-netral-10">Pesan Terkirim</p>
      <ChecklistIcon className="h-8 w-8 text-secondary3-500" />
    </div>
  );
}

export default SuccessModal;
