import { createSlice } from '@reduxjs/toolkit';
import { Certificate } from '../../interfaces';
import { certificatesExtraReducer, resetCertificatesAction, getCertificatesAction } from './action';

export interface CertificatesState {
  data: Certificate[];
  totalPage: number;
  loadingData: boolean;
}

const initialState: CertificatesState = {
  data: [],
  totalPage: 0,
  loadingData: false,
};

export const certificatesSlice = createSlice({
  name: 'certificates',
  initialState,
  reducers: {
    resetCertificates: resetCertificatesAction,
  },
  extraReducers: certificatesExtraReducer,
});

export default certificatesSlice.reducer;

export const { resetCertificates } = certificatesSlice.actions;

export { getCertificatesAction as getCertificates };
