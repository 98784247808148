import { ComponentPropsWithoutRef, ReactNode } from 'react';

interface AuthButtonProps extends ComponentPropsWithoutRef<'button'> {
  children: ReactNode;
  isDisable?: boolean;
}

export default function AuthButton({ isDisable, children, ...rest }: AuthButtonProps) {
  return (
    <button
      className={`${
        isDisable ? 'cursor-not-allowed bg-netral-5 text-netral-6' : 'bg-[#23C4F4] text-white'
      } w-full rounded-xl py-3 font-semibold`}
      disabled={isDisable}
      {...rest}
    >
      {!isDisable ? children : 'Loading...'}
    </button>
  );
}
