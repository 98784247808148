import { ChangeEvent, useState } from 'react';
import { CloseButton, PrimaryButton } from '../buttons';
import { LabelInput, LabelSelectionInput } from '../inputs';

interface AddAdminModalProps {
  close: () => void;
  // eslint-disable-next-line no-unused-vars
  addAdmin: (name: string, email: string, password: string) => void;
  loading: boolean;
  data: { name: string; email: string; password: string };
}

export default function AddAdminModal({ data, loading, close, addAdmin }: AddAdminModalProps) {
  const [form, setForm] = useState({
    name: data.name,
    email: data.email,
    password: data.password,
    status: 'Aktif',
  });

  const onFormChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
    setForm({ ...form, [e.target.name]: e.target.value });

  return (
    <form
      className="w-[438px] rounded-2xl border border-netral-5 bg-white p-6"
      onSubmit={(e) => e.preventDefault()}
    >
      <div className="flex items-center justify-between border-b border-b-netral-4 pb-3">
        <h6 className="font-semibold text-netral-8">Tambah Admin</h6>
        <CloseButton onClick={close} type="button" />
      </div>
      <div className="mt-[13px] space-y-4">
        <LabelInput
          id="setting-name"
          label="Nama Admin"
          name="name"
          onChange={onFormChange}
          value={form.name}
        />
        <div className="flex items-center space-x-6">
          <LabelInput
            id="setting-username"
            label="Username"
            name="email"
            onChange={onFormChange}
            value={form.email}
          />
          <LabelInput
            id="setting-password"
            label="Password"
            name="password"
            onChange={onFormChange}
            type="password"
            value={form.password}
            withEye
          />
        </div>
        <div className="flex items-center space-x-6">
          <LabelSelectionInput
            id="setting-status"
            label="Status"
            name="status"
            onChange={onFormChange}
            options={['Aktif', 'Tidak Aktif']}
            value={form.status}
          />
          <div className="w-full"></div>
        </div>
      </div>
      <div className="mt-6 flex justify-center">
        <PrimaryButton
          color="dark-green"
          loading={loading}
          onClick={() => addAdmin(form.name, form.email, form.password)}
          type="submit"
        >
          Simpan
        </PrimaryButton>
      </div>
    </form>
  );
}

AddAdminModal.defaultProps = {
  data: {
    name: '',
    email: '',
    password: '',
  },
};
