import { ComponentPropsWithoutRef, useState } from 'react';
import { RemoveRedEyeIcon } from '../icons';

type TypeInput = 'password' | 'text' | 'email' | 'date' | 'number';

interface LabelInputProps extends ComponentPropsWithoutRef<'input'> {
  id: string;
  label: string;
  withEye?: boolean;
  type?: TypeInput;
}

export default function LabelInput({ id, type, withEye, label, ...rest }: LabelInputProps) {
  const [isPassword, setPassword] = useState<boolean>(type === 'password');
  const [typeState, setTypeState] = useState<TypeInput>(type || 'text');

  function toggleEye(): void {
    if (isPassword) {
      setPassword(false);
      setTypeState('text');
    } else {
      setPassword(true);
      setTypeState('password');
    }
  }

  return (
    <div className="flex w-full flex-col space-y-2">
      <label className="w-full text-sm font-semibold text-netral-8" htmlFor={id}>
        {label}
      </label>
      <div className="relative">
        <input
          className="w-full rounded border border-netral-7 p-2 text-netral-9"
          id={id}
          type={typeState}
          {...rest}
        />
        {withEye && (
          <div className="absolute top-0 right-3 flex h-full items-center">
            <button className="relative" onClick={toggleEye} type="button">
              {isPassword && (
                <div className="absolute top-0 left-0 flex h-full w-full items-center">
                  <div className="h-[2px] w-full rotate-[-30.26deg] scale-125 rounded-full bg-primary-500"></div>
                </div>
              )}
              <RemoveRedEyeIcon className="h-5 w-5 text-primary-500" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

LabelInput.defaultProps = {
  type: 'text',
  withEye: false,
};
