import { createSlice } from '@reduxjs/toolkit';
import { Transaction } from '../../interfaces';
import { getTransactionsAction, resetTransactionsAction, transactionsExtraReducer } from './action';

export interface TransactionsState {
  data: Transaction[];
  totalPage: number;
  loadingData: boolean;
}

const initialState: TransactionsState = {
  data: [],
  totalPage: 0,
  loadingData: false,
};

export const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: { resetTransactions: resetTransactionsAction },
  extraReducers: transactionsExtraReducer,
});

export default transactionsSlice.reducer;

export const { resetTransactions } = transactionsSlice.actions;

export { getTransactionsAction as getTransactions };
