import { useState } from 'react';
import { CloseButton, PrimaryButton } from '../buttons';
import { CloseIcon } from '../icons';
import { LabelSelectionInput } from '../inputs';

interface AssignCourseModalProps {
  close: () => void;
}

export default function AssignCourseModal({ close }: AssignCourseModalProps) {
  const [courses, setCourses] = useState<string[]>([]);
  return (
    <div
      className="flex h-[375px] w-[438px] flex-col rounded-2xl bg-white p-6"
      style={{ filter: 'drop-shadow(1px 4px 16px rgba(0, 0, 0, 0.1))' }}
    >
      <div className="flex items-center justify-between border-b border-b-netral-4">
        <h6 className="font-semibold text-netral-8">Assign Kursus</h6>
        <CloseButton onClick={close} type="button" />
      </div>
      <div className="mt-4 flex h-full flex-col justify-between space-y-6">
        <div className="h-full overflow-auto">
          <LabelSelectionInput
            defaultOption="-- Pilih Kursus --"
            defaultValue="-- Pilih Kursus --"
            id="promo-course-type"
            label="Tipe Kursus"
            onChange={(e) => setCourses([...courses, e.target.value])}
            options={initialCouse}
          />
          <div className="mt-[21px] flex flex-col space-y-3">
            {courses.map((el, i) => (
              <div
                key={i}
                className="relative w-max rounded-full border border-primary-600 p-2 pr-[18px] text-sm text-primary-600"
              >
                <p>{el}</p>
                <button
                  className="absolute top-0 -right-1"
                  onClick={() => setCourses(courses.filter((course) => course !== el))}
                  type="button"
                >
                  <CloseIcon className="h-[13px] w-[13px] rounded-full bg-primary-500 text-white" />
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-center">
          <PrimaryButton>Assign Kursus</PrimaryButton>
        </div>
      </div>
    </div>
  );
}

const initialCouse = [
  'Fundamental Web Programming',
  'Menjadi UI/UX Designer',
  'Belajar HTML untuk Pemula',
];
