export default function DeleteIcon({ ...rest }) {
  return (
    <svg fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_165_16563)">
        <path
          d="M7.99935 25.3333C7.99935 26.8 9.19935 28 10.666 28H21.3327C22.7993 28 23.9993 26.8 23.9993 25.3333V12C23.9993 10.5333 22.7993 9.33333 21.3327 9.33333H10.666C9.19935 9.33333 7.99935 10.5333 7.99935 12V25.3333ZM23.9993 5.33333H20.666L19.7193 4.38667C19.4793 4.14667 19.1327 4 18.786 4H13.2127C12.866 4 12.5193 4.14667 12.2793 4.38667L11.3327 5.33333H7.99935C7.26602 5.33333 6.66602 5.93333 6.66602 6.66667C6.66602 7.4 7.26602 8 7.99935 8H23.9993C24.7327 8 25.3327 7.4 25.3327 6.66667C25.3327 5.93333 24.7327 5.33333 23.9993 5.33333Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_165_16563">
          <rect fill="white" height="32" width="32" />
        </clipPath>
      </defs>
    </svg>
  );
}
