import { createSlice } from '@reduxjs/toolkit';
import { Task } from '../../interfaces';
import { getTasksAction, resetTasksAction, tasksExtraReducer } from './action';

export interface TasksState {
  data: Task[];
  totalPage: number;
  loading: boolean;
}

const initialState: TasksState = {
  data: [],
  totalPage: 0,
  loading: false,
};

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: { resetTasks: resetTasksAction },
  extraReducers: tasksExtraReducer,
});

export default tasksSlice.reducer;

export const { resetTasks } = tasksSlice.actions;

export { getTasksAction as getTasks };
