import { ArrowDropDownIcon } from '../icons';

interface StatCardProps {
  data: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Icon: any;
    title: string;
    value: number;
    usage?: {
      unused: number;
      used: number;
    };
    status?: {
      publish: number;
      takedown: number;
      waiting: number;
    };
    profit?: {
      average: 'Down' | 'Up';
      profitLastMonth: number;
      percentage: string;
    };
  };
}

export default function StatCard({ data }: StatCardProps) {
  return (
    <div className="flex w-full justify-between rounded-xl bg-white p-3">
      <div className="space-y-2">
        <p className="text-sm font-semibold text-netral-8">{data.title}</p>
        <h4 className="text-xl font-semibold text-netral-10">
          {data.value.toLocaleString('id-ID')}
        </h4>
      </div>
      <div className="flex h-full space-x-3">
        {data.usage && (
          <div className="flex items-center space-x-[5px] self-end">
            <p className="text-xs text-primary-600">{data.usage.unused} Unused</p>
            <p className="text-xs text-secondary3-600">{data.usage.used} used</p>
          </div>
        )}
        {data.status && (
          <div className="space-y-1">
            <p className="text-xs text-primary-600">{data.status.publish} Publish</p>
            <p className="text-xs text-secondary3-600">{data.status.takedown} Takedown</p>
            <p className="text-xs text-secondary3-600">{data.status.waiting} Wait Moderation</p>
          </div>
        )}
        {!!data.profit && (
          <div className="space-y-[6px]">
            <p className="text-xs text-primary-600">
              {data.profit.profitLastMonth.toLocaleString('id-ID')}
              <br />
              Last Month
            </p>
            <div className="flex items-center space-x-1">
              <ArrowDropDownIcon
                className={`${
                  data.profit.average === 'Down'
                    ? 'text-secondary3-600'
                    : 'rotate-180 text-primary-600'
                } h-5 w-5`}
              />
              <p
                className={`${
                  data.profit.average === 'Down' ? 'text-secondary3-600' : 'text-primary-600'
                } text-xs `}
              >
                {data.profit.percentage}%
              </p>
            </div>
          </div>
        )}
        <div className="flex h-12 w-12 items-center justify-center rounded-xl bg-[#23C4F4]">
          <data.Icon className="h-8 w-8 text-white" />
        </div>
      </div>
    </div>
  );
}
