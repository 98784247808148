import { loading } from '../../constants';
import { PrimaryButton, SecondaryButton } from '../buttons';

interface modalProps {
  close: () => void;
  title: string;
  subTitle: string;
  handleConfirm: () => void;
}

function ConfirmModal({ close, title, subTitle, handleConfirm }: modalProps) {
  const handleClick = async () => {
    await handleConfirm();
    close();
  };
  return (
    <div
      className="relative flex w-[375px] items-center justify-between rounded-2xl bg-white p-[24px]"
      style={{ filter: 'drop-shadow(1px 4px 16px rgba(0, 0, 0, 0.1))' }}
    >
      <div className="flex flex-col items-center space-y-[24px]">
        <img alt="confirm" className="h-[60px] w-[60px]" src="/assets/images/confirm.png" />
        <div className="space-y-[8px] text-center">
          <p className="text-xl font-bold text-netral-10">{title}</p>
          <p className="text-sm text-netral-6">{subTitle}</p>
        </div>
        <div className="flex w-full space-x-[16px]">
          <div className="w-full">
            <SecondaryButton onClick={() => close()}>Batal</SecondaryButton>
          </div>
          <PrimaryButton onClick={() => handleClick()}>Ya</PrimaryButton>
        </div>
      </div>
    </div>
  );
}

export default ConfirmModal;
