import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { DashboardLayout, LoginLayout } from '../layouts';
import Blog from '../pages/blog';
import CreateBlogPage from '../pages/blog/create';
import BlogDetail from '../pages/blog/[blog_id]';
import LearnerProgress from '../pages/course/Progress';
import EducatorDetail from '../pages/educator/[educator_id]';
import LearnerDetail from '../pages/learner/[learner_id]';
import Notification from '../pages/Notif/Notif';
import Rating from '../pages/rating/Rating';
import RatingDetail from '../pages/rating/[rating_id]';
const ArticleAndBanner = lazy(() => import('../pages/article-and-banner'));
const ArticleDetail = lazy(() => import('../pages/article-and-banner/article/[article_id]'));
const BannerDetail = lazy(() => import('../pages/article-and-banner/banner/[banner_id]'));
const Certificate = lazy(() => import('../pages/Certificate'));
const Course = lazy(() => import('../pages/course'));
const CourseDetail = lazy(() => import('../pages/course/course_id'));
const Interaction = lazy(() => import('../pages/course/course_id/Interaction'));
const Posttest = lazy(() => import('../pages/course/course_id/Posttest'));
const CourseTasks = lazy(() => import('../pages/course/course_id/Tasks'));
const Video = lazy(() => import('../pages/course/course_id/Video'));
const Educator = lazy(() => import('../pages/educator/Educator'));
const Home = lazy(() => import('../pages/Home'));
const Learner = lazy(() => import('../pages/learner/Learner'));
const Login = lazy(() => import('../pages/Login'));
const Profile = lazy(() => import('../pages/Profile'));
const Promo = lazy(() => import('../pages/promo'));
const PromoDetail = lazy(() => import('../pages/promo/[promo_id]'));
const Redeem = lazy(() => import('../pages/prakerja/Prakerja'));
const Setting = lazy(() => import('../pages/Setting'));
const Tasks = lazy(() => import('../pages/tasks'));
const TasksDetail = lazy(() => import('../pages/tasks/[tasks_id]'));
const Transaction = lazy(() => import('../pages/Transaction'));
const Voucher = lazy(() => import('../pages/voucher'));
const VoucherDetail = lazy(() => import('../pages/voucher/[voucher_id]'));

export default function Pages() {
  return (
    <Routes>
      <Route element={<DashboardLayout />}>
        <Route element={<Home />} path="/" />
        <Route element={<Learner />} path="/learner" />
        <Route element={<LearnerDetail />} path="/learner/:learner_id" />
        <Route element={<Rating />} path="/rating" />
        <Route element={<RatingDetail />} path="/rating/:rating_id" />
        <Route element={<Educator />} path="/educator" />
        <Route element={<EducatorDetail />} path="/educator/:educator_id" />
        <Route element={<Course />} path="/course" />
        <Route element={<CourseDetail />} path="/course/:course_id" />
        <Route element={<Blog />} path="/blog" />
        <Route element={<CreateBlogPage />} path="/blog/create" />
        <Route element={<BlogDetail />} path="/blog/:blog_id" />
        <Route element={<LearnerProgress />} path="/course/:progress_id/:learner_name" />
        <Route element={<Posttest />} path="/course/:course_id/posttest" />
        <Route element={<Interaction />} path="/course/:course_id/interaction" />
        <Route element={<Video />} path="/course/:course_id/video" />
        <Route element={<CourseTasks />} path="/course/:course_id/tasks" />
        <Route element={<Transaction />} path="/transaction" />
        <Route element={<Redeem />} path="/prakerja" />
        <Route element={<Certificate />} path="/certificate" />
        <Route element={<ArticleAndBanner />} path="/article-and-banner" />
        <Route element={<ArticleDetail />} path="/article-and-banner/article/:article_id" />
        <Route element={<BannerDetail />} path="/article-and-banner/banner/:banner_id" />
        <Route element={<Voucher />} path="/voucher" />
        <Route element={<VoucherDetail />} path="/voucher/:voucher_id" />
        <Route element={<Promo />} path="/promo" />
        <Route element={<PromoDetail />} path="/promo/:promo_id" />
        <Route element={<Tasks />} path="/tasks" />
        <Route element={<TasksDetail />} path="/tasks/:tasks_id" />
        <Route element={<Setting />} path="/setting" />
        <Route element={<Profile />} path="/profile" />
        <Route element={<Notification />} path="/notification" />
        <Route
          element={<h4 className="text-xl font-semibold text-red-600">404 Page not found!</h4>}
          path="*"
        />
      </Route>
      <Route element={<LoginLayout />}>
        <Route element={<Login />} path="/login" />
      </Route>
    </Routes>
  );
}
