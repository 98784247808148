export default function ArrowDropDownIcon({ ...rest }) {
  return (
    <svg fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_176_18300)">
        <path
          d="M7.25829 9.75898L9.41663 11.9173C9.74163 12.2423 10.2666 12.2423 10.5916 11.9173L12.75 9.75898C13.275 9.23398 12.9 8.33398 12.1583 8.33398H7.84163C7.09996 8.33398 6.73329 9.23398 7.25829 9.75898Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_176_18300">
          <rect fill="white" height="20" width="20" />
        </clipPath>
      </defs>
    </svg>
  );
}
