import axios from 'axios';
import { APIBaseURL } from '../../environment';
import User from '../../interfaces/user';

interface LoginAPI {
  code: number;
  status: boolean;
  message: string;
  token: string;
  user: User;
}

// interface ResetPasswordAPI {
//   message: string;
//   status: boolean;
// }

// interface createUser {
//   status: boolean;
//   message: string;
// }

export async function loginAPI(email: string, password: string): Promise<LoginAPI> {
  const myHeaders: HeadersInit = new Headers();
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

  const urlencoded: URLSearchParams = new URLSearchParams();
  urlencoded.append('email_or_phonenumber', email);
  urlencoded.append('password', password);

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow',
  };

  const response: Response = await fetch(`${APIBaseURL}/api/auth/login`, requestOptions);

  const result: LoginAPI = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (!result.status) throw new Error(result.message);

  return result;
}

export async function resetPassword(
  data: { password: string; confirmPassword: string },
  id: string,
  token: string,
) {
  const response = await axios({
    method: 'put',
    url: `${APIBaseURL}/api/admin/resetPassword/${id}`,
    headers: { Authorization: `Bearer ${token}` },
    data: data,
  });
  const result = await response.data;

  if (!result.status) return result.response.data;

  return result;
}

export async function createUser(
  data: {
    email: string;
    phoneNumber: string;
    name: string;
    password: string;
    confirm_password: string;
    role: string[];
  },
  token: string,
) {
  const response = await axios({
    method: 'post',
    url: `${APIBaseURL}/api/admin/createUser`,
    headers: { Authorization: `Bearer ${token}` },
    data: data,
  });
  const result = await response.data;

  if (!result.status) return result.response.data;

  return result;
}
