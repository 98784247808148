import { formatLink } from '../../constants/getLink';
import { CourseDetail } from '../../interfaces';
import { PrimaryButton } from '../buttons';
import { EditIcon } from '../icons';

interface BigCourseCardProps {
  data: CourseDetail;
  secondaryFunc?: () => void;
  handleEditDiscount?: () => void;
}

export default function BigCourseCard({
  secondaryFunc,
  data,
  handleEditDiscount,
}: BigCourseCardProps) {
  return (
    <div className="flex space-x-6 rounded-xl bg-white p-4 pb-[22px]">
      <div>
        <div className="h-[305px] w-[280px] rounded-xl bg-black">
          <img
            alt={data?.thumbnail}
            className="h-full w-full overflow-hidden object-contain"
            src={formatLink(data?.thumbnail || '')}
          />
        </div>
      </div>
      <div className="w-full">
        <div className="space-y-2 border-b border-b-netral-5 pb-4">
          {/* <p className="text-xs font-semibold text-netral-6">KP0001</p> */}
          <h4 className="text-xl font-semibold text-netral-10">{data?.title}</h4>
          <p className="text-sm text-netral-9">{data?.instructor}</p>
        </div>
        <div className="mt-4 grid grid-cols-3 gap-x-6 gap-y-[22px]">
          {details(data).map((el, i) => (
            <div key={i} className="space-y-2">
              <p className="text-xs font-semibold text-netral-8">{el.name}</p>
              <p className="font-semibold text-netral-10">{el.value}</p>
            </div>
          ))}

          <div className="flex items-center space-x-[20px]">
            <div className="space-y-2">
              <p className="text-xs font-semibold text-netral-8">Discount</p>
              <p className="font-semibold text-netral-10">{data?.discount || 0}%</p>
            </div>
            <div onClick={handleEditDiscount}>
              <EditIcon className="h-[24px] w-[24px] cursor-pointer text-[#006A9D]" />
            </div>
          </div>
        </div>
        <div className="mt-[16px]">
          <p className="mb-[8px] font-semibold text-netral-10">Kode Kelas</p>
          <div className=" flex space-x-[20px]">
            <div className="flex items-center space-x-[12px]">
              <img alt="vendor_img" className="w-[24px]" src="/assets/images/tokopedia.png" />
              <p>{data?.product_code?.find((el) => el.vendor === 'Tokopedia')?.code || '-'}</p>
            </div>
            <div className="flex items-center space-x-[12px]">
              <img alt="vendor_img" className="w-[24px]" src="/assets/images/bukalapak.png" />
              <p>{data?.product_code?.find((el) => el.vendor === 'Bukalapak')?.code || '-'}</p>
            </div>
            <div className="flex items-center space-x-[12px]">
              <img alt="vendor_img" className="h-[20px] w-[30px]" src="/assets/images/pintar.png" />
              <p>{data?.product_code?.find((el) => el.vendor === 'Pintar')?.code || '-'}</p>
            </div>
          </div>
        </div>
        <div className="mt-6 flex items-center space-x-4">
          <PrimaryButton>Preview Kelas</PrimaryButton>
          <PrimaryButton color="light-green" onClick={secondaryFunc} type="button">
            Update kode kelas
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}

const details = (data: CourseDetail) => [
  { name: 'Jenis Kelas', value: data?.class_type },
  { name: 'Jenis Peserta', value: data?.participant_type },
  { name: 'Kategori', value: data?.category },
  { name: 'Tanggal Mulai', value: data?.start_date },
  { name: 'Harga Kelas', value: `Rp ${Number(data?.price).toLocaleString('id-ID')}` },
];
