// import { Icon } from '../../image';

interface StartsProps {
  n: number;
  size: string;
}

export default function Stars({ n, size }: StartsProps) {
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    n < i ? stars.push('Star-outline-secondary3-400') : stars.push('Star-secondary3-400');
  }

  return (
    <div className="flex items-center space-x-[2px]">
      {stars.map((star, i) => (
        <img key={i} alt="rating" className={`${size}`} src={`/assets/logos/${star}.svg`} />
      ))}
    </div>
  );
}

Stars.defaultProps = {
  size: 'w-[18px] h-[18px]',
};
