export default function NotificationIcon2({ ...rest }) {
  return (
    <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M19.9426 15.8775C19.6609 15.5975 19.5017 15.2172 19.5001 14.82V10.5C19.4988 8.8197 18.9333 7.18851 17.8943 5.86797C16.8553 4.54743 15.4029 3.61405 13.7701 3.2175C13.8371 3.02704 13.8726 2.8269 13.8751 2.625C13.8751 2.12772 13.6775 1.65081 13.3259 1.29917C12.9742 0.947544 12.4973 0.75 12.0001 0.75C11.5028 0.75 11.0259 0.947544 10.6742 1.29917C10.3226 1.65081 10.1251 2.12772 10.1251 2.625C10.1275 2.8269 10.163 3.02704 10.2301 3.2175C8.59722 3.61405 7.14485 4.54743 6.10582 5.86797C5.0668 7.18851 4.50132 8.8197 4.50005 10.5V14.82C4.49838 15.2172 4.33924 15.5975 4.05755 15.8775C3.72005 16.2136 3.45275 16.6134 3.2712 17.0538C3.08965 17.4941 2.99748 17.9662 3.00005 18.4425V18.75C3.00005 19.1478 3.15809 19.5294 3.43939 19.8107C3.7207 20.092 4.10223 20.25 4.50005 20.25H9.88505C9.79774 20.4905 9.75208 20.7441 9.75005 21C9.75005 21.5967 9.9871 22.169 10.4091 22.591C10.831 23.0129 11.4033 23.25 12.0001 23.25C12.5968 23.25 13.1691 23.0129 13.591 22.591C14.013 22.169 14.2501 21.5967 14.2501 21C14.248 20.7441 14.2024 20.4905 14.1151 20.25H19.5001C19.8979 20.25 20.2794 20.092 20.5607 19.8107C20.842 19.5294 21.0001 19.1478 21.0001 18.75V18.4425C21.0026 17.9662 20.9105 17.4941 20.7289 17.0538C20.5474 16.6134 20.2801 16.2136 19.9426 15.8775ZM11.6251 2.625C11.6251 2.55083 11.647 2.47833 11.6883 2.41666C11.7295 2.35499 11.788 2.30693 11.8565 2.27855C11.9251 2.25016 12.0005 2.24274 12.0732 2.25721C12.146 2.27167 12.2128 2.30739 12.2652 2.35984C12.3177 2.41228 12.3534 2.4791 12.3678 2.55184C12.3823 2.62458 12.3749 2.69998 12.3465 2.76851C12.3181 2.83703 12.2701 2.8956 12.2084 2.9368C12.1467 2.97801 12.0742 3 12.0001 3C11.9006 3 11.8052 2.96049 11.7349 2.89016C11.6646 2.81984 11.6251 2.72446 11.6251 2.625Z"
        fill="currentColor"
      />
    </svg>
  );
}
