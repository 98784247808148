import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SecondaryButton } from '../../components/buttons';
import { ChecklistIcon, CloseIconRed } from '../../components/icons';
import { formatLink } from '../../constants/getLink';
import { APIBaseURL } from '../../environment';
import { useTypedSelector } from '../../store';

interface downloadFileRedeem {
  _id: string;
  user_id: string;
  class_id: string;
  completedMaterial: number;
  pretest: number;
  posttest: number;
  totalMaterials: number;
  skilltest_score: string;
  user: {
    name: string;
    profile_picture: string;
  };
  class: {
    courseTitle: string;
    _id: string;
  };
  voucher: {
    voucher_code: string;
    redeemCode: string;
    isReported: boolean;
  };
  status: string;
  chapters: {
    score: number;
    title: string;
  }[];
}
const LearnerProgress = () => {
  // const [tableHeads, tableBodies] = promoDetailTable();
  const [dataLearner, setdataLearner] = useState({
    _id: '',
    user_id: '',
    class_id: '',
    completedMaterial: 0,
    pretest: 0,
    posttest: 0,
    totalMaterials: 0,
    skilltest_score: '',
    user: {
      name: '',
      profile_picture: '',
    },
    class: {
      courseTitle: '',
      _id: '',
    },
    voucher: {
      voucher_code: '',
      redeemCode: '',
      isReported: false,
    },
    chapters: [
      {
        score: 0,
        title: 'sesi 1',
      },
    ],
    status: 'Lulus',
  });
  const { token } = useTypedSelector((state) => state.auth);
  const { progress_id } = useParams();

  async function getLearnerData(): Promise<downloadFileRedeem> {
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions: RequestInit = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const response = await fetch(
      `${APIBaseURL}/api/admin/class-progress/${progress_id}`,
      requestOptions,
    );
    const result = await response.json();
    if (result.status) setdataLearner(result.result);
    return result;
  }

  useEffect(() => {
    getLearnerData();
  }, []);

  const profilePicture = formatLink(dataLearner?.user.profile_picture || '');
  return (
    <div className="my-5 space-y-6">
      <div
        className="flex w-full items-center justify-between rounded-xl border border-netral-4 bg-netral-1 px-6 py-3"
        style={{
          backdropFilter: 'blur(10.5px)',
          filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.06))',
        }}
      >
        <div className="flex items-center space-x-5">
          <div>
            <img
              alt={profilePicture}
              className="h-[70px] w-[70px] rounded-xl bg-[#23C4F4]"
              src={profilePicture}
            />
          </div>
          <div className="flex justify-between space-x-6">
            <div className="space-y-1">
              <p className="text-sm text-netral-6">Nama</p>
              <p className="text-sm text-netral-6">Kode Voucher</p>
              <p className="text-sm text-netral-6">Kode Redeem</p>
            </div>
            <div className="space-y-1">
              <p className="text-sm text-netral-6">{dataLearner.user.name}</p>
              <div className="flex w-full items-center space-x-4 text-netral-6">
                <p>{dataLearner.voucher.voucher_code}</p>
                {dataLearner.voucher.isReported ? (
                  <ChecklistIcon className="h-[15px] w-[15px]" />
                ) : (
                  <CloseIconRed className="h-[15px] w-[15px]" />
                )}
              </div>
              <p className="text-sm text-netral-6">{dataLearner.voucher.redeemCode || '-'}</p>
            </div>
          </div>
        </div>
        <div className="self-start">
          <SecondaryButton color="light-green" rounded="small" size="small">
            Informasi Detail Peserta
          </SecondaryButton>
        </div>
      </div>
      <div className="flex justify-between rounded-xl bg-white p-4 text-base text-netral-8">
        <div className="space-y-4">
          <p>Nilai Pre-Test Test</p>
          <p>Nilai Post Test</p>
          <p>Nilai Uji Keterampilan</p>
        </div>
        <div className="space-y-4">
          <p>{dataLearner.pretest}</p>
          <p>{dataLearner.posttest}</p>
          <p>{dataLearner.skilltest_score}</p>
        </div>
      </div>
      <div className="space-y-4 bg-white p-4">
        <p className="text-lg font-semibold text-netral-10">Kuis</p>
        <div className="flex justify-between rounded-xl  text-base text-netral-8">
          <div className="space-y-4">
            {dataLearner.chapters.map((el, idx) => (
              <p key={idx}>{el.title}</p>
            ))}
          </div>
          <div className="space-y-4">
            {dataLearner.chapters.map((el, idx) => (
              <p key={idx}>{el.score}</p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearnerProgress;
