import { APIBaseURL } from '../../environment';
import { Blog } from '../../interfaces';

interface GetAllBlogAPI {
  status: boolean;
  totalData: number;
  totalPage: number;
  result: Blog[];
}

interface GetBlogByIdAPI {
  status: boolean;
  message?: string;
  result: Blog;
}

export async function getAllBlogAPI(token: string, query: string): Promise<GetAllBlogAPI> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(`${APIBaseURL}/api/article/admin?${query}`, requestOptions);

  const result = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (!result.status) throw new Error(result.message);

  return result;
}

export async function getBlogByIdAPI(token: string, id: string): Promise<GetBlogByIdAPI> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(`${APIBaseURL}/api/article/${id}`, requestOptions);

  const result = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (!result.status) throw new Error(result.message);

  return result;
}

export async function createBlog(
  token: string,
  data: {
    title: string;
    content: string;
    author: string;
    image: any;
  },
): Promise<GetBlogByIdAPI> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const urlencoded = new FormData();
  urlencoded.append('file', data.image);
  urlencoded.append('content', data.content);
  urlencoded.append('author', data.author);
  urlencoded.append('title', data.title);

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
    body: urlencoded,
  };

  const response = await fetch(`${APIBaseURL}/api/article`, requestOptions);

  const result = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (!result.status) {
    throw new Error(result.message);
  }

  return result;
}

export async function updateBlog(
  token: string,
  id: string,
  data: {
    title: string;
    content: string;
    author: string;
    image: any;
  },
): Promise<GetBlogByIdAPI> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const urlencoded = new FormData();
  urlencoded.append('file', data.image);
  urlencoded.append('content', data.content);
  urlencoded.append('author', data.author);
  urlencoded.append('title', data.title);

  const requestOptions: RequestInit = {
    method: 'PUT',
    headers: myHeaders,
    redirect: 'follow',
    body: urlencoded,
  };

  const response = await fetch(`${APIBaseURL}/api/article/${id}`, requestOptions);

  const result = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (!result.status) {
    throw new Error(result.message);
  }

  return result;
}

export async function deleteBlog(token: string, id: string): Promise<GetBlogByIdAPI> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions: RequestInit = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(`${APIBaseURL}/api/article/${id}`, requestOptions);

  const result = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (!result.status) {
    throw new Error(result.message);
  }

  return result;
}
