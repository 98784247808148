import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { AlertModal } from '../../components/modals';
import { Voucher } from '../../interfaces';
import { createVoucherAPI, getVouchersByClassIdAPI } from '../../services/api/voucher';
import { AppDispatch, RootState } from '../../store';
import { closeModal, openModal } from '../modal/slice';
import { VouchersState } from './slice';

export const createVouchersAction = createAsyncThunk<
  Voucher[],
  { courseId: string; vendor: string; total: string; voucher_type: string; discount: string },
  { state: RootState; dispatch: AppDispatch }
>(
  'vouchers/createVouchers',
  async ({ courseId, vendor, total, voucher_type, discount }, { getState, dispatch }) => {
    try {
      const { token } = getState().auth;

      if (!token) throw null;

      const result = await createVoucherAPI(token, courseId, vendor, total, voucher_type, discount);

      return result.data;
    } catch (error) {
      const message = (error as Error).message;

      const close = () => dispatch(closeModal());

      dispatch(openModal(AlertModal({ close, message })));

      throw error;
    }
  },
);

export const getVouchersByClassAction = createAsyncThunk<
  {
    class: [{ id: string; courseTitle: string; price: number; instructor: string }];
    voucher: Voucher[];
  },
  { courseId: string },
  { state: RootState; dispatch: AppDispatch }
>('vouchers/getVouchersByClass', async ({ courseId }, { getState, dispatch }) => {
  try {
    const { token } = getState().auth;

    if (!token) throw null;

    const result = await getVouchersByClassIdAPI(token, courseId);

    return result.data;
  } catch (error) {
    const message = (error as Error).message;

    const close = () => dispatch(closeModal());

    dispatch(openModal(AlertModal({ close, message })));

    throw error;
  }
});

export function resetVouchersByClassDataAction(state: VouchersState) {
  state.dataVoucherByClass = null;
}

export function vouchersExtraReducer(builder: ActionReducerMapBuilder<VouchersState>) {
  builder
    .addCase(createVouchersAction.pending, (state) => {
      state.loadingCreateVouchers = true;
    })
    .addCase(createVouchersAction.fulfilled, (state) => {
      state.loadingCreateVouchers = false;
    })
    .addCase(createVouchersAction.rejected, (state) => {
      state.loadingCreateVouchers = false;
    })
    .addCase(getVouchersByClassAction.pending, (state) => {
      state.dataVoucherByClass = null;
    })
    .addCase(getVouchersByClassAction.fulfilled, (state, action) => {
      state.dataVoucherByClass = action.payload;
    })
    .addCase(getVouchersByClassAction.rejected, (state) => {
      state.dataVoucherByClass = null;
    });
}
