import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { LearnersState } from './slice';
import { AlertModal } from '../../components/modals';
import { getLearnersAPI } from '../../services/api/learner';
import { Learner } from '../../interfaces';
import { closeModal, openModal } from '../modal/slice';
import { AppDispatch, RootState } from '../../store';

export function resetLeanersAction(state: LearnersState) {
  state.loadingData = false;
  state.data = [];
  state.totalPage = 0;
}

export const getLearnersAction = createAsyncThunk<
  { result: Learner[]; status: boolean; total_page: number },
  { limit: number; page: number; name: string; startDate: string; endDate: string },
  { state: RootState; dispatch: AppDispatch }
>(
  'learners/getLearners',
  async ({ limit, page, name, startDate, endDate }, { getState, dispatch }) => {
    try {
      const { token } = getState().auth;

      if (!token) throw null;

      const result = await getLearnersAPI(token, limit, page, name, startDate, endDate);

      return result;
    } catch (error) {
      const message = (error as Error).message;

      const close = () => dispatch(closeModal());

      dispatch(openModal(AlertModal({ close, message })));

      throw error;
    }
  },
);

export function learnersExtraReducer(builder: ActionReducerMapBuilder<LearnersState>) {
  builder
    .addCase(getLearnersAction.pending, (state) => {
      state.loadingData = true;
    })
    .addCase(getLearnersAction.fulfilled, (state, action) => {
      state.loadingData = false;

      state.data = action.payload.result;

      state.totalPage = action.payload.total_page;
    })
    .addCase(getLearnersAction.rejected, (state) => {
      state.loadingData = false;
    });
}
