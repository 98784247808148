import { useEffect, useMemo, useState, ChangeEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../../components/buttons';
import { EditIcon, SearchIcon } from '../../../components/icons';
import { TablePagination } from '../../../components/paginations';
import { useQuery } from '../../../hooks';
import { Blog } from '../../../interfaces';
import { createBlog, getAllBlogAPI } from '../../../services/api/blog';
import { useTypedDispatch, useTypedSelector } from '../../../store';
import { LabelFileInput, LabelInput, LabelTextAreaInput } from '../../../components/inputs';
import { openModal } from '../../../features/modal/action';
import { closeModal } from '../../../features/modal/slice';
import { AlertModal } from '../../../components/modals';

interface AllBlogData {
  status: boolean;
  totalData: number;
  totalPage: number;
  result: Blog[];
}

const CreateBlogPage = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { token } = useTypedSelector((state) => state.auth);
  const [dataToSend, setDataToSend] = useState<{
    title: string;
    content: string;
    author: string;
    image: File | null;
  }>({
    title: '',
    content: '',
    author: '',
    image: null,
  });
  const dispatch = useTypedDispatch();

  const onFormChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setDataToSend({ ...dataToSend, [e.target.name]: e.target.value });

  const handleSubmit = async () => {
    try {
      const res = await createBlog(token, dataToSend);
      console.log(res);
      if (res.status) {
        navigate('/blog');
      }
    } catch (error) {
      const message = (error as Error).message;
      const close = () => dispatch(closeModal());

      dispatch(openModal(AlertModal({ close, message })));

      throw error;
    }
  };

  console.log(dataToSend);

  return (
    <div className="space-y-6">
      <div className="space-y-[12px] rounded-xl bg-white p-4">
        <div className="flex items-center space-x-2">
          <img
            className="h-[15px] w-[15px] cursor-pointer"
            onClick={() => history.back()}
            src="/assets/logos/left-arrow.svg"
          />
          <p className="text-xs text-netral-10">List Blog</p>
        </div>
        <div className="flex items-center justify-between">
          <h5 className="text-lg font-semibold text-netral-10">Buat Blog</h5>
          <div className="flex">
            {/* <DownloadButton>Download Excel</DownloadButton> */}
            <PrimaryButton color="dark-green" onClick={() => handleSubmit()} size="small">
              Simpan
            </PrimaryButton>
          </div>
        </div>
        <div className="grid h-[500px] w-full grid-cols-[1fr_1px_1fr] gap-[24px] border-y border-netral-5 p-[16px]">
          <div className="w-full space-y-[24px] overflow-scroll pr-[20px]">
            <LabelInput
              id="writter"
              label="Penulis"
              name="author"
              onChange={onFormChange}
              value={dataToSend.author}
            />
            <LabelInput
              id="title"
              label="Judul"
              name="title"
              onChange={onFormChange}
              value={dataToSend.title}
            />
            <LabelTextAreaInput
              id="content"
              label="Isi"
              name="content"
              onChange={onFormChange}
              value={dataToSend.content}
            />
          </div>
          <div className="border-l border-netral-3" />
          <div>
            {!dataToSend.image ? (
              <div className="h-[300px] w-full bg-netral-3">
                <label
                  className="flex h-full w-full cursor-pointer flex-col items-center justify-center bg-netral-3"
                  htmlFor="image"
                >
                  <img className="h-[32px] w-[32px]" src="/assets/logos/photo_camera.svg" />
                  <p className="text-sm text-netral-10">Upload Image</p>
                </label>
                <input
                  className="hidden"
                  id="image"
                  name="image"
                  onChange={(e) =>
                    setDataToSend({ ...dataToSend, image: e.target.files && e.target.files[0] })
                  }
                  type="file"
                />
              </div>
            ) : (
              <div className="w-full">
                <label className="flex h-full w-full cursor-pointer" htmlFor="image">
                  <img className="w-full" src={URL.createObjectURL(dataToSend.image)} />
                </label>
                <input
                  className="hidden"
                  id="image"
                  name="image"
                  onChange={(e) =>
                    setDataToSend({ ...dataToSend, image: e.target.files && e.target.files[0] })
                  }
                  type="file"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateBlogPage;
