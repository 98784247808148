import { useEffect, useMemo, useState, ChangeEvent } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PrimaryButton, SecondaryButton } from '../../components/buttons';
import { DeleteIcon, EditIcon, SearchIcon } from '../../components/icons';
import { TablePagination } from '../../components/paginations';
import { useQuery } from '../../hooks';
import { Blog } from '../../interfaces';
import {
  createBlog,
  deleteBlog,
  getAllBlogAPI,
  getBlogByIdAPI,
  updateBlog,
} from '../../services/api/blog';
import { useTypedDispatch, useTypedSelector } from '../../store';
import { LabelFileInput, LabelInput, LabelTextAreaInput } from '../../components/inputs';
import { openModal } from '../../features/modal/action';
import { closeModal } from '../../features/modal/slice';
import { AlertModal } from '../../components/modals';
import { formatLink } from '../../constants/getLink';

interface AllBlogData {
  status: boolean;
  totalData: number;
  totalPage: number;
  result: Blog[];
}

const BlogDetail = () => {
  const { pathname } = useLocation();
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { token } = useTypedSelector((state) => state.auth);
  const { blog_id } = useParams();
  const [mode, setMode] = useState('view');
  const [dataToSend, setDataToSend] = useState<{
    title: string;
    content: string;
    author: string;
    image: File | string | null;
  }>({
    title: '',
    content: '',
    author: '',
    image: null,
  });
  const [dataDisplay, setDataDisplay] = useState<Blog>({
    image: '',
    title: '',
    content: '',
    author: '',
    status: '',
    _id: '',
    createdAt: '',
    updatedAt: '',
  });

  const onFormChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setDataToSend({ ...dataToSend, [e.target.name]: e.target.value });

  const handleGetAllBlogData = async (id: string) => {
    const res = await getBlogByIdAPI(token, id);
    const result = res.result;
    if (res.status) {
      setDataDisplay(result);
      setDataToSend({
        title: result.title,
        content: result.content,
        author: result.author,
        image: result.image,
      });
    }
  };

  useEffect(() => {
    if (blog_id) {
      handleGetAllBlogData(blog_id);
    }
  }, [blog_id]);

  const handleSubmit = async () => {
    try {
      const res = await updateBlog(token, blog_id || '', dataToSend);
      const result = res.result;
      if (res.status) {
        setDataDisplay(result);
        setDataToSend({
          title: result.title,
          content: result.content,
          author: result.author,
          image: result.image,
        });
        setMode('view');
      }
    } catch (error) {
      const message = (error as Error).message;
      const close = () => dispatch(closeModal());

      dispatch(openModal(AlertModal({ close, message })));

      throw error;
    }
  };

  const handleDelete = async () => {
    try {
      const res = await deleteBlog(token, blog_id || '');
      const result = res.result;
      if (res.status) {
        history.back();
      }
    } catch (error) {
      const message = (error as Error).message;
      const close = () => dispatch(closeModal());

      dispatch(openModal(AlertModal({ close, message })));

      throw error;
    }
  };

  return (
    <div className="space-y-6">
      <div className="space-y-[12px] rounded-xl bg-white p-4">
        <div className="flex items-center space-x-2">
          <img
            className="h-[15px] w-[15px] cursor-pointer"
            onClick={() => history.back()}
            src="/assets/logos/left-arrow.svg"
          />
          <p className="text-xs text-netral-10">List Blog</p>
        </div>
        <div className="flex items-center justify-between">
          <h5 className="text-lg font-semibold text-netral-10">Detail Blog</h5>
          {mode === 'view' ? (
            <div className="flex">
              {/* <DownloadButton>Download Excel</DownloadButton> */}
              {/* <PrimaryButton color="dark-green" size="small" onClick={() => handleSubmit()}>
              Simpan
            </PrimaryButton> */}
              <SecondaryButton onClick={() => setMode('edit')} size="small">
                <div className="flex items-center gap-[6px]">
                  <EditIcon className="h-[12px] w-[12px]" />
                  <p>Ubah Data</p>
                </div>
              </SecondaryButton>
            </div>
          ) : (
            <div className="flex gap-[8px]">
              <SecondaryButton onClick={() => setMode('edit')} size="small">
                <div className="flex items-center gap-[6px]" onClick={() => handleDelete()}>
                  <DeleteIcon className="h-[12px] w-[12px]" />
                  <p>Hapus Data</p>
                </div>
              </SecondaryButton>
              <PrimaryButton color="dark-green" onClick={() => handleSubmit()} size="small">
                Simpan
              </PrimaryButton>
            </div>
          )}
        </div>
        <div className="grid h-[500px] w-full grid-cols-[1fr_1px_1fr] gap-[24px] border-y border-netral-5 py-[16px]">
          {mode === 'view' ? (
            <div className="space-y-[8px] overflow-scroll pr-[20px]">
              <p className="text-sm text-netral-6">
                {dataDisplay.author} • {dataDisplay.createdAt}
              </p>
              <p className="text-2xl font-bold text-netral-10">{dataDisplay.title}</p>
              <p className="whitespace-pre-wrap text-sm text-netral-10">{dataDisplay.content}</p>
            </div>
          ) : (
            <div className="w-full space-y-[24px] overflow-scroll pr-[20px]">
              <LabelInput
                id="writter"
                label="Penulis"
                name="author"
                onChange={onFormChange}
                value={dataToSend.author}
              />
              <LabelInput
                id="title"
                label="Judul"
                name="title"
                onChange={onFormChange}
                value={dataToSend.title}
              />
              <LabelTextAreaInput
                id="content"
                label="Isi"
                name="content"
                onChange={onFormChange}
                value={dataToSend.content}
              />
            </div>
          )}
          <div className="border-l border-netral-3" />
          {mode === 'view' ? (
            <div>
              <img src={formatLink(dataDisplay.image)} />
            </div>
          ) : (
            <div>
              {!dataToSend.image ? (
                <div className="h-[300px] w-full bg-netral-3">
                  <label
                    className="flex h-full w-full cursor-pointer flex-col items-center justify-center bg-netral-3"
                    htmlFor="image"
                  >
                    <img className="h-[32px] w-[32px]" src="/assets/logos/photo_camera.svg" />
                    <p className="text-sm text-netral-10">Upload Image</p>
                  </label>
                  <input
                    className="hidden"
                    id="image"
                    name="image"
                    onChange={(e) =>
                      setDataToSend({ ...dataToSend, image: e.target.files && e.target.files[0] })
                    }
                    type="file"
                  />
                </div>
              ) : (
                <div className="w-full">
                  <label className="flex h-full w-full cursor-pointer" htmlFor="image">
                    {typeof dataToSend.image === 'string' ? (
                      <img className="w-full" src={formatLink(dataToSend.image)} />
                    ) : (
                      <img className="w-full" src={URL.createObjectURL(dataToSend.image)} />
                    )}
                  </label>
                  <input
                    className="hidden"
                    id="image"
                    name="image"
                    onChange={(e) =>
                      setDataToSend({ ...dataToSend, image: e.target.files && e.target.files[0] })
                    }
                    type="file"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
