import { APIBaseURL } from '../../environment';
import { Admin } from '../../interfaces';

export interface GetdminsAPI {
  code: number;
  message?: string;
  total_page: number;
  data: Admin[];
}

interface AddAdminAPI {
  code: number;
  status: boolean;
  message: string;
}

interface DeleteAdminAPI {
  code: number;
  message: string;
}

interface UpdateAdminAPI {
  code: number;
  message: string;
}

export async function addAdminAPI(
  token: string,
  email: string,
  name: string,
  password: string,
): Promise<AddAdminAPI> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({ email, name, password, role: 'Admin' });

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const response: Response = await fetch(`${APIBaseURL}/api/admin/createUser`, requestOptions);

  const result: AddAdminAPI = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (!result.status) throw new Error(result.message);

  return result;
}

export async function getAdminsAPI(token: string, query: string): Promise<GetdminsAPI> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response: Response = await fetch(`${APIBaseURL}/api/admin/all${query}`, requestOptions);

  const result: GetdminsAPI = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (result.code !== 200) throw new Error(result.message);

  return result;
}

export async function updateAdminAPI(
  token: string,
  adminId: string,
  email: string,
  name: string,
  password: string,
): Promise<UpdateAdminAPI> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify({ email, name, password });

  const requestOptions: RequestInit = {
    method: 'PUT',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const response: Response = await fetch(
    `${APIBaseURL}/api/admin/updateUser/${adminId}`,
    requestOptions,
  );

  const result: UpdateAdminAPI = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (result.code !== 200) throw new Error(result.message);

  return result;
}

export async function deleteAdminAPI(token: string, adminId: string): Promise<DeleteAdminAPI> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions: RequestInit = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response: Response = await fetch(
    `${APIBaseURL}/api/admin/delete/${adminId}`,
    requestOptions,
  );

  const result: DeleteAdminAPI = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (result.code !== 200) throw new Error(result.message);

  return result;
}
