export default function CashierMachineIcon({ ...rest }) {
  return (
    <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M13.5 4.5C13.5663 4.5 13.6299 4.47366 13.6768 4.42678C13.7237 4.37989 13.75 4.3163 13.75 4.25V2.25C13.75 2.1837 13.7237 2.12011 13.6768 2.07322C13.6299 2.02634 13.5663 2 13.5 2H9C8.9337 2 8.87011 2.02634 8.82322 2.07322C8.77634 2.12011 8.75 2.1837 8.75 2.25V4.25C8.75 4.3163 8.77634 4.37989 8.82322 4.42678C8.87011 4.47366 8.9337 4.5 9 4.5H10.5V5.75H2C1.93702 5.7498 1.87628 5.77338 1.82993 5.81602C1.78359 5.85866 1.75504 5.91722 1.75 5.98L1.3125 11.25H14.6875L14.25 5.98C14.245 5.91722 14.2164 5.85866 14.1701 5.81602C14.1237 5.77338 14.063 5.7498 14 5.75H12V4.5H13.5ZM6.185 9.96H5V9.46H6.185V9.96ZM6.185 8.71H5V8.21H6.185V8.71ZM6.185 7.46H5V6.96H6.185V7.46ZM7.8425 9.96H6.6575V9.46H7.8425V9.96ZM7.8425 8.71H6.6575V8.21H7.8425V8.71ZM7.8425 7.46H6.6575V6.96H7.8425V7.46ZM9.5 9.96H8.315V9.46H9.5V9.96ZM9.5 8.71H8.315V8.21H9.5V8.71ZM9.5 7.46H8.315V6.96H9.5V7.46ZM11 9.96H9.815V9.46H11V9.96ZM11 8.71H9.815V8.21H11V8.71ZM11 7.46H9.815V6.96H11V7.46ZM15 11.75H11.25V12.75C11.25 12.8163 11.2237 12.8799 11.1768 12.9268C11.1299 12.9737 11.0663 13 11 13H5C4.9337 13 4.87011 12.9737 4.82322 12.9268C4.77634 12.8799 4.75 12.8163 4.75 12.75V11.75H1C0.933696 11.75 0.870107 11.7763 0.823223 11.8232C0.776339 11.8701 0.75 11.9337 0.75 12V14.25C0.75 14.3163 0.776339 14.3799 0.823223 14.4268C0.870107 14.4737 0.933696 14.5 1 14.5H15C15.0663 14.5 15.1299 14.4737 15.1768 14.4268C15.2237 14.3799 15.25 14.3163 15.25 14.25V12C15.25 11.9337 15.2237 11.8701 15.1768 11.8232C15.1299 11.7763 15.0663 11.75 15 11.75ZM2.75 1.75C2.75 1.61739 2.69732 1.49021 2.60355 1.39645C2.50979 1.30268 2.38261 1.25 2.25 1.25C2.11739 1.25 1.99021 1.30268 1.89645 1.39645C1.80268 1.49021 1.75 1.61739 1.75 1.75V3.5H2.75V1.75Z"
        fill="currentColor"
      />
      <path
        d="M7.74999 5.25V2.25C7.74999 1.975 7.52499 1.25 7.24999 1.25H3.11499C3.33999 1.635 3.21749 1.87 3.24999 3.75V5.25H7.74999ZM7.24999 4.5H4.74999V4H7.24999V4.5ZM7.24999 3.5H5.74999V3H7.24999V3.5ZM3.99999 2H7.24999V2.5H3.99999V2Z"
        fill="currentColor"
      />
    </svg>
  );
}
