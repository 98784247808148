import { ComponentPropsWithoutRef, ReactNode } from 'react';
import { DownloadIcon } from '../icons';

interface DownloadButtonProps extends ComponentPropsWithoutRef<'button'> {
  children: ReactNode;
}

export default function DownloadButton({ children }: DownloadButtonProps) {
  return (
    <button className="flex items-center space-x-2">
      <DownloadIcon className="h-4 w-4 text-secondary2-500" />
      <p className="mt-1 text-sm font-semibold text-secondary2-500">{children}</p>
    </button>
  );
}
