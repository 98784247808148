import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SecondaryButton } from '../../components/buttons';
import { ProfileCard } from '../../components/cards';
import { DashboardHeader } from '../../components/headers';
import { ChecklistIcon, CloseIconRed } from '../../components/icons';
// import { TablePagination } from '../../components/paginations';
import { DashboardTable } from '../../components/tables';
import { formatLink } from '../../constants/getLink';
import { APIBaseURL } from '../../environment';
// import { getLearnerCourseAPI } from '../../services/api/course';
import { useTypedSelector } from '../../store';

interface downloadFileRedeem {
  _id: string;
  profile_picture: string;
  name: string;
  email: string;
  myClass: {
    class_id: string;
    voucher_code: string;
    reedem_code: unknown;
    percentage: number;
    courseTitle: string;
    instructor: string;
  }[];
}

interface myClass {
  class_id: string;
  voucher_code: string;
  reedem_code: unknown;
  percentage: number;
  courseTitle: string;
  instructor: string;
  isReported: boolean;
}

const LearnerDetail = () => {
  // const [tableHeads, tableBodies] = promoDetailTable();
  const [dataLearner, setdataLearner] = useState({
    _id: '',
    profile_picture: '',
    name: '',
    email: '',
    myClass: [],
  });
  const { data } = useTypedSelector((state) => state.user);
  const { token } = useTypedSelector((state) => state.auth);
  const { learner_id } = useParams();
  async function getLearnerData(): Promise<downloadFileRedeem> {
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions: RequestInit = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const response = await fetch(
      `${APIBaseURL}/api/admin/learner/detail/${learner_id}`,
      requestOptions,
    );
    const result = await response.json();

    setdataLearner(result.data);
    return result;
  }

  const [tableHeads, tableBodies] = useMemo(() => {
    return promoDetailTable(dataLearner.myClass);
  }, [dataLearner]);

  useEffect(() => {
    getLearnerData();
  }, []);

  const profilePicture = formatLink(dataLearner?.profile_picture);
  return (
    <div>
      <div
        className="relative mb-[70px] h-[250px] rounded-b-3xl bg-secondary-button-900 bg-cover px-3 pt-4"
        style={{ backgroundImage: 'url("/assets/images/1457718455276 1.png")' }}
      >
        <DashboardHeader
          imageURL={data?.profile_picture.link || ''}
          isWhite
          name={data?.name || ''}
        />
        <div className="absolute left-0 bottom-0 w-full translate-y-1/2 px-3">
          <div
            className="flex w-full items-center justify-between rounded-xl border border-netral-4 bg-netral-1 px-6 py-3"
            style={{
              backdropFilter: 'blur(10.5px)',
              filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.06))',
            }}
          >
            <div className="flex items-center space-x-5">
              <div>
                <img
                  alt={profilePicture}
                  className="h-[70px] w-[70px] rounded-xl bg-[#23C4F4]"
                  src={profilePicture}
                />
              </div>
              <div className="flex justify-between space-x-5">
                <div className="space-y-1">
                  <p className="text-sm text-netral-6">Nama</p>
                  <p className="text-sm text-netral-6">Email</p>
                  <p className="text-sm text-netral-6">Nomor Handphone</p>
                </div>
                <div className="space-y-1">
                  <p className="text-sm text-netral-6">{dataLearner.name}</p>
                  <p className="text-sm text-netral-6">{dataLearner.email}</p>
                  {/* <p className="text-sm text-netral-6">{dataLearner.phone_number}</p> */}
                  <p className="text-sm text-netral-6">-</p>
                </div>
              </div>
            </div>
            <div className="self-start">
              <SecondaryButton color="light-green" rounded="small" size="small">
                Reset Password
              </SecondaryButton>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-[16px]">
        <p className="text-lg font-semibold">Informasi Detail</p>
      </div>
      <div className="mb-[70px] rounded-xl bg-white py-[1px] px-[16px]">
        <div className="mt-4">
          <DashboardTable tableBodies={tableBodies} tableHeads={tableHeads} />
        </div>
        {/* <div className="relative border-t border-netral-6 pt-3">
          <TablePagination />
        </div> */}
      </div>
    </div>
  );
};

export default LearnerDetail;

function promoDetailTable(data: myClass[]) {
  const tableHeads = [
    [
      { node: 'Nama Kelas' },
      { node: 'Nama Instruktur' },
      { node: 'Kode Voucher' },
      { node: 'Redeem Code' },
      { node: 'Progress' },
    ],
  ];

  const tableBodies = data.map((el) => [
    { node: <ProfileCard etc={''} imageURL="" name={el.courseTitle} /> },
    { node: el.instructor },
    {
      node: (
        <div className="flex w-full justify-between">
          <p>{el.voucher_code}</p>
          {el.isReported ? (
            <ChecklistIcon className="mr-[25px] h-[15px] w-[15px]" />
          ) : (
            <CloseIconRed className="mr-[25px] h-[15px] w-[15px]" />
          )}
        </div>
      ),
    },
    { node: '-' },
    // { node: el.reedem_code },
    { node: el.percentage + '%' },
  ]);

  return [tableHeads, tableBodies];
}
