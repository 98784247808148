import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTypedSelector } from '../store';

interface AuthenticatedRouteProps {
  children: JSX.Element;
}

export default function AuthenticatedRoute({ children }: AuthenticatedRouteProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated } = useTypedSelector((state) => state.auth);

  useEffect(() => {
    const visit = encodeURIComponent(location.pathname + location.search);
    if (!isAuthenticated)
      return navigate(`/login?continue=${visit}`, {
        replace: true,
        state: location,
      });
  }, [location, isAuthenticated]);

  return children;
}
