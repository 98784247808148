import axios from 'axios';
import { APIBaseURL } from '../../environment';
import Redeem, { PrakerjaReport } from '../../interfaces/redeem';

interface GetRedeemsAPI {
  status: boolean;
  totalPage: number;
  result: Redeem[];
}

interface GetPrakerjaReportAPI {
  status: boolean;
  code: number;
  message: string;
  totalData: number;
  totalPage: number;
  data: PrakerjaReport[];
}

interface UpdateReportPrakerjaAPI {
  status: boolean;
  message: string;
  failed: string[];
}

export async function getRedeemsAPI(token: string, query: string): Promise<GetRedeemsAPI> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(`${APIBaseURL}/api/admin/reedem/all?${query}`, requestOptions);

  const result = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (result.status === false) throw new Error(result.message);

  return result;
}

export async function getPrakerjaReportAPI(
  token: string,
  query: string,
): Promise<GetPrakerjaReportAPI> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(`${APIBaseURL}/api/admin/report-prakerja?${query}`, requestOptions);

  const result = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (result.status === false) throw new Error(result.message);

  return result;
}

export async function updateReportStatusPrakerjaAPI(
  token: string,
  data: string[],
): Promise<UpdateReportPrakerjaAPI> {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  // const requestOptions: RequestInit = {
  //   method: 'PUT',
  //   headers: myHeaders,
  //   redirect: 'follow',
  //   body: JSON.stringify({ codes: data }),
  // };

  // const response = await fetch(`${APIBaseURL}/api/admin/update-report`, requestOptions);

  const response = await axios({
    method: 'put',
    url: `${APIBaseURL}/api/admin/update-report`,
    headers: { Authorization: `Bearer ${token}` },
    data: { codes: data },
  });
  const result = await response.data;

  // const result = await response.json();

  if (typeof result === 'string') throw new Error('Internal Server Error');

  if (result.status === false) throw new Error(result.message);

  return result;
}
