import { CloseButton } from '../buttons';
import { CancelIcon } from '../icons';

interface AlertModalProps {
  close: () => void;
  message: string;
}

export default function AlertModal({ close, message }: AlertModalProps) {
  return (
    <div className="w-[436px] rounded-lg bg-white p-4">
      <div className="flex justify-end">
        <CloseButton onClick={close} type="button" />
      </div>
      <div className="flex w-full justify-center">
        <CancelIcon className="h-[130px] w-[130px]" />
      </div>
      <p className="mt-3 text-center text-sm text-secondary3-500">{message}</p>
    </div>
  );
}
