import { ComponentPropsWithoutRef, ReactNode } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

interface DashboardNavButtonProps extends ComponentPropsWithoutRef<'a'> {
  link: string;
  children: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Icon: any;
}

export default function DashboardNavButton({ link, Icon, children }: DashboardNavButtonProps) {
  const { pathname } = useLocation();
  const activeLink = '/' + pathname.split('/')[1] === link;

  return (
    <NavLink
      className={`${
        activeLink && 'bg-white'
      } flex w-full items-center space-x-3 rounded-xl py-2 pl-3`}
      style={{ boxShadow: activeLink ? '2px 4px 6px rgba(0, 0, 0, 0.04)' : 'none' }}
      to={link}
    >
      <Icon
        className={`${
          activeLink ? 'bg-[#26255B] text-white' : 'bg-white text-[#26255B]'
        } h-6 w-6 rounded-lg p-1`}
      />
      <p className={`${activeLink ? 'text-netral-10' : 'text-netral-7'} text-sm font-semibold`}>
        {children}
      </p>
    </NavLink>
  );
}
