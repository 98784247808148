import { useState } from 'react';
import { CloseButton, PrimaryButton } from '../buttons';
import { CancelIcon } from '../icons';

interface SelectTemplateNotif {
  close: () => void;
  sendMessage: (templateId: string) => void;
  data?: any;
}

interface TemplateState {
  templateId: string;
  id: string;
  body: string;
}
export default function SelectTemplateNotifModal({
  close,
  sendMessage,
  data,
}: SelectTemplateNotif) {
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateState>({
    id: '',
    templateId: '',
    body: '',
  });
  const [showAll, setShowAll] = useState('');
  console.log(data);
  return (
    <div className="w-[760px] space-y-4 rounded-lg bg-white p-4">
      <div className="flex items-start justify-between">
        <div>
          <p className="text-lg font-semibold">Template Pesan</p>
          <p className="text-xs text-netral-6">Pilih template pesan yang ingin anda kirim</p>
        </div>
        <CloseButton onClick={close} type="button" />
      </div>
      <div className="max-h-[400px] space-y-[8px] overflow-scroll">
        {data.map((el: any, idx: any) => (
          <div
            key={idx}
            className={`flex cursor-pointer items-center rounded-[10px] border p-[14px] ${
              el.id === selectedTemplate.id && 'border-secondary-button-700'
            }`}
            onClick={() => setSelectedTemplate(el)}
          >
            <div className="mr-8 space-y-[4px]">
              <p className="font-semibold">{el.templateId}</p>
              <p className="text-sm text-netral-6">
                {showAll === el.id ? el.body : el.body.slice(0, 300)}
              </p>
              <p
                className="cursor-pointer font-semibold text-secondary-button-500"
                onClick={() => (showAll !== el.id ? setShowAll(el.id) : setShowAll(''))}
              >
                {showAll === el.id ? 'Kecilkan' : 'Selengkapnya'}
              </p>
            </div>
            <input
              checked={el.id === selectedTemplate.id}
              className="h-[24px] w-[24px] rounded-full"
              onChange={() => {
                return;
              }}
              type="checkbox"
            />
          </div>
        ))}
      </div>
      <div className="flex justify-end">
        <PrimaryButton
          onClick={() => sendMessage(selectedTemplate.templateId)}
          rounded="large"
          size="small"
          type="button"
          width={150}
        >
          Kirim Pesan
        </PrimaryButton>
      </div>
    </div>
  );
}
