import { CloseButton } from '../buttons';
import { formatLink } from '../../constants/getLink';

interface ImageProps {
  url: string;
  close: () => void;
}

export default function ImageModal({ url, close }: ImageProps) {
  const image = formatLink(url);
  return (
    <div className="space-y-2 py-10">
      <div className="flex justify-end">
        <CloseButton onClick={close} type="button" />
      </div>
      <img
        alt={url}
        className="h-full max-h-[600px] w-auto max-w-full"
        src={url !== '' ? image : ''}
      />
    </div>
  );
}
