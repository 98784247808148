import { APIBaseURL } from '../environment';

export function formatLink(imageURL: string) {
  if (imageURL) {
    if (imageURL.includes('skillsrevamp')) {
      return imageURL;
    } else {
      return APIBaseURL + imageURL;
    }
  } else {
    return '';
  }
}
