import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { UserState } from './slice';
import { AlertModal } from '../../components/modals';
import { getUserAPI } from '../../services/api/user';
import { User } from '../../interfaces';
import { closeModal, openModal } from '../modal/slice';
import { AppDispatch, RootState } from '../../store';

export function removeUserAction(state: UserState) {
  state.data = null;
  state.loadingUser = false;
}

export const getUserAction = createAsyncThunk<
  User,
  undefined,
  { state: RootState; dispatch: AppDispatch }
>('user/getUser', async (_, { getState, dispatch }) => {
  try {
    const { token } = getState().auth;

    if (!token) throw null;

    const result = await getUserAPI(token);

    return result.data;
  } catch (error) {
    const message = (error as Error).message;

    const close = () => dispatch(closeModal());

    dispatch(openModal(AlertModal({ close, message })));

    throw error;
  }
});

export function userExtraReducer(builder: ActionReducerMapBuilder<UserState>) {
  builder
    .addCase(getUserAction.pending, (state) => {
      state.loadingUser = true;
    })
    .addCase(getUserAction.fulfilled, (state, action) => {
      state.loadingUser = false;

      state.data = action.payload;
    })
    .addCase(getUserAction.rejected, (state) => {
      state.loadingUser = false;
    });
}
