import { createSlice } from '@reduxjs/toolkit';
import { TransactionDetail } from '../../interfaces';
import { getTransactionAction, resetTransactionAction, transactionExtraReducer } from './action';

export interface TransactionState {
  data: TransactionDetail | null;
  loading: boolean;
}

const initialState: TransactionState = {
  data: null,
  loading: false,
};

export const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: { resetTransaction: resetTransactionAction },
  extraReducers: transactionExtraReducer,
});

export default transactionSlice.reducer;
export const { resetTransaction } = transactionSlice.actions;
export { getTransactionAction as getTransaction };
