import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { CoursesState, getCourses, setLoadingUpdateCourse } from './slice';
import { AlertModal } from '../../components/modals';
import { Course } from '../../interfaces';
import { closeModal, openModal } from '../modal/slice';
import { AppDispatch, RootState } from '../../store';
import { getCoursesAPI, updateCourseStatusAPI } from '../../services/api/course';

export function resetCoursesAction(state: CoursesState) {
  state.loadingData = false;

  state.data = [];

  state.totalPage = 0;
}

export const getCoursesAction = createAsyncThunk<
  { data: Course[]; status: boolean; totalPage: number; linkDownload: string },
  { query: string },
  { state: RootState; dispatch: AppDispatch }
>('courses/getCourses', async ({ query }, { getState, dispatch }) => {
  try {
    const { token } = getState().auth;

    if (!token) throw null;

    const result = await getCoursesAPI(token, query);

    return result;
  } catch (error) {
    const message = (error as Error).message;

    const close = () => dispatch(closeModal());

    dispatch(openModal(AlertModal({ close, message })));

    throw error;
  }
});

export const updateCourseStatusAction = createAsyncThunk<
  void,
  { id: string; status: 'Publish' | 'Unpublish'; query: string },
  { state: RootState; dispatch: AppDispatch }
>('courses/updateCourseStatus', async ({ id, status, query }, { getState, dispatch }) => {
  try {
    const { token } = getState().auth;

    if (!token) throw null;

    dispatch(setLoadingUpdateCourse(id));

    const result = await updateCourseStatusAPI(token, id, status);

    if (result.status)
      dispatch(getCourses({ query })).then(() => dispatch(setLoadingUpdateCourse('')));
  } catch (error) {
    const message = (error as Error).message;

    const close = () => dispatch(closeModal());

    dispatch(openModal(AlertModal({ close, message })));

    throw error;
  }
});

export function setLoadingUpdateCourseAction(state: CoursesState, action: PayloadAction<string>) {
  state.loadingUpdateCourseById = action.payload;
}

export function coursesExtraReducer(builder: ActionReducerMapBuilder<CoursesState>) {
  builder
    .addCase(getCoursesAction.pending, (state) => {
      state.loadingData = true;
    })
    .addCase(getCoursesAction.fulfilled, (state, action) => {
      state.loadingData = false;

      state.data = action.payload.data;

      state.totalPage = action.payload.totalPage;

      state.linkDownload = action.payload.linkDownload;
    })
    .addCase(getCoursesAction.rejected, (state) => {
      state.loadingData = false;
    });
}
