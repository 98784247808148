export default function CloseIconRed({ ...rest }) {
  return (
    <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M7.19987 0.333984C10.8865 0.333984 13.8665 3.31398 13.8665 7.00065C13.8665 10.6873 10.8865 13.6673 7.19987 13.6673C3.5132 13.6673 0.533203 10.6873 0.533203 7.00065C0.533203 3.31398 3.5132 0.333984 7.19987 0.333984ZM9.5932 3.66732L7.19987 6.06065L4.80654 3.66732L3.86654 4.60732L6.25987 7.00065L3.86654 9.39398L4.80654 10.334L7.19987 7.94065L9.5932 10.334L10.5332 9.39398L8.13987 7.00065L10.5332 4.60732L9.5932 3.66732Z"
        fill="#D1421A"
      />
    </svg>
  );
}
