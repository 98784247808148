import { createSlice } from '@reduxjs/toolkit';
import { Redeem } from '../../interfaces';
import { getRedeemsAction, redeemsExtraReducer, resetRedeemsAction } from './action';

export interface RedeemsState {
  data: Redeem[];
  totalPage: number;
  loadingData: boolean;
}

const initialState: RedeemsState = {
  data: [],
  totalPage: 0,
  loadingData: false,
};

export const redeemsSlice = createSlice({
  name: 'redeems',
  initialState,
  reducers: {
    resetRedeems: resetRedeemsAction,
  },
  extraReducers: redeemsExtraReducer,
});

export default redeemsSlice.reducer;

export const { resetRedeems } = redeemsSlice.actions;

export { getRedeemsAction as getRedeems };
